import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Load file resource as image URL
 */

@Directive({
  selector: 'source,img[appLoadFile]'
})

export class LoadFileDirective {

  @Input() set appLoadFile(value: File) {
    this.file = value;
    this.load();
  }

  @HostBinding('src') src: any;

  private file: File;

  constructor(protected sanitizer: DomSanitizer) {
  }

  load() {
    if (this.file) {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.file));
    }
  }

}
