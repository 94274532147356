import {Pipe, PipeTransform} from '@angular/core';
import { Utils } from '@helpers/utils';

@Pipe({
    name: 'hmsToReadable'
})
export class HmsToReadablePipe implements PipeTransform {

    constructor() { }

    public transform(value: string): string {
        const hms = value.split(':');

        if (hms.length !== 3) {
            return value;
        }

        const hours = Utils.toNumber(hms[0]);
        const minutes = Utils.toNumber(hms[1]);
        const seconds = Utils.toNumber(hms[2]);

        let result = '';
        if (hours > 0) {
            result += hours.toString() + 'h ';
        }
        if (minutes > 0) {
            result += minutes.toString() + 'min ';
        }
        result += seconds.toString() + 's';

        return result;
    }
}
