import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInputButtonInterface, TextInputIconInterface, TextInputMaskInterface } from './text-input.interface';
import { Platform } from '@ionic/angular';
import { Utils } from '@helpers/utils';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    }
  ],
})
export class TextInputComponent implements OnInit, ControlValueAccessor {

  @Input() value: string;
  @Input() maxlength: string = null;

  @Input() maxRows: number = undefined;

  @Input() color: string;
  @Input() invalid = false;

  @HostBinding('class.multiline')
  @Input() multiline = false;

  @Input() mask: TextInputMaskInterface;

  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() disabled: boolean;

  @Input() loading = false;
  @Input() buttonEnd: TextInputButtonInterface;
  @Input() icon: TextInputIconInterface;
  @Input() counter = false;

  // https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/inputmode
  @Input() inputMode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url' = 'text';
  // https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/enterkeyhint
  @Input() enterKeyHint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send' = 'enter';

  isFocused = false;
  counterValue = '';

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor(private ionicPlatform: Platform) {
  }

  ngOnInit() {
    if (this.ionicPlatform.is('ios') && this.enterKeyHint === 'enter') {
      this.enterKeyHint = 'done';
    }

    if (this.icon && !this.icon.position) {
      this.icon.position = 'end';
    }
    this.updateCounter();
  }

  updateValue() {
    this.updateCounter();
    this.onChange(this.value);
  }

  updateCounter() {
    // Update counter
    if (this.counter) {
      if (this.maxlength != null) {
        this.counterValue = `${ this.value?.length.toString() || '0' } / ${ this.maxlength }`;
      } else if (Utils.toNumber(this.value?.length) > 0) {
        this.counterValue = this.value?.length.toString() || '0';
      } else {
        this.counterValue = '';
      }
    }
  }

  /**
   * ControlValueAccesor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.value = inputValue;
    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
