import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'app-select-button-option',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./select-button-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectButtonOptionComponent implements OnInit {

  @Input() value: any;

  // tslint:disable-next-line:no-output-native
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Set iSelected property
   * @param value value
   */
  set isSelected(value: boolean) {
    this.isSelectValue = value;
    this.applySelection();
  }

  set isLast(value: boolean) {
    if (value) {
      this.rdr.addClass(this.elt.nativeElement, 'is-last');
    }
    this.detectChanges();
  }

  set isFirst(value: boolean) {
    if (value) {
      this.rdr.addClass(this.elt.nativeElement, 'is-first');
    }
    this.detectChanges();
  }

  isSelectValue = false;
  isDisabled = false;

  constructor(private elt: ElementRef,
              private rdr: Renderer2,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {}

  applySelection() {
    if (this.isSelectValue) {
      this.rdr.addClass(this.elt.nativeElement, 'selected');
    } else {
      this.rdr.removeClass(this.elt.nativeElement, 'selected');
    }
    this.detectChanges();
  }

  @HostListener('click', ['$event'])
  clickAction() {
    if (!this.isDisabled) {
      this.select.emit(this.value);
    }
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.rdr.addClass(this.elt.nativeElement, 'disabled');
    } else {
      this.rdr.removeClass(this.elt.nativeElement, 'disabled');
    }
    this.detectChanges();
  }
}
