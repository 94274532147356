import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';

export interface OptionsInterface {
  title?: string;
  titleKey?: string;
  message?: string;
  messageKey?: string;
  messageParams?: any;
  cancelHandler?: any;
  successHandler?: any;
  closeHandler?: any;
  duration?: number;
  params?: any;
  inputs?: any;
  type?: any;
  isDelete?: boolean;
  buttons?: (AlertButton | string)[];
}

@Injectable()
export class ToolboxService {
  static MSG_WAITING = 'utils.waiting';
  static MSG_LOADING = 'utils.loading';

  static TYPE_ERROR = 'error';
  static TYPE_SUCCESS = 'success';
  static TYPE_WARNING = 'warning';
  static TYPE_INFO = 'info';

  loading: any = null;
  progress: any = null;
  percentDone: any = 0;
  alert: any = null;
  toast: any = null;

  constructor(public loadingCtrl: LoadingController,
              public alertCtrl: AlertController,
              public toastCtrl: ToastController,
              private translateServ: TranslocoService) {
  }

  /**
   * Shortcut access to translate
   */
  public translate(key: TranslateParams, params?: HashMap, lang?: string) {
    return this.translateServ.translate(key, params, lang);
  }

  /**
   * Loading Tools
   */
  public async showLoading(msg = ToolboxService.MSG_WAITING) {
    if (msg === ToolboxService.MSG_WAITING || msg === ToolboxService.MSG_LOADING) {
      msg = this.translateServ.translate(msg);
    }
    if (this.loading) {
      this.loading.setMessage(msg);
    } else {
      this.loading = await this.loadingCtrl.create({
        message: msg
      });
      await this.loading.present();
    }
  }

  public async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  /**
   * Message Tools
   */
  // Display a message
  public async message(options: OptionsInterface) {
    let cssClassO = 'popup-message';
    let messageO = options.message;

    this.hideLoading();
    if (!options.title) {
      options.title = null;
    }
    if (!options.params) {
      options.params = null;
    }
    if (!options.closeHandler) {
      options.closeHandler = null;
    }
    if (!messageO && options.messageKey) {
      options.message = this.translateServ.translate(options.messageKey);
    }

    if (options.type) {
      let icon: string;
      if (options.type === ToolboxService.TYPE_ERROR) {
        icon = 'close-circle-outline';
      } else if (options.type === ToolboxService.TYPE_WARNING) {
        icon = 'alert';
      } else if (options.type === ToolboxService.TYPE_SUCCESS) {
        icon = 'checkmark-circle-outline';
      } else {
        icon = 'information-circle-outline';
      }
      cssClassO = 'popup-message popup-' + options.type;
      messageO = '<p><ion-icon name="' + icon + '"></ion-icon></p>' +
        '<p>' + options.message + '</p>';
    }

    const defaultButton = {
      text: 'OK',
      handler: options.closeHandler
    };
    const buttons = options.buttons?.length > 0 ? options.buttons.concat([defaultButton]) : [defaultButton];

    this.alert = await this.alertCtrl.create({
      subHeader: options.title,
      message: messageO,
      buttons,
      cssClass: cssClassO
    });
    await this.alert.present(prompt);
  }

  /**
   * Prompt a message with inputs
   */
  public async prompt(options: OptionsInterface) {
    this.hideLoading();
    if (!options.title) {
      options.title = null;
    }
    if (!options.params) {
      options.params = null;
    }
    if (!options.cancelHandler) {
      options.cancelHandler = null;
    }
    if (!options.successHandler) {
      options.successHandler = null;
    }
    if (!options.inputs) {
      options.inputs = [
        {
          name: 'default',
        }
      ];
    }
    if (!options.title && options.titleKey) {
      options.title = this.translateServ.translate(options.titleKey);
    }
    if (!options.message && options.messageKey) {
      options.message = this.translateServ.translate(options.messageKey);
    }

    this.alert = await this.alertCtrl.create({
      subHeader: options.title,
      message: options.message,
      inputs: options.inputs,
      buttons: [
        {
          text: this.translate('buttons.cancel'),
          role: 'cancel',
          handler: options.cancelHandler
        },
        {
          text: 'OK',
          handler: options.successHandler
        }
      ]
    });
    await this.alert.present();
  }

  /**
   * Confirm Tools
   */
  // Display a confirm modal box
  public async confirm(options: OptionsInterface) {
    let message = options.message;

    this.hideLoading();
    if (!options.cancelHandler) {
      options.cancelHandler = null;
    }
    if (!options.successHandler) {
      options.successHandler = null;
    }
    if (!options.title) {
      options.title = null;
    }
    if (!message && options.messageKey) {
      message = this.translateServ.translate(options.messageKey, options?.messageParams || undefined);
    }
    if (!options.title && options.titleKey) {
      options.title = this.translateServ.translate(options.titleKey);
    }

    const buttons: Array<any> = [
      {
        text: this.translate('buttons.cancel'),
        role: 'cancel',
        handler: options.cancelHandler
      }
    ];

    if (options.isDelete) {
      buttons.push({
        text: this.translate('buttons.delete'),
        cssClass: 'app-color-danger',
        handler: options.successHandler
      });
    } else {
      buttons.push({
        text: 'OK',
        handler: options.successHandler
      });
    }

    this.alert = await this.alertCtrl.create({
      message,
      header: options.title,
      buttons,
    });
    await this.alert.present();
  }


  /**
   * Toast Tools
   */
  public async flash(options: OptionsInterface) {
    let cssClassO = 'flash-message';
    let message = options.message;

    if (!options.duration) {
      options.duration = 1000;
    }
    if (!options.params) {
      options.params = null;
    }
    if (options.type) {
      cssClassO = 'flash-message flash-' + options.type;
    }
    if (!message && options.messageKey) {
      message = this.translateServ.translate(options.messageKey, options?.messageParams || undefined);
    }

    this.toast = await this.toastCtrl.create({
      message,
      duration: options.duration,
      position: 'bottom',
      cssClass: cssClassO
    });

    await this.toast.present();
  }

  public async showProgress(percent: number) {
    if (this.progress) {
      this.progress.setMessage('Upload ' + percent + '%');
      this.percentDone = percent;
      if (percent >= 100) {
        this.progress.setDuration(2000);
      }
      if (percent > 100) {
        this.progress.dismissAll();
      }
    } else {
      await this.createToastProgress(percent);
    }

    await this.progress.onDidDismiss(() => {
      if (this.percentDone < 100) {
        this.createToastProgress(percent);
      } else {
        this.progress = null;
        this.percentDone = 0;
      }
    });
  }

  private async createToastProgress(percent: number) {
    this.progress = await this.toastCtrl.create({
      message: 'Upload ' + percent + '%',
      duration: 3000,
      position: 'bottom'
    });

    await this.progress.present();
  }

  public displayRoleRestrictedAccessWarning() {
    this.flash({
      message: this.translate('alerts.role-restricted-access'),
      duration: 2000,
      type: ToolboxService.TYPE_ERROR
    })?.then(/* Nothing to do */);
  }

  public getActiveLang() {
    return this.translateServ.getActiveLang();
  }
}
