import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformSelectComponent } from './platform-select.component';
import { IonicModule } from '@ionic/angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '@directives/directives.module';



@NgModule({
  declarations: [
    PlatformSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatMenuModule,
    MatIconModule,
    DirectivesModule
  ],
  exports: [
    PlatformSelectComponent
  ]
})
export class PlatformSelectModule { }
