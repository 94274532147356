import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionListComponent } from '@components/utils/mention/mention-list.component';
import { MentionDirective } from '@components/utils/mention/mention.directive';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
  declarations: [
    MentionDirective,
    MentionListComponent
  ],
  imports: [
    CommonModule,
    OverlayModule
  ],
  exports: [
    MentionDirective
  ]
})
export class MentionModule { }
