import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'trim'
})
export class TrimPipe implements PipeTransform {

    constructor() { }

    public transform(value: string): string {
        return value.trim();
    }
}
