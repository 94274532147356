import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

    constructor() { }

    public transform(value: string): string {
        return value.replace(/\n/g, '<br />');
    }
}
