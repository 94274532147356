import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { ChallengeBaseType, ChallengeType } from '@interfaces/api/client';
import { ModalController, NavController } from '@ionic/angular';
import { CommonService } from '@services/common.service';
import { AccessControlService, PermissionsForDashboardInterface } from '@services/access-control';
import { OffersController } from '@components/modals/offers';
import { ToolboxService } from '@services/toolbox.service';
import { SwiperComponent } from 'swiper/angular';

interface TypeObjectInterface {
  key: ChallengeType;
  popular: boolean;
  infoKey?: string;
  exampleKey?: string;
  image: string;
  enabled: () => boolean;
}

@Component({
  selector: 'app-dashboard-challenge-add-edit-type-modal',
  templateUrl: './type.modal.html',
  styleUrls: ['./type.modal.scss'],
})
export class DashboardChallengeAddEditTypeModal implements OnInit {

  @ViewChild('slides', {static: false}) slidesComp: SwiperComponent;

  @Input() idCat: string;

  @Input() disabled = false;

  @Input() base: ChallengeBaseType;

  @Input() type: ChallengeType = null;

  types: Array<Array<TypeObjectInterface>> = [
    [
      {
        key: 'challenge',
        popular: true,
        infoKey: 'dashboard.challenges.notices.info-init-challenge',
        image: '/assets/images/challenges/type-challenge.png',
        enabled: () => true
      },
      {
        key: 'multi',
        popular: false,
        infoKey: 'dashboard.challenges.notices.info-init-multi',
        exampleKey: 'dashboard.challenges.notices.info-multi-example',
        image: '/assets/images/challenges/type-multi.png',
        enabled: () => this.permissions?.settings?.isUnlimitedChallengesEnabled
      }
    ],
    [
      {
        key: 'challenge-points',
        popular: false,
        infoKey: 'dashboard.challenges.notices.info-init-challenge-points',
        image: '/assets/images/challenges/type-challenge-points.png',
        enabled: () => this.permissions?.settings?.isUnlimitedChallengesEnabled
      },
      {
        key: 'multi-points',
        popular: false,
        infoKey: 'dashboard.challenges.notices.info-init-multi-points',
        exampleKey: 'dashboard.challenges.notices.info-multi-points-example',
        image: '/assets/images/challenges/type-multi-points.png',
        enabled: () => this.permissions?.settings?.isUnlimitedChallengesEnabled
      }],
  ];

  slidesOptions = {allowTouchMove: false, autoHeight: true};
  selectedType: TypeObjectInterface;
  permissions: PermissionsForDashboardInterface;

  getTitle = () => {
    if (!this.type) {
      return this.commonServ.translate('dashboard.challenges.new');
    } else {
      return this.commonServ.translate('common.new-name', {
        name: this.commonServ.translate('dashboard.challenges.type.' + this.type)
      });
    }
  };


  constructor(private navCtrl: NavController,
              private commonServ: CommonService,
              private toolboxServ: ToolboxService,
              private accessServ: AccessControlService,
              private offersCtrl: OffersController,
              private modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.permissions = this.accessServ.permissionsForDashboard();
  }

  selectType(value: TypeObjectInterface) {
    if (value.enabled()) {
      this.selectedType = value;
      this.type = value.key;
    } else if (this.permissions.isAdminUser) {
      this.offersCtrl.open().then(/* Nothing to do */);
    } else if (this.permissions.platform?.access.id_role > 0) {
      this.toolboxServ.displayRoleRestrictedAccessWarning();
    }
    setTimeout(() => this.slidesComp?.swiperRef.updateAutoHeight(300), 100);
  }

  selectBase(value: ChallengeBaseType) {
    const route = ['/dashboard/challenges'];
    if (this.idCat?.length > 0) {
      route.push(this.idCat);
    }
    route.push('new');
    this.navCtrl.navigateForward(route, {queryParams: {base: value, type: this.type}}).then(/* Nothing to do */);
    this.modalCtrl.dismiss(null, 'chosen').then(/* Nothing to do */);
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
  }

}
