import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgAvatarComponent } from './img-avatar.component';
import { IonicModule } from '@ionic/angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@directives/directives.module';



@NgModule({
  declarations: [
    ImgAvatarComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatTooltipModule,
    DirectivesModule
  ],
  exports: [
    ImgAvatarComponent
  ]
})
export class ImgAvatarModule { }
