import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OffersModal } from './offers.modal';

/**
 * Component that manage offers modal showing
 */

@Injectable()
export class OffersController {

  constructor(protected modalCtrl: ModalController) {
  }

  /**
   * Display viewer modal
   */
  async open(currentPlan?: string) {
    const modal = await this.modalCtrl.create({
      component: OffersModal,
      componentProps: {plan: currentPlan},
      cssClass: 'large-modal offers-modal',
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }

}
