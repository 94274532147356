import { Component, Input, OnInit } from '@angular/core';
import { AvatarInterface } from '@interfaces/common.interface';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-img-avatar',
  templateUrl: './img-avatar.component.html',
  styleUrls: ['./img-avatar.component.scss'],
})
export class ImgAvatarComponent implements OnInit {

  @Input() data: AvatarInterface;

  @Input() useIonAvatar = false;
  @Input() enableNameTooltip = false;
  @Input() linkToProfile = false;

  constructor(private navCtrl: NavController) { }

  ngOnInit() {
    if (this.data?.id_gender && typeof this.data?.id_gender !== 'string') {
      this.data.id_gender = (this.data.id_gender as number).toString();
    }
  }

  navToProfile() {
    if (this.linkToProfile) {
      this.navCtrl.navigateForward(['/profile', this.data.id_customer]);
    }
  }

}
