import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessControlService, PermissionsForClientInterface } from '@services/access-control';

@Component({
  selector: 'app-header-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class AppHeaderLogoComponent implements OnInit, OnDestroy {

  @Input() isProfile: boolean;

  permissions: PermissionsForClientInterface;
  mainRouteUrl: string;

  subscription: Subscription;

constructor(private accessServ: AccessControlService) { }

  ngOnInit() {
    this.subscription = this.accessServ.permissionsForClientState().subscribe(permissions => {
      this.permissions = permissions;
      this.mainRouteUrl = this.accessServ.getClientMainRoute();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
