import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaInputComponent } from '@components/forms/textarea-input/textarea-input.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TranslocoModule } from '@ngneat/transloco';
import { EmojiPanelModule } from '@components/utils/emoji-panel';
import { MentionModule } from '@components/utils/mention';
import { NgxEditorModule } from 'ngx-editor';
import { DirectivesModule } from '@directives/directives.module';


@NgModule({
  declarations: [
    TextareaInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    TranslocoModule,
    EmojiPanelModule,
    NgxEditorModule,
    MentionModule,
    DirectivesModule
  ],
  exports: [
    TextareaInputComponent
  ]
})
export class TextareaInputModule { }
