import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConnectionService } from '@services/connection.service';
import { GeneralService } from '@services/api';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-cgu-agreement',
  templateUrl: './cgu-agreement.modal.html',
  styleUrls: ['./cgu-agreement.modal.scss'],
})
export class CguAgreementModal implements OnInit {
  static DEFAULT_ID_ACTION = 52;

  @Input() idAction = CguAgreementModal.DEFAULT_ID_ACTION;
  @Input() viewCgu: boolean;
  @Input() viewCguMedias: boolean;

  @Input() mode: 'view' | 'agree' = 'agree';


  agreement: string;

  private isCguMedias: boolean;


  constructor(private modalCtrl: ModalController,
              private authServ: ConnectionService,
              private generalServ: GeneralService) { }

  ngOnInit() {
    this.isCguMedias = false;
    const token = this.authServ.getTokenValue();
    const platform = this.authServ.getSelectedPlatformValue();

    if (this.viewCgu) {
      this.showCgu();
    } else if (this.viewCguMedias) {
      this.showCguMedias();
    } else if (token?.user.optin === '0') {
      this.showCgu();
    } else if (token?.user.optin_medias === '0' && platform?.require_optin_medias === '1') {
      this.showCguMedias();
    } else {
      this.modalCtrl.dismiss(null, 'bad-request').then(/* Nothing to do */);
    }

  }

  showCgu() {
    this.generalServ.getCgu(this.idAction).pipe(
      switchMap(response => {
        if (response.cgu.toString().trim() === '' && this.idAction !== CguAgreementModal.DEFAULT_ID_ACTION) {
          this.idAction = CguAgreementModal.DEFAULT_ID_ACTION;
          return this.generalServ.getCgu(this.idAction);
        } else {
          return of(response);
        }
      })
    ).subscribe(response => {
      this.agreement = response.cgu;
    });
  }

  showCguMedias() {
    this.generalServ.getCguMedias(this.idAction).pipe(
      switchMap(response => {
        if (response.cgu.toString().trim() === '' && this.idAction !== CguAgreementModal.DEFAULT_ID_ACTION) {
          this.idAction = CguAgreementModal.DEFAULT_ID_ACTION;
          return this.generalServ.getCguMedias(this.idAction);
        } else {
          return of(response);
        }
      })
    ).subscribe(response => {
      this.agreement = response.cgu;
      this.isCguMedias = true;
    });
  }

  validateAction(choice: number) {
    if (this.isCguMedias) {
      this.validateCguMedias(choice);
    } else {
      this.validateCgu(choice);
    }
  }

  validateCgu(choice: number) {
    this.generalServ.validateCgu(this.idAction, choice).subscribe(() => {
      if (choice === 0) {
        this.modalCtrl.dismiss(choice.toString()).then(/* Nothing to do */);
      } else {
        // Save optin in token
        const token = this.authServ.getTokenValue();
        token.user.optin = '1';
        this.authServ.saveToken(token);
        // Check for medias
        const platform = this.authServ.getSelectedPlatformValue();

        if (platform?.require_optin_medias === '1' && token.user.optin_medias === '0') {
          this.showCguMedias();
        } else {
          this.modalCtrl.dismiss(choice.toString()).then(/* Nothing to do */);
        }
      }
    });
  }

  validateCguMedias(choice: number) {
    this.generalServ.validateCguMedias(this.idAction, choice).subscribe(() => {
      if (choice === 0) {
        this.modalCtrl.dismiss(choice.toString()).then(/* Nothing to do */);
      } else {
        // Save optin_medias in token
        const token = this.authServ.getTokenValue();
        token.user.optin_medias = '1';
        this.authServ.saveToken(token);

        this.modalCtrl.dismiss(choice.toString()).then(/* Nothing to do */);
      }
    });
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss().then(/* Nothing to do */);
  }

}
