import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { ReqAdminBillingInterface } from '@interfaces/api/admin';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Utils } from '@helpers/utils';


@Injectable()
export class AdminBillingService extends HttpBaseService {


  /**
   * Get billing data
   * @param billing billing data
   */
  billing(billing?: string): Observable<ReqAdminBillingInterface> {
    const body = new FormData();

    if (billing) {
      body.append('billing', billing);
    }

    return this.stdRequest<ReqAdminBillingInterface>(this.http.post<ReqAdminBillingInterface>(`${ this.rootApi }/administration/Billing`, body)).pipe(
      // Cast data
      tap(response => {
        if (response?.platform) {
          response.platform.coins_available = Utils.roundPrice(response.platform.coins_available);
        }
      })
    );
  }

}
