import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/internal/operators';
import { Injectable } from '@angular/core';
import { ConnectionService } from '@services/connection.service';
import { NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Errors interceptor for managing errors behavior
 */
@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  // Last context state
  private lastContext: {
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
    retryCounter: number // Security mechanism
  };

  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private connectionServ: ConnectionService,
              private router: NavController,
              private translate: TranslocoService) {
    this.lastContext = null;
  }

  handleAuthError(err: HttpErrorResponse, requestInput?: HttpRequest<any>, nextInput?: HttpHandler): Observable<HttpEvent<any>> {

    // Initialize context
    if (null === this.lastContext) {
      this.lastContext = {
        error: err,
        request: requestInput,
        next: nextInput,
        retryCounter: 0
      };
    }

    // Manage Token Auth expiration
    if (401 === err.status) {
      if (requestInput.url.endsWith('/verifysession')) {
        this.lastContext.retryCounter++;
      }
      if (this.lastContext.retryCounter >= 3 || !this.connectionServ.getTokenValue()) {
        console.log('disconnect after 3 retry');
        this.disconnect();
        return throwError(err.error);
      } else {
        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => nextInput.handle(requestInput))
          );
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          // TODO: verifySession is now called each session start, so called on 401 status received is not longer supported.
          // --> Check better way to manage errors
          return this.connectionServ.verifySession().pipe(
            delay(500),
            switchMap((token) => {
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);

              return this.lastContext.next.handle(this.replaceAuthenticationToken(this.lastContext.request)).pipe(
                // Reset last Context
                tap(() => this.lastContext = null)
              );
            }),
            catchError(error => {

              // If verifySession get Login fail, disconnect user
              if (error === 'Login fail') {
                this.disconnect();
              }

              return throwError(error);
            })
          );
        }
      }
    }

    // Bad verification
    if (requestInput.url.endsWith('/verifysession') && err.status === 400) {
      console.log('disconnect on bad renew request');
      this.disconnect();
      return throwError(err);
    }

    return throwError(err);

  }

  disconnect() {
    this.lastContext = null;
    this.connectionServ.logout();
    this.router.navigateRoot(['/login']);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {

          if (error.error instanceof ErrorEvent) {
            // client-side error
            console.log('this is client side error');
          } else {
            // server-side error
            return this.handleAuthError(error, request.clone(), next);
          }

          return throwError(error);

        })
      );
  }

  replaceAuthenticationToken(request) {
    // Get access token from Local Storage
    const token = this.connectionServ.getTokenValue();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!token) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: token.token,
        lang: this.translate.getActiveLang()
      }
    });
  }
}
