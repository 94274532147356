import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-tooltip',
  template: `<span [matTooltip]="tooltip"><ion-icon [name]="name" [color]="color"></ion-icon></span>`,
  styleUrls: ['./icon-tooltip.component.scss'],
})
export class IconTooltipComponent {

  @Input() name: string;

  @Input() tooltip: string;

  @Input() color: string;

}
