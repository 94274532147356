import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TalkPopupComponent } from './popup/popup.component';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ImgAvatarModule } from '@components/images';
import { PipesModule } from '@pipes';
import { TalkNotificationToastComponent } from './notification-toast/notification-toast.component';
import { TalkConversationsModule } from '@components/app/talk/talk-conversations.module';

@NgModule({
  declarations: [
    TalkPopupComponent,
    TalkNotificationToastComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    ImgAvatarModule,
    PipesModule,
    TalkConversationsModule
  ],
  exports: [
    TalkPopupComponent
  ]
})
export class TalkModule { }
