import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@services/common.service';
import { ResponsiveService } from '@services/responsive.service';
import { Subscription } from 'rxjs';
import {
  AccessControlService,
  PermissionsForDashboardInterface
} from '@services/access-control';
import { ConnectionService } from '@services/connection.service';
import { NavController, PopoverController } from '@ionic/angular';
import { NotificationsPopover } from '../notifications/notifications.popover';
import { Router } from '@angular/router';
import { DashboardChallengeAddEditTypeController } from '@roadoo-app/dashboard/challenges/add-edit/_type/type.controller';
import { TalkService } from '@components/app/talk';
import { NotificationsService } from '@services/notifications';
import { OffersController } from '@components/modals/offers';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  providers: [
    DashboardChallengeAddEditTypeController
  ]
})
export class AppSideMenuComponent implements OnInit, OnDestroy {

  notificationsCount = 0;
  messagesCount = 0;

  subscriptions: Subscription[] = [];

  permissions: PermissionsForDashboardInterface;

  canCreateItemsInDashboard = false;
  hasDashboardEvents = false;

  isTalkEnabled = false;

  canAccessDashboard = false;

  dashboardMainRoute = '/';

  constructor(public commonServ: CommonService,
              public responsiveServ: ResponsiveService,
              protected popoverCtrl: PopoverController,
              protected navCtrl: NavController,
              protected baseCtrl: DashboardChallengeAddEditTypeController,
              protected accessServ: AccessControlService,
              protected connectionServ: ConnectionService,
              protected notificationsServ: NotificationsService,
              protected talkServ: TalkService,
              protected offersCtrl: OffersController,
              protected router: Router) {
  }

  ngOnInit() {
    this.subscriptions.push(this.accessServ.permissionsForDashboardState().subscribe(permissions => this.buildPermissions(permissions)));
    this.subscriptions.push(this.notificationsServ.getInAppNotificationsCountState().subscribe(val => this.notificationsCount = val));
    this.subscriptions.push(this.notificationsServ.eventsState(true).subscribe(events => this.hasDashboardEvents = events.accessFlag));
    this.subscriptions.push(this.talkServ.getUnreadMessagesCountState().subscribe(val => this.messagesCount = val));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildPermissions(permissions: PermissionsForDashboardInterface) {
    this.permissions = permissions;

    this.canCreateItemsInDashboard = (permissions.isChallengesEnabled && !!permissions.permissions.Challenge?.add) ||
      (permissions.isRoadboxEnabled && !!permissions.permissions.Gallery?.add) ||
      (permissions.isQuizEnabled && !!permissions.permissions.Quizz?.add) ||
      (permissions.isSurveysEnabled && !!permissions.permissions.Sondage?.add) ||
      (permissions.isFormsEnabled && !!permissions.permissions.Form?.add);

    this.canAccessDashboard = this.commonServ.canAccessDashboard();
    this.dashboardMainRoute = this.accessServ.getDashboardMainRoute();

    this.isTalkEnabled = this.accessServ.permissionsForClient().isTalkEnabled;

    this.superviseDashboardAccess();
  }

  /**
   * Open notifications
   * @param event click event
   */
  async openNotifications(event) {
    if (this.responsiveServ.isMobile) {
      this.navCtrl.navigateForward('/notifications')?.then(/* Nothing to do */);
    } else {
      const popover = await this.popoverCtrl.create({
        component: NotificationsPopover,
        cssClass: 'notifications-popover',
        event,
        translucent: true,
        showBackdrop: false
      });
      return await popover.present();
    }
  }
  createChallenge() {
    if (!(!this.permissions?.settings?.isUnlimitedChallengesEnabled && this.permissions?.platform.hasActiveChallenge)) {
      this.baseCtrl.open()?.then(/* Nothing to do */);
    } else if (this.permissions.isAdminUser) {
      this.offersCtrl.open().then(/* Nothing to do */);
    }
  }
  /**
   * Supervise Dashboard menu
   * Got to home if not admin access
   */
  superviseDashboardAccess() {
    if (!this.commonServ.canAccessDashboard() && this.router.url?.indexOf('/dashboard') > -1) {
      this.navCtrl.navigateRoot(['/'])?.then(/* Nothing to do */);
    }
  }

}
