import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserProfileBase } from '@interfaces/connection.interface';
import Talk from 'talkjs';
import { TalkSdkService } from '@components/app/talk/talk-sdk.service';

@Component({
  selector: 'app-talk-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class TalkPopupComponent implements OnInit, OnDestroy {

  @Input() id: string;

  @Input() user: UserProfileBase = null;
  @Input() conversationId: string;

  @Input() title: string;

  @Output() closePopup: EventEmitter<string> = new EventEmitter<string>();

  collapsed = false;

  private chatbox: Talk.Chatbox;

  constructor(private talkSdkServ: TalkSdkService) { }

  ngOnInit() {
    if (this.id && (this.user || this.conversationId)) {
      this.createInbox()?.then(/* Nothing to do */);
    }
  }

  ngOnDestroy() {
    if (this.chatbox) {
      this.chatbox.destroy();
    }
  }

  private async createInbox() {
    this.chatbox = await this.talkSdkServ.createChatbox(this.user ? [this.user] : null);
    if (this.conversationId && !this.user) {
      this.chatbox.select(this.conversationId);
    }
    this.chatbox.mount(document.getElementById('talkjs-container-' + this.id))?.then(/* Nothing to do */);
  }


}
