import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { SafeResourcePipe } from './safe-resource.pipe';
import { DateMomentPipe } from './date-moment.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { SafePlayerPipe } from './safe-player.pipe';
import { ToNumberPipe } from './to-number.pipe';
import { PhonePipe } from './phone.pipe';
import { TranslocoChallengeLabelPipe } from './transloco-challenge-label.pipe';
import { HmsToReadablePipe } from './hms-to-readable.pipe';
import { ExtractNamePipe } from './extract-name.pipe';
import { TrimPipe } from './trim.pipe';
import { SmartTextPipe } from './smart-text.pipe';
import { TextWrapPipe } from './text-wrap.pipe';
import { TranslocoFilterLabelPipe } from './transloco-filter-label.pipe';
import { SafeHrefPipe } from './safe-href.pipe';
import { TranslocoCoinsPipe } from '@roadoo-app/_pipes/transloco-coins.pipe';

@NgModule({
  declarations: [
    SafePipe,
    SafeResourcePipe,
    DateMomentPipe,
    CapitalizeFirstPipe,
    Nl2brPipe,
    SafePlayerPipe,
    ToNumberPipe,
    PhonePipe,
    TranslocoChallengeLabelPipe,
    HmsToReadablePipe,
    ExtractNamePipe,
    TrimPipe,
    SmartTextPipe,
    TextWrapPipe,
    TranslocoFilterLabelPipe,
    SafeHrefPipe,
    TranslocoCoinsPipe
  ],
  exports: [
    SafePipe,
    SafeResourcePipe,
    DateMomentPipe,
    CapitalizeFirstPipe,
    Nl2brPipe,
    SafePlayerPipe,
    ToNumberPipe,
    PhonePipe,
    TranslocoChallengeLabelPipe,
    HmsToReadablePipe,
    ExtractNamePipe,
    TrimPipe,
    SmartTextPipe,
    TextWrapPipe,
    TranslocoFilterLabelPipe,
    SafeHrefPipe,
    TranslocoCoinsPipe
  ]
})
export class PipesModule {
}
