import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConnectionService } from '@services/connection.service';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers.modal.html',
  styleUrls: ['./offers.modal.scss'],
})
export class OffersModal implements OnInit {

  @Input() plan: string;

  payingChoice = 'month';

  constructor(private modalCtrl: ModalController,
              private connectionServ: ConnectionService) { }

  ngOnInit() {
    if (!this.plan) {
      this.plan = this.connectionServ.getSelectedPlatformValue()?.offer;
    }
  }

  onPayingChoiceChange(event: CustomEvent) {
    this.payingChoice = event?.detail?.checked ? 'year' : 'month';
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss().then(/* Nothing to do */);
  }
}
