import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@helpers/utils';

@Component({
  selector: 'app-rule-input',
  templateUrl: './rule-input.component.html',
  styleUrls: ['./rule-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RuleInputComponent),
      multi: true,
    },
  ],
})
export class RuleInputComponent implements OnInit, ControlValueAccessor {

  @Input() cssClass: string = null;

  // Min Rows and Max Rows for text type only
  @Input() minRows = 1;
  @Input() maxRows = 8;

  @Input() maxLength = 0;

  @Input() placeholder = '';

  value: string;

  isHl = false;
  // TODO: Replace customer_progression and team_progression by user_performance and team_performance
  fields = ['customer_firstname', 'customer_lastname', 'customer_progression', 'team_progression', 'customer_goal'];

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor() { }

  ngOnInit() {}

  /**
   * Insert field in textarea
   * @param el textarea input
   * @param field field to insert
   */
  insertField(el, field) {
    Utils.inputInsertAtCursor(el, `{${field}}`);
    this.value = el.value;
    this.updateValue();
    el.focus();
  }

  updateValue() {
    this.onChange(this.value);
  }

  /**
   * ControlValueAccesor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.value = inputValue;
    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
