import { Injectable } from '@angular/core';
import { HttpBaseService } from '@services/api/http-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StandardResponseInterface } from '@interfaces/api';
import { ReqAwardsRankInterface, ReqAwardsStatsInterface, ReqAwardsSuggestionInterface } from '@interfaces/api/client/awards.interface';

@Injectable()
export class AwardsService extends HttpBaseService {

  /**
   * Get user stats
   * @param idUser id of user to get awards
   */
  awardsStats(idUser: string): Observable<ReqAwardsStatsInterface> {
    return this.stdRequest<any>(this.http.post(`${ this.rootApi }/awards/${ idUser }`, null)).pipe(
      // Standardize interface
      map(response => ({
        error: response?.error,
        message: response?.message,
        stats: {
          challenges: response?.awards?.challenges,
          quizzes: response?.awards?.quizzs,
        },
        awards: response?.awards?.awards
      }))
    );
  }

  /**
   * Used to share an award
   * @param idAward id of award
   */
  awardsShare(idAward: number): Observable<StandardResponseInterface> {
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/awards/${ idAward.toString() }/share`, null));
  }

  /**
   * Used to get rank of award
   * @param idAward id of award
   */
  awardsRank(idAward: number): Observable<ReqAwardsRankInterface> {
    return this.stdRequest(this.http.post<ReqAwardsRankInterface>(`${ this.rootApi }/awards/${ idAward.toString() }/rank`, null));
  }

  /**
   * Used to get suggestion for feed
   */
  suggestion(): Observable<ReqAwardsSuggestionInterface> {
    return this.stdRequest(this.http.post<ReqAwardsSuggestionInterface>(`${ this.rootApi }/awards/suggestion`, null));
  }

}
