import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AdminUserCoinsData,
  AdminUsersFilterData,
  ReqAdminCoinsInterface,
  ReqAdminUserFieldsInterface,
  ReqAdminSearchFiltersInterface,
  ReqAdminUserCoinsInterface,
  ReqAdminUsersInterface,
  AdminUserInterface,
  AdminUserData,
  ReqAdminAddUserInterface,
  AdminUserFieldsInterface,
  AdminConfigFieldData,
  ReqAdminDeliveryAddressInterface, ReqAdminTeamsAndChallengesInterface, ReqAdminUserCustomFieldsInterface
} from '@interfaces/api/admin';
import { PaginatorInterface } from '@interfaces/common.interface';
import { StandardResponseInterface } from '@interfaces/api';
import { Utils } from '@helpers/utils';
import { DeliveryUserAddressInterface } from '@interfaces/api/client';
import { tap } from 'rxjs/operators';

@Injectable()
export class AdminUsersService extends HttpBaseService {


  /**
   * Get users from administration
   * @param data data options
   * @param paginator paginator options
   */
  users(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminUsersInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }
    if (data?.disabled) {
      body.append('disabled', data.disabled ? '1' : '0');
    }

    if (data?.getCsv) {
      body.append('get_csv', data.getCsv ? '1' : '0');
    } else {

      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('start', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest(this.http.post<ReqAdminUsersInterface>(`${ this.rootApi }/administration/User`, body));
  }

  /**
   * Update users coins
   * @param data user object
   */
  updateCoins(data: AdminUserCoinsData[]): Observable<ReqAdminUserCoinsInterface> {
    const body = new FormData();
    body.append('users', JSON.stringify(data));
    return this.stdRequest(this.http.post<ReqAdminUserCoinsInterface>(`${ this.rootApi }/administration/Coins/update`, body));
  }

  /**
   * Get CSV file to update coins
   * @param search search string
   */
  getCsv(search?: string): Observable<ReqAdminUserCoinsInterface> {
    const body = new FormData();
    body.append('get_csv', 'true');
    if (search) {
      body.append('search', search);
    }
    return this.stdRequest(this.http.post<ReqAdminUserCoinsInterface>(`${ this.rootApi }/administration/Coins/update`, body));
  }

  /**
   * Update Coins with CSV file
   * @param csvFile csvFile
   */
  updateCoinsWithCsv(csvFile: File): Observable<ReqAdminUserCoinsInterface> {
    const body = new FormData();
    body.append('csvFile', csvFile);
    return this.stdRequest(this.http.post<ReqAdminUserCoinsInterface>(`${ this.rootApi }/administration/Coins/update`, body));
  }

  /**
   * Used to get the list filters needed to search for users into platform
   * @param isTeam concern team ?
   */
  searchFilters(isTeam = false): Observable<ReqAdminSearchFiltersInterface> {
    const body = new FormData();
    if (isTeam) {
      body.append('team_filter', '1');
    }
    return this.stdRequest(this.http.post<ReqAdminSearchFiltersInterface>(`${ this.rootApi }/administration/getSearchFilters`, body));
  }

  /**
   * Get coins from administration
   * @param data data options
   * @param paginator paginator options
   */
  coins(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminUsersInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }
    if (data?.disabled) {
      body.append('disabled', data.disabled ? '1' : '0');
    }

    if (data?.getCsv) {
      body.append('get_csv', data.getCsv ? '1' : '0');
    } else {

      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('start', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest(this.http.post<ReqAdminUsersInterface>(`${ this.rootApi }/administration/Coins`, body));
  }
  /**
   * Used to get users coins history
   * @param users users Id
   */
  coinsHistory(users?: Array<string>): Observable<ReqAdminCoinsInterface> {
    const body = new FormData();
    if (users) {
      body.append('users', JSON.stringify(users));
    }
    return this.stdRequest(this.http.post<ReqAdminCoinsInterface>(`${ this.rootApi }/administration/Coins/history`, body));
  }

  /**
   * Disable an user
   * @param idUser id of user
   */
  disableUser(idUser: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('user', idUser);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/disable`, body));
  }

  /**
   * Enable an user
   * @param idUser id of user
   */
  enableUser(idUser: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('user', idUser);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/enable`, body));
  }

  /**
   * Invite an user again
   * @param idUser id of user
   */
  inviteUser(idUser: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_user', idUser);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/reInvite`, body));
  }

  /**
   * Add new user to platform
   * @param user user data object
   * @param userAvatar user avatar file
   * @param userCover user cover file
   * @param customFields customFields
   */
  addUser(user: AdminUserData,
          userAvatar?: File,
          userCover?: File,
          customFields?: { [key: string]: string }): Observable<ReqAdminAddUserInterface> {
    return this.addEditUser('add', user, userAvatar, userCover, customFields);
  }

  /**
   * Update user from platform
   * @param user user data object
   * @param userAvatar user avatar file
   * @param userCover user cover file
   * @param customFields customFields
   */
  updateUser(user: AdminUserData,
             userAvatar?: File,
             userCover?: File,
             customFields?: { [key: string]: string }): Observable<ReqAdminAddUserInterface> {
    return this.addEditUser('update', user, userAvatar, userCover, customFields);
  }

  /**
   * Convert User Object to User Data object
   * @param user user object
   * @param includeId include Id ?
   */
  userToData(user: AdminUserInterface, includeId = false): AdminUserData {
    const result: AdminUserData = {
      id_gender: Utils.toNumber(user.id_gender || '1'),
      collaboration: user?.collaboration || 0,
      firstname: user?.firstname || null,
      lastname: user?.lastname || null,
      nickname: user?.nickname || '',
      email: user?.email || null,
      societe: user?.societe || '',
      service: user?.service || '',
      poste: user?.poste || '',
      ville: user?.ville || '',
      phone: user?.phone || '',
      birthday: user?.birthday || '',
      departement: user?.departement || '',
      region: user?.region || '',
      pays: user?.pays || '',
      citation: user?.citation || '',
    };
    if (includeId) {
      result.id_customer = user.id_customer;
    }
    return result;
  }

  /**
   * Return user fields
   */
  userFields(): Observable<ReqAdminUserFieldsInterface> {
    return this.stdRequest<ReqAdminUserFieldsInterface>(this.http.post<ReqAdminUserFieldsInterface>(`${ this.rootApi }/administration/User/profileFields`, null)).pipe(
      // TODO: Cast data shall be done by backend
      tap(response => {
        response?.configFields?.basic_config?.forEach(field => {
          field.display = Utils.toNumber(field.display);
          field.edit = Utils.toNumber(field.edit);
        });
        response?.configFields?.custom_config?.forEach(field => {
          field.display = Utils.toNumber(field.display);
          field.edit = Utils.toNumber(field.edit);
        });
      })
    );
  }

  /**
   * Get user custom fields with data
   * @param idUser id of user
   */
  userCustomFieldsData(idUser: string): Observable<ReqAdminUserCustomFieldsInterface> {
    return this.stdRequest(this.http.post<ReqAdminUserCustomFieldsInterface>(`${ this.rootApi }/administration/User/${ idUser }/getCustomProfileFieldsDatas`, null));
  }

  /**
   * Update user fields
   * @param userFields user fields
   */
  updateUserFields(userFields: AdminUserFieldsInterface): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('config_fields', JSON.stringify(userFields));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/updateProfileFields`, body));
  }

  /**
   * Add new field
   * @param name name if field
   */
  addField(name: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('field', JSON.stringify({name}));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/profileField/add`, body));
  }

  /**
   * Update field
   * @param field field object
   */
  updateField(field: AdminConfigFieldData): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('field', JSON.stringify(field));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/profileField/update`, body));
  }

  /**
   * Delete a field
   * @param idField id of field
   */
  deleteField(idField: string) {
    const body = new FormData();
    body.append('id_field', idField);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/User/profileField/delete`, body));
  }

  /**
   * Get delivery address
   * @param idUser
   */
  deliveryAddress(idUser: string): Observable<ReqAdminDeliveryAddressInterface> {
    return this.stdRequest(this.http.post<ReqAdminDeliveryAddressInterface>(
      `${ this.rootApi }/administration/User/${ idUser }/address`,
      null
    ));
  }

  /**
   * Update delivery address
   * @param idUser
   * @param address
   */
  updateDeliveryAddress(idUser: string, address: DeliveryUserAddressInterface): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('address', JSON.stringify(address));
    return this.stdRequest(this.http.post<StandardResponseInterface>(
      `${ this.rootApi }/administration/User/${ idUser }/address/update`,
      body
    ));
  }

  /**
   * Get teams and challenges of user
   * @param idUser
   */
  teamsAndChallenges(idUser: string): Observable<ReqAdminTeamsAndChallengesInterface> {
    return this.stdRequest(this.http.post<ReqAdminTeamsAndChallengesInterface>(
      `${ this.rootApi }/administration/User/${ idUser }/getTeamsAndChallenges`,
      null
    ));
  }

  /**
   * Add new user or edit to platform
   * @param endpoint endpoint
   * @param user user data object
   * @param userAvatar user avatar file
   * @param userCover user cover file
   * @param customFields customFields
   * @private
   */
  private addEditUser(endpoint: 'add' | 'update',
                      user: AdminUserData,
                      userAvatar?: File,
                      userCover?: File,
                      customFields?: { [key: string]: string }): Observable<ReqAdminAddUserInterface> {
    const body = new FormData();
    let newData = user;
    // Add custom fields to user JSON object
    if (customFields && typeof customFields === 'object') {
      newData = Object.assign(user, customFields);
    }
    body.append('user', JSON.stringify(newData));
    if (userAvatar) {
      body.append('profilepic', userAvatar);
    }
    if (userCover) {
      body.append('profilebg', userCover);
    }
    return this.stdRequest(this.http.post<ReqAdminAddUserInterface>(`${ this.rootApi }/administration/User/${ endpoint }`, body));
  }

}
