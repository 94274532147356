import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiPanelComponent } from '@components/utils/emoji-panel/emoji-panel.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiPanelController } from '@components/utils/emoji-panel/emoji-panel.controller';

@NgModule({
  declarations: [
    EmojiPanelComponent
  ],
  imports: [
    CommonModule,
    PickerModule
  ],
  exports: [
    EmojiPanelComponent
  ],
  providers: [
    EmojiPanelController
  ]
})
export class EmojiPanelModule { }
