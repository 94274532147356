import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConnectionService } from '@services/connection.service';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from '@services/state.service';
import { tap } from 'rxjs/operators';
import { Utils } from './utils';
import { Platform } from '@ionic/angular';
import { NotificationsService } from '@services/notifications';

/**
 * Connection interceptor for managing
 * - authentication token
 * - platform choice
 * - response fields
 */

@Injectable()
export class ConnectionInterceptor implements HttpInterceptor {


  constructor(private auth: ConnectionService,
              private stateServ: StateService,
              private notificationsServ: NotificationsService,
              private ionicPlatform: Platform,
              private translate: TranslocoService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getTokenValue();
    const idAction = this.auth.getCurrentActionId();
    const url = request.url;
    const isAcceptedUrl = url.indexOf('roadoo.com') > -1 || url.indexOf('localhost') > -1;

    if (token && !token.error && isAcceptedUrl) {

      // Add headers
      let update = {
        setHeaders: {
          Authorization: `Bearer ${ token.token }`,
          lang: this.translate.getActiveLang()
        }
      };

      // Add idAction in params
      if (idAction) {
        // Form Data type
        if (request.body instanceof FormData) {
          const newBody: FormData = new FormData();
          // Build currently FormData fields
          request.body.forEach((value, key) => {
            newBody.append(key, value);
          });
          // Add id_action
          if (!newBody.has('id_action')) {
            newBody.append('id_action', idAction.toString());
          }
          update = Object.assign(update, {
            body: newBody
          });
          // Empty body
        } else if (!request.body) {
          const newBody: FormData = new FormData();
          // Add id_action
          if (!newBody.has('id_action')) {
            newBody.append('id_action', idAction.toString());
          }

          update = Object.assign(update, {
            body: newBody
          });

          // HttpParams type
        } else {
          update = Object.assign(update, {
            body: {
              ...request.body,
              id_action: idAction
            }
          });
        }
      }

      request = request.clone(update);
    }

    return next.handle(request).pipe(
      tap((response: any) => {
        if (response?.body?.notification_count !== undefined) {
          const notificationCount = Utils.toNumber(response.body.notification_count);
          this.notificationsServ.setInAppNotificationsCountValue(notificationCount);
          this.notificationsServ.setNativeIconNotificationsBadge(notificationCount);
        }
        if (response?.body?.user_coins !== undefined) {
          this.stateServ.setUserCoinsValue(Utils.toNumber(response.body.user_coins));
        }
      })
    );
  }
}
