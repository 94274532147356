import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

/**
 * Based on angular-mentions component
 * @src https://github.com/dmacfarlane/angular-mentions/blob/master/projects/angular-mentions/src/lib/mention-list.component.ts
 */

// tslint:disable:triple-equals
@Component({
  selector: 'app-mention',
  templateUrl: './mention-list.component.html',
  styleUrls: ['./mention-list.component.scss'],
})
export class MentionListComponent implements AfterContentChecked {
  @ViewChild('list', {static: true}) list: ElementRef;
  @ViewChild('defaultItemTemplate', {static: true}) defaultItemTemplate: TemplateRef<any>;

  @Input() labelKey = 'label';
  @Input() itemTemplate: TemplateRef<any>;

  @Output() itemClick = new EventEmitter();

  items = [];
  activeIndex = 0;
  hidden = false;
  dropUp = false;
  styleOff = false;

  private coords: { top: number, left: number } = {top: 0, left: 0};
  private offset = 0;

  constructor(private element: ElementRef) {
  }

  ngAfterContentChecked() {
    if (!this.itemTemplate) {
      this.itemTemplate = this.defaultItemTemplate;
    }
  }

  /**
   * Determine position of list
   */
  position(nativeParentElement: HTMLInputElement) {
    const parentCoords = nativeParentElement.getBoundingClientRect();
    this.coords.top = Math.round(parentCoords.top) + 20;
    this.coords.left = Math.round(parentCoords.left);
    // console.log(this.coords, nativeParentElement.getBoundingClientRect());

    // set the default/initial position
    this.applyPosition(this.coords.left, this.coords.top, this.dropUp);
  }

  get activeItem() {
    return this.items[this.activeIndex];
  }

  activateNextItem() {
    // adjust scrollable-menu offset if the next item is out of view
    const listEl: HTMLElement = this.list.nativeElement;
    const activeEl = listEl.getElementsByClassName('active').item(0);
    if (activeEl) {
      const nextLiEl: HTMLElement = activeEl.nextSibling as HTMLElement;
      if (nextLiEl && nextLiEl.nodeName == 'LI') {
        const nextLiRect: ClientRect = nextLiEl.getBoundingClientRect();
        if (nextLiRect.bottom > listEl.getBoundingClientRect().bottom) {
          listEl.scrollTop = nextLiEl.offsetTop + nextLiRect.height - listEl.clientHeight;
        }
      }
    }
    // select the next item
    this.activeIndex = Math.max(Math.min(this.activeIndex + 1, this.items.length - 1), 0);
  }

  activatePreviousItem() {
    // adjust the scrollable-menu offset if the previous item is out of view
    const listEl: HTMLElement = this.list.nativeElement;
    const activeEl = listEl.getElementsByClassName('active').item(0);
    if (activeEl) {
      const prevLiEl: HTMLElement = activeEl.previousSibling as HTMLElement;
      if (prevLiEl && prevLiEl.nodeName == 'LI') {
        const prevLiRect: ClientRect = prevLiEl.getBoundingClientRect();
        if (prevLiRect.top < listEl.getBoundingClientRect().top) {
          listEl.scrollTop = prevLiEl.offsetTop;
        }
      }
    }
    // select the previous item
    this.activeIndex = Math.max(Math.min(this.activeIndex - 1, this.items.length - 1), 0);
  }

  // reset for a new mention search
  reset() {
    this.list.nativeElement.scrollTop = 0;
    this.checkBounds();
  }

  // final positioning is done after the list is shown (and the height and width are known)
  // ensure it's in the page bounds
  private checkBounds() {
    let left = this.coords.left;
    let top = this.coords.top;
    let dropUp = this.dropUp;
    const bounds: ClientRect = this.list.nativeElement.getBoundingClientRect();
    // if off right of page, align right
    if (left + bounds.width > window.innerWidth) {
      left = window.innerWidth - bounds.width;
    }
    // if bottom hide
    if (top + bounds.height > window.innerHeight) {
      top = window.innerHeight - bounds.height;
    }
    // if top is off page, disable dropUp
    if (bounds.top < 0) {
      dropUp = false;
    }
    // set the revised/final position
    this.applyPosition(left, top, dropUp);
  }

  private applyPosition(left: number, top: number, dropUp: boolean) {
    const el: HTMLElement = this.element.nativeElement;
    top += dropUp ? 0 : this.offset; // top of list is next line
    el.className = dropUp ? 'dropup' : null;
    el.style.position = 'absolute';
    el.style.left = left + 'px';
    el.style.top = top + 'px';
  }
}
