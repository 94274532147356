import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { UserProfileBase } from '@interfaces/connection.interface';
import { CommonService } from '@services/common.service';

@Component({
  selector: 'app-talk-conversations-participants',
  templateUrl: './participants.modal.html',
  styleUrls: ['./participants.modal.scss'],
})
export class TalkConversationsParticipantsModal implements OnInit {

  @Input() participants: UserProfileBase[];

  profile: UserProfileBase;

  constructor(private modalCtrl: ModalController,
              private navCtrl: NavController,
              private commonServ: CommonService) { }

  ngOnInit() {
    this.profile = this.commonServ.getProfile();
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
  }

  showParticipant(id: string|number) {
    this.navCtrl.navigateForward(['/profile', id]).then(/* Nothing to do */);
    this.closeModal();
  }
}
