import { Component, Input, OnInit } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { UrlTree } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input() fill: string;
  @Input() color: string;

  @Input() default: string | UrlTree | any[];

  constructor(private navCtrl: NavController,
              private routerOutlet: IonRouterOutlet) { }

  ngOnInit() {}

  back() {
    if (this.routerOutlet.canGoBack()) {
      this.navCtrl.back();
    } else if (this.default) {
      this.navCtrl.navigateBack(this.default);
    }
  }

}
