import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import {
  CguInterface,
  ReqLandingPageInterface,
  ReqNotificationsInterface,
  ReadNotificationInterface,
  ResetInterface,
  ValidateCguInterface, ReqAuthConfigsInterface, ReqBadgeNotificationsInterface
} from '@interfaces/api';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '@helpers/utils';
import { ConnectionService } from '../connection.service';
import { map, tap } from 'rxjs/operators';

/**
 * Service to interface with General API section
 */

@Injectable({providedIn: 'root'})
export class GeneralService extends HttpBaseService {

  private lastNotifications: {
    result?: ReqNotificationsInterface
    debut: number;
    limit: number;
  };

  constructor(protected http: HttpClient,
              protected auth: ConnectionService) {
    super(http);

    this.lastNotifications = {debut: 0, limit: 30};
  }

  /**
   * Used to get the logo and illustration of a platform by his subdomain name
   * @param subdomain domain to get landing page
   * @param lang lang : country code format ISO 3166-1 alpha-2 (fr, en (default), es)
   */
  getLandingPage(subdomain: string, lang: string = 'en'): Observable<ReqLandingPageInterface> {
    let headers = new HttpHeaders();
    headers = headers.set('lang', lang);

    return this.stdRequest<ReqLandingPageInterface>(this.http.post(`${ this.rootApi }/getLandingpage/${ subdomain }`, null, {headers}))
      .pipe(tap(response => {
        if (response?.landing_page?.sso?.clientID) {
          response.landing_page.sso.btn_logo = response.landing_page.btn_logo;
          response.landing_page.sso.btn_title = response.landing_page.btn_title;
          response.landing_page.sso.btn_title_lang = response.landing_page.btn_title_lang;
          response.landing_page.sso.logo = response.landing_page.logo;
        }
      }));
  }

  /**
   * Used to get Auth config
   * @param email email of platform
   * @param lang language code
   */
  getAuthConfigs(email: string, lang: string = 'en'): Observable<ReqAuthConfigsInterface> {
    let headers = new HttpHeaders();
    headers = headers.set('lang', lang);

    const body = new FormData();
    body.append('email', email);

    return this.stdRequest(this.http.post(`${ this.rootApi }/getConfig/sso`, body, {headers}));
  }

  /**
   * Used to reset user password by sending email confirmation
   * @param email email of user
   * @param lang lang of user
   */
  reset(email: string, lang: string): Observable<ResetInterface> {
    let headers = new HttpHeaders();
    headers = headers.set('lang', lang);
    const body = new FormData();
    body.append('email', email);

    return this.stdRequest(this.http.post(`${ this.rootApi }/reset`, body, {headers}));
  }

  /**
   * Get badge notifications
   */
  badgeNotifications(): Observable<ReqBadgeNotificationsInterface> {
    const idAction = this.auth.getCurrentActionId();

    return this.stdRequest(this.http.post(`${ this.rootApi }/notifications/${idAction}/badges`, null));
  }

  /**
   * Use to get notifications from the current user (for all his platforms)
   * @param next get the next page
   */
  notifications(next = false): Observable<ReqNotificationsInterface> {
    if (next === false) {
      this.lastNotifications = {result: null, debut: 0, limit: 30};
    } else {
      this.lastNotifications.debut += 30;

      if (this.lastNotifications.debut > Utils.toNumber(this.lastNotifications.result?.total_notifications)) {
        return of(this.lastNotifications.result);
      }
    }
    const body = new FormData();
    body.append('debut', this.lastNotifications.debut.toString());
    body.append('limit', this.lastNotifications.limit.toString());
    return this.stdRequest(this.http.post<ReqNotificationsInterface>(`${ this.rootApi }/notifications`, body).pipe(
      tap(response => {
        if (next) {
          // Update if next
          this.lastNotifications.result.notifications = this.lastNotifications.result.notifications.concat(response.notifications);
          this.lastNotifications.result.notification_count = response.notification_count;
        } else {
          this.lastNotifications.result = response;
        }
      }),
      map(() => this.lastNotifications.result)
    ));
  }

  /**
   * Use to set notification has read when user click on notification from list
   * @param idNotification index
   */
  readNotification(idNotification: number): Observable<ReadNotificationInterface> {
    return this.stdRequest(this.http.post(`${ this.rootApi }/readnotification/${ idNotification }`, null));
  }

  /**
   * Used to read a list of notifications
   * @param ids list of notification id
   */
  readNotificationByList(ids: Array<number | string>): Observable<ReadNotificationInterface> {
    const body = new FormData();
    body.append('ids_notification', JSON.stringify(ids));
    return this.stdRequest(this.http.post(`${ this.rootApi }/readnotification`, body));
  }

  /**
   * Use to get the GGU of the platform
   * @param idAction id action
   */
  getCgu(idAction?: number): Observable<CguInterface> {
    const body = new FormData();
    if (idAction !== undefined && idAction !== null) {
      body.append('id_action', idAction.toString());
    }

    return this.stdRequest(this.http.post(`${ this.rootApi }/getcgu`, body));
  }

  /**
   * Use to get the GTU of the platform
   * @param idAction id action
   */
  getCguMedias(idAction?: number): Observable<CguInterface> {
    const body = new FormData();
    if (idAction !== undefined && idAction !== null) {
      body.append('id_action', idAction.toString());
    }

    return this.stdRequest(this.http.post(`${ this.rootApi }/getcgumedias`, body));
  }

  /**
   * Use to indicate user have seen the “Terms of use”
   * @param idAction id action
   * @param optIn user response
   */
  validateCgu(idAction: number, optIn: number): Observable<ValidateCguInterface> {
    const body = new FormData();
    body.append('id_action', idAction.toString());
    body.append('optin', optIn.toString());

    return this.stdRequest(this.http.post(`${ this.rootApi }/validatecgu`, body));
  }

  /**
   * Use to indicate user have seen the “Terms of use”
   * @param idAction id action
   * @param optIn user response
   */
  validateCguMedias(idAction: number, optIn: number): Observable<ValidateCguInterface> {
    const body = new FormData();
    body.append('id_action', idAction.toString());
    body.append('optin_medias', optIn.toString());

    return this.stdRequest(this.http.post(`${ this.rootApi }/validatecgumedias`, body));
  }

  /**
   * Used to get cart id and start shopping.
   * @param idAction index
   */
  getCart(idAction: number) {
    const body = new FormData();
    body.append('id_action', idAction.toString());

    return this.stdRequest(this.http.post(`${ this.rootApi }/getcart`, body));
  }


  /**
   * Use to authenticate a user from his login and password
   * @param page id of the page
   * @param timeSpent timestamp (in second) from the beginning to the exit
   * @param idAction id of platform
   */
  setPageTime(page: string, timeSpent: number, idAction: number = null) {
    const body = new FormData();
    body.append('id_action', idAction ? idAction.toString() : this.auth.getCurrentActionId().toString());
    body.append('page', page);
    body.append('duration', timeSpent.toString());
    body.append('os_string', Utils.getOsClient());

    return this.stdRequest(this.http.post(`${ this.rootApi }/setpagetime`, body));
  }

  /**
   * Use to authenticate a user from his login and password
   * @param page id of the page
   * @param timeSpent timestamp (in second) from the beginning to the exit
   * @param idAction id of platform
   */
  setPageTimeSync(page: string, timeSpent: number, idAction: number = null) {
    // TODO: make synchronous request
    return this.stdRequest(this.http.post(
      `${ this.rootApi }/setPageTimeOnClose/${ this.auth.getCurrentActionId().toString() }/${ page }/${ timeSpent.toString() }/${ Utils.getOsClient() }`,
      null
    ));
  }

}


