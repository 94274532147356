import { NgModule } from '@angular/core';
import { TalkConversationStartModal } from '@components/app/talk/conversation-start/conversation-start.modal';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { TextInputModule } from '@components/forms';
import { ImgAvatarModule } from '@components/images';
import { ToolbarModalModule } from '@components/modals/toolbar-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TalkUserSelectModule } from '@components/app/talk/_user-select/user-select.module';
import { TalkConversationStartController } from '@components/app/talk/conversation-start/conversation-start.controller';

@NgModule({
  declarations: [
    TalkConversationStartModal
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslocoModule,
    TextInputModule,
    ImgAvatarModule,
    ToolbarModalModule,
    TalkUserSelectModule
  ],
  exports: [
    TalkConversationStartModal
  ],
  providers: [
    TalkConversationStartController
  ]
})
export class TalkConversationStartModule { }
