import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class StateService {

  private userCoinsState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private isProfileViewState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private contentViewState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private toRefresh: string;

  /**
   * Get Profile View state
   */
  getProfileViewState() {
    return this.isProfileViewState;
  }

  /**
   * Get Profile View value
   */
  getProfileViewValue() {
    return this.isProfileViewState.getValue();
  }

  /**
   * Set Profile View value
   * @param value new value
   */
  setProfileViewValue(value: boolean) {
    if (this.isProfileViewState.getValue() !== value) {
      this.isProfileViewState.next(value);
    }
  }

  /**
   * Get user coins state
   */
  getUserCoinsState() {
    return this.userCoinsState;
  }

  /**
   * Get user coins value
   */
  getUserCoinsValue() {
    return this.userCoinsState.getValue();
  }

  /**
   * Set user coins value
   * @info this callback is called in interceptor when field user_coins is provided in response of request
   * @param value new value
   */
  setUserCoinsValue(value: number) {
    if (this.userCoinsState.getValue() !== value) {
      this.userCoinsState.next(value);
    }
  }

  /**
   * Get content view state
   */
  getContentViewState() {
    return this.contentViewState;
  }

  /**
   * Get content view value
   */
  getContentViewValue() {
    return this.contentViewState.getValue();
  }

  /**
   * Set content view value
   * @info this callback is called when content shall be centered, or side menu hidden.
   * To center content, set value > 300.
   * To hide side menu, set value < 0.
   * To apply default side-menu, set value between 0 and 300.
   * @param value new value
   */
  setContentViewValue(value: number) {
    if (this.contentViewState.getValue() !== value) {
      this.contentViewState.next(value);
    }
  }

  /**
   * Set a flag to refresh in ionViewWillEnter of page
   * @param flag flag key
   */
  refreshAdd(flag: string) {
    this.toRefresh += flag + '+';
  }

  /**
   * Remove a flag
   * @param flag flag key
   */
  refreshRemove(flag: string) {
    this.toRefresh = this.toRefresh.replace(flag + '+', '');
  }

  /**
   * If refresh flag raised ?
   * @param flag flag key
   */
  refreshFlag(flag: string) {
    return this.toRefresh?.indexOf(flag + '+') > -1;
  }
}
