import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { ConnectionService } from '@services/connection.service';
import { CommonService } from '@services/common.service';
import { ThemeService } from '@services/theme.service';
import { CguAgreementController } from '@components/modals/cgu-agreement';
import { TeamService } from '@services/team.service';
import { TranslocoService } from '@ngneat/transloco';
import { CookiesService } from '@services/cookies.service';
import { ResponsiveService } from '@services/responsive.service';
import { DateAdapter } from '@angular/material/core';
import { StateService } from '@services/state.service';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { PlatformInterface, PushNotificationInterface } from '@interfaces/api';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';

import { StatusBar } from '@capacitor/status-bar';
import { Intercom } from 'ng-intercom';
import { Utils } from '@helpers/utils';
import { NotificationsUtils, NotificationsService } from '@services/notifications';
import { App } from '@capacitor/app';
import { ToolboxService } from '@services/toolbox.service';
import StoreListing from 'plugins/storelisting/src/web';
import { SplashScreen } from '@capacitor/splash-screen';
import { TalkService } from '@components/app/talk';
import { AccessControlService } from '@services/access-control';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NetworkStatusService } from '@components/utils/network-status';

declare var batch;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  connected: boolean;
  menuDisplayed: boolean;
  platform: PlatformInterface;

  isIntercomUsed = false;

  sideWidth = 0;

  appIcon: HTMLLinkElement = document.querySelector('#appIcon');
  appTitle: HTMLTitleElement = document.querySelector('#appTitle');

  private appId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private ionicPlatform: Platform,
    private connectionServ: ConnectionService,
    private navCtrl: NavController,
    private commonServ: CommonService,
    private themeServ: ThemeService,
    private cguAgreementCtrl: CguAgreementController,
    private toolboxServ: ToolboxService,
    private alertCtrl: AlertController,
    private teamServ: TeamService,
    private translateServ: TranslocoService,
    private cookiesServ: CookiesService,
    private talkServ: TalkService,
    private responsiveServ: ResponsiveService,
    private dateAdapter: DateAdapter<any>,
    private dateTimeAdapter: NgxMatDateAdapter<any>,
    private stateServ: StateService,
    private intercom: Intercom,
    private notificationsServ: NotificationsService,
    private accessServ: AccessControlService,
    private route: ActivatedRoute,
    private router: Router,
    private networkStatusServ: NetworkStatusService
  ) {
  }

  ngOnInit() {
    this.connected = false;
    this.ionicPlatform.ready().then(() => {
      this.preInitApp()?.then(/* Nothing to do */);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.networkStatusServ.destroy();
  }

  async preInitApp() {

    // Define scheme and App Id for native app
    if (this.ionicPlatform.is('capacitor')) {
      const appInfo = await App.getInfo();
      if (appInfo) {
        this.appId = appInfo?.id;
        this.themeServ.defineScheme(this.appId);
        this.connectionServ.setAppInfo(appInfo);
      }
    }

    // Apply dark mode is current theme used
    await this.themeServ.darkTheme()?.then(/* Nothing to do */);
    this.setupSubdomainTheme();
    this.verifyServicesState();
  }

  /**
   * Before initialize app, check service state and route to correct start point
   */
  verifyServicesState() {
    this.subscriptions.push(this.connectionServ.checkServicesState().subscribe({
      next: state => {
        if (!state.isMajor && !state.isMaintenance) {
          this.initApp();
        } else {
          this.navCtrl.navigateRoot([state.isMaintenance ? '/maintenance' : '/update'])?.then(/* Nothing to do */);
        }
      },
      error: () => {
        this.navCtrl.navigateRoot(['/maintenance'])?.then(/* Nothing to do */);
      }
    }));
  }

  initApp() {
    this.connectionServ.init();
    this.initPushNotifications();
    this.initNativeSettings();
    this.buildResponsive();
    this.networkStatusServ.init().then(/* Nothing to do */);

    this.subscriptions.push(this.stateServ.getProfileViewState().subscribe(value => this.menuDisplayed = !value));

    /**
     * Manage services/components initialization when user is connected / disconnected.
     */
    this.subscriptions.push(this.connectionServ.getConnectionState().subscribe(connected => {
      this.connected = connected;

      if (connected) {
        // Init if connected
        this.teamServ.init();
        this.manageCgu();
        this.setupPlatformTheme();
        this.manageHelper();
        this.checkForAppUpdate();
        this.talkServ.createCurrentSession().then(/* Nothing to do */);
        this.commonServ.promptAppRating();
        this.notificationsServ.init().then(/* Nothing to do */);
      } else {
        // Flush or de-init if not connected
        this.manageHelper(false);
        this.talkServ.destroyCurrentSession().then(/* Nothing to do */);
        this.notificationsServ.flush();
      }
    }));

    /**
     * Manage services/components platform change tasks
     */
    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      // For task where done action on any platform change
      this.accessServ.onPlatformChange();
      // For tasks where platform shall be defined and different from previous
      if (platform && platform?.id_action !== this.platform?.id_action) {
        this.talkServ.onPlatformChange();
        this.notificationsServ.onPlatformChange(platform);
      }
      this.platform = platform;
      // For task where platform shall be defined and user connected
      if (platform && this.connected) {
        this.manageHelper();
        this.checkForPlatformIdConsistency();
        this.checkForUrlWithoutToken();
      }
    }));

    this.manageTranslationInit();
    this.manageCenterContent();

    if (this.ionicPlatform.is('capacitor')) {
      setTimeout(() => {
        SplashScreen.hide({fadeOutDuration: 300}).then(/*Nothing to do*/);
      }, 5000);
    }
  }

  /**
   * Initialize settings for native
   */
  initNativeSettings() {
    if (this.ionicPlatform.is('capacitor')) {
      StatusBar.show().then(/* Nothing to do */);
    }
  }

  /**
   * Initialize Push Notifications
   */
  initPushNotifications() {
    if (this.ionicPlatform.is('capacitor')) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()?.then(/* Nothing to do */);
          const scheme = this.themeServ.getCurrentScheme();
          batch.setConfig({
            androidAPIKey: scheme.androidBatchId,
            iOSAPIKey: scheme.iosBatchId
          });
          batch.optIn();
          batch.start();
          if (this.ionicPlatform.is('ios')) {
            batch.push.requestNotificationAuthorization();
            batch.push.refreshToken();
            batch.push.setiOSShowForegroundNotifications(true);
          }
        } else {
          // Show some error
        }
      });

      // PushNotifications.addListener('registration', (token: Token) => {
      //   console.log('Push registration success, token: ' + token.value);
      // });
      //
      // PushNotifications.addListener('registrationError', (error: any) => {
      //   console.log('Error on registration: ' + JSON.stringify(error));
      // });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          const roadooNotification = JSON.parse(notification.data.roadoo_notification) as PushNotificationInterface;
          if (roadooNotification.notification_count !== undefined) {
            const notificationCount = Utils.toNumber(roadooNotification.notification_count);
            this.notificationsServ.setNativeIconNotificationsBadge(notificationCount);
          }
        }
      );

      document.addEventListener('batchPushReceived', (e: any) => {
        const pushPayload = e.payload;
        // Process the payload as you wish here
        const notification = pushPayload.roadoo_notification as PushNotificationInterface;
        const platform = this.connectionServ.getSelectedPlatformValue();

        const navTo = (pf: PlatformInterface, nf: PushNotificationInterface) => {
          const route = NotificationsUtils.getRoute(nf.type, nf.reference_id, !!pf.is_admin);
          if (route) {
            this.navCtrl.navigateForward(route.url, route.options)?.then(/* Nothing to do */);
          }
        };

        if (platform.id_action !== notification.id_action) {
          this.subscriptions.push(this.connectionServ.setSelectedPlatformId(notification.id_action).subscribe(response => {
            navTo(response, notification);
          }));
        } else {
          navTo(platform, notification);
        }
      }, false);

    }
  }

  /**
   * Setup themes according sub domain or scheme
   */
  setupSubdomainTheme() {
    const subdomain = this.commonServ.getSubDomain();
    const scheme = this.themeServ.getCurrentScheme();
    let hasSubDomainTheme = false;

    if (subdomain) {
      hasSubDomainTheme = this.themeServ.toggleThemeFromClientList(subdomain);
    }
    if (hasSubDomainTheme) {
      const theme = this.themeServ.getTheme(subdomain);
      this.appIcon.href = theme.faviconPath;
      this.appTitle.textContent = Utils.capitalizeFirst(theme.name);
    }
    if (!hasSubDomainTheme && scheme.colors) {
      this.themeServ.applyTheme({
        primary: scheme.colors.primary,
        secondary: scheme.colors.secondary
      });
    }

  }

  /**
   * Setup colors theme according platform or scheme
   */
  setupPlatformTheme() {
    const scheme = this.themeServ.getCurrentScheme();
    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      // Colors
      if (scheme.default) {
        // Apply platform theme only for default App
        if (platform?.primary_color && platform?.secondary_color) {
          this.themeServ.applyTheme({
            primary: platform.primary_color,
            secondary: platform.secondary_color
          });
        } else {
          this.themeServ.removeTheme();
        }
      }
      // Logo
      if (platform && scheme?.logoPath && !platform.logo) {
        // Apply scheme logo if not available for platform
        platform.logo = scheme.logoPath;
      }
    }));
  }

  /**
   * Manage CGU
   */
  manageCgu() {
    if (this.cguAgreementCtrl.shallBeShow()) {
      this.cguAgreementCtrl.show({idAction: this.connectionServ.getSelectedPlatformValue()?.id_action}, choice => {
        if (choice === '1') {
          this.navCtrl.navigateRoot(['/'])?.then(/* Nothing to do */);
        } else if (choice === '0') {
          this.connectionServ.logout();
        }
      })?.then(/* Nothing to do */);
    }
  }

  /**
   * Manage translation at initialization
   * - Persist new language in cookie on change
   * - Apply cookie language if defined
   */
  manageTranslationInit() {
    const lang = this.cookiesServ.getCookie('__lang');

    if (this.translateServ.getAvailableLangs().indexOf(lang as any) > 0) {
      this.translateServ.setActiveLang(lang);
      this.dateAdapter.setLocale(lang);
      this.dateTimeAdapter.setLocale(lang);
    }

    this.subscriptions.push(this.translateServ.langChanges$.subscribe(newLang => {
      this.cookiesServ.setCookie('__lang', newLang, 60);
      this.dateAdapter.setLocale(lang);
      this.dateTimeAdapter.setLocale(lang);
    }));
  }

  /**
   * Manage center content
   */
  manageCenterContent() {
    this.subscriptions.push(this.stateServ.getContentViewState().subscribe(value => {
      this.sideWidth = value;
      this.applySideWidth(this.sideWidth);
    }));
  }

  /**
   * Build responsive
   */
  @HostListener('window:resize')
  buildResponsive() {
    this.responsiveServ.buildResponsive();
    this.applySideWidth(this.sideWidth);
    this.talkServ.onResponsiveChange();
  }

  /**
   * Apply new side width on windows resize
   * @param value width to apply
   */
  applySideWidth(value: number) {
    // 300 is default side width
    let sideWidth = 300;
    if (value > 300) {
      sideWidth = Math.floor((window.innerWidth - value) / 2);

      if (sideWidth < 300) {
        sideWidth = 300;
      }
    }

    const el = document.querySelector('ion-split-pane');
    if (el) {
      el.style.setProperty('--side-width', sideWidth.toString() + 'px');
    }
  }

  /**
   * Check for minor update and show popup
   */
  private checkForAppUpdate() {
    setTimeout(() => {
      const serviceState = this.connectionServ.getServicesState();
      if (serviceState !== null && serviceState.isUpdate && !serviceState.isMajor) {
        this.promptUpdateMessage().then(/* Nothing to do */);
      }
    }, 5000);
  }

  async promptUpdateMessage() {
    const alert = await this.alertCtrl.create({
      message: this.translateServ.translate('login.update.minor-message'),
      header: this.translateServ.translate('login.update.title'),
      backdropDismiss: true,
      keyboardClose: true,
      buttons: [{
        text: this.translateServ.translate('buttons.cancel'),
        cssClass: 'app-color-danger',
        role: 'cancel'
      }, {
        text: this.translateServ.translate('login.update.button'),
        handler: () => {
          StoreListing.openStoreListing({appId: this.connectionServ.getAppId()}).then(/* Nothing to do */);
        }
      }]
    });

    return await alert.present();
  }

  /**
   * Manage intercom or private assistance according platform is_admin
   * Only for desktop
   * @private
   */
  private manageHelper(visible = true) {
    if (!this.ionicPlatform.is('capacitor')) {
      // If option is enabled
      if (!this.accessServ.permissionsForClient().settings?.isHideHelpAdmin) {
        // Display only if required and is desktop screen
        if (visible && !this.responsiveServ.is?.lg) {
          if (this.platform?.is_admin) {
            this.manageIntercom();
          } else {
            this.manageIntercom(false);
          }
        } else {
          this.manageIntercom(false);
        }
      }
    }
  }

  /**
   * Initialize intercom
   */
  private manageIntercom(visible = true) {
    // Show Intercom
    if (visible) {
      if (!this.isIntercomUsed) {

        const profile = this.connectionServ.getTokenValue()?.user;

        if (profile) {

          // Replace Intercom icon for mobile
          /* RDFR-118: Not need for mobile
          const replaceIcon = () => {
            if (this.responsiveServ.isMobile) {
              let el = document.querySelector('.intercom-lightweight-app')?.firstChild as HTMLDivElement;
              if (!el) {
                el = document.querySelector('.intercom-app')?.querySelector('.intercom-launcher-frame') as HTMLIFrameElement;
              }
              if (el) {
                el.style.bottom = '65px';
                el.style.right = '5px';
              }
            }
          };
           */

          this.intercom.boot({
            email: profile.email,
            user_id: Utils.toString(profile.id_customer),
            widget: {
              activator: '#intercom'
            }
          });

          this.isIntercomUsed = true;

          /* RDFR-118: Not need for mobile
          this.intercom.onHide(() => {
            replaceIcon();
          });

          // Let intercom initialized to replace icon
          setTimeout(() => {
            replaceIcon();
          }, 500);

           */
        }
      }
    } else if (this.isIntercomUsed) {
      this.intercom.shutdown();
      this.isIntercomUsed = false;
    }
  }

  /**
   * Check if requested platform id is the defined platform if
   * @private
   */
  private checkForPlatformIdConsistency() {
    const requestedPlatformIdEnc = this.route.snapshot.queryParams.pid;
    const requestedPlatformId = requestedPlatformIdEnc ? Utils.toNumber(window.atob(requestedPlatformIdEnc)) : 0;
    if (requestedPlatformId > 0 && requestedPlatformId !== Utils.toNumber(this.platform.id_action)) {
      this.subscriptions.push(this.connectionServ.setSelectedPlatformId(requestedPlatformId).subscribe());
    }
  }

  /**
   * Check if UR contains token and pid. Remove them in this case.
   * Shall be called when platform is connected
   * @private
   */
  private checkForUrlWithoutToken() {
    const queryParams = Object.assign({}, this.route.snapshot.queryParams);

    if (queryParams.pid && queryParams.token) {
      queryParams.pid = undefined;
      queryParams.token = undefined;
      const urlRoot = this.router.url?.split('?').reverse().pop();

      this.navCtrl.navigateRoot([urlRoot], {queryParams, replaceUrl: true}).then(/* Nothing to do */);
    }
  }

}
