import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextFieldModule } from '@angular/cdk/text-field';



@NgModule({
  declarations: [
    TextInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    NgxMaskModule,
    MatTooltipModule,
    TextFieldModule
  ],
  exports: [
    TextInputComponent
  ]
})
export class TextInputModule { }
