import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatSelectModule } from '@angular/material/select';
import { TeamFeedSelectComponent } from '@components/forms/team-feed-select/team-feed-select.component';
import { TeamFeedSelectModal } from './team-feed-select.modal';
import { TranslocoModule } from '@ngneat/transloco';
import { SearchInputModule } from '@components/forms/search-input/search-input.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DirectivesModule } from '@directives/directives.module';
import { ToolbarModalModule } from '@components/modals/toolbar-modal';



@NgModule({
  declarations: [
    TeamFeedSelectComponent,
    TeamFeedSelectModal
  ],
  exports: [
    TeamFeedSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    SearchInputModule,
    MatSelectModule,
    MatPaginatorModule,
    DirectivesModule,
    ToolbarModalModule
  ]
})
export class TeamFeedSelectModule { }
