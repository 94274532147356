import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.toast.html',
  styleUrls: ['./network-status.toast.scss'],
})
export class NetworkStatusToast implements OnInit {

  @Input() status: string;

  constructor() { }

  ngOnInit() {}

}
