import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminFormData, ReqAdminFormInterface, ReqAdminFormsInterface, ReqAdminResultsInterface } from '@interfaces/api/admin';
import { StandardExportInterface, StandardResponseInterface } from '@interfaces/api';

@Injectable()
export class AdminFormsService extends HttpBaseService {

  /**
   * Used to get the list of all platform forms.
   */
  forms(): Observable<ReqAdminFormsInterface> {
    return this.stdRequest(this.http.post<ReqAdminFormsInterface>(`${ this.rootApi }/administration/Form`, null));
  }

  /**
   * Used to get a form.
   * @param idForm id of form
   */
  form(idForm: string): Observable<ReqAdminFormInterface> {
    const body = new FormData();
    body.append('id_form', idForm);
    return this.stdRequest(this.http.post<ReqAdminFormInterface>(`${ this.rootApi }/administration/Form`, body));
  }

  /**
   * Used to add a publication into feed about this form
   * @param idForm id of form
   */
  publish(idForm: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_form', idForm);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Form/publish`, body));
  }

  /**
   * Used to delete a form.
   * @param idForm id of form
   */
  delete(idForm: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_form', idForm);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Form/delete`, body));
  }

  /**
   * Used to create a new form.
   * @param data data object
   * @param picture picture to add
   */
  add(data: AdminFormData, picture?: File): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('form', JSON.stringify(data));
    if (picture) {
      body.append('form_image', picture);
    }
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Form/add`, body));
  }

  /**
   * Used to update a form.
   * @param idForm id of form
   * @param data data object
   * @param picture picture to add
   */
  update(idForm: number, data: AdminFormData, picture?: File): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_form', idForm.toString());
    body.append('form', JSON.stringify(data));
    if (picture) {
      body.append('form_image', picture);
    }
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Form/update`, body));
  }

  /**
   * Used to get results of a form.
   * @param idForm if of forms
   */
  results(idForm: string): Observable<ReqAdminResultsInterface> {
    const body = new FormData();
    body.append('id_form', idForm.toString());
    return this.stdRequest(this.http.post<ReqAdminResultsInterface>(`${ this.rootApi }/administration/Form/getResults`, body));
  }

  /**
   * Used to export results of a form.
   * @param idForm id of forms
   */
  exportResults(idForm: string): Observable<StandardExportInterface> {
    const body = new FormData();
    body.append('id_form', idForm.toString());
    body.append('get_csv', '1');
    return this.stdRequest(this.http.post<StandardExportInterface>(`${ this.rootApi }/administration/Form/getResults`, body));
  }

  /**
   * Used t delete result of form
   * @param idForm id of form
   * @param idAnswer id of answer
   */
  deleteResults(idForm: string, idAnswer: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_form', idForm);
    body.append('id_answer', idAnswer);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Form/deleteResult`, body));
  }
}
