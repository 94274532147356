import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { UserProfileBase } from '@interfaces/connection.interface';
import { TeamService } from '@services/team.service';
import { PlatformInterface } from '@interfaces/api';
import { ConnectionService } from '@services/connection.service';
import { filter, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-talk-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class TalkUserSelectComponent implements OnInit, OnDestroy {

  @Input() resetValueOnSelection = true;
  @Input() placeholder = '';

  @Output() selection: EventEmitter<UserProfileBase> = new EventEmitter<UserProfileBase>();

  searchValue = new FormControl();
  inputValue = '';
  filteredSearchValue: Observable<UserProfileBase[]>;

  users: UserProfileBase[] = [];

  private platform: PlatformInterface;
  private subscriptions: Subscription[] = [];

  normalize = (val: string): string => {
    return val.toLowerCase().trim();
  }

  constructor(private teamServ: TeamService,
              private connectionServ: ConnectionService) {
  }

  ngOnInit() {
    this.filteredSearchValue = this.searchValue.valueChanges.pipe(
      startWith(''),
      filter(value => typeof value === 'string'),
      switchMap(value => {
          if (!(value?.length > 0)) {
            return of([]);
          }
          // Remove special chars in input
          const normalizedValue = this.normalize(value).replace(/([^a-zA-Z0-9 ]+)/gi, '');
          return of(this.users?.filter(item =>
            this.normalize(item.firstname).indexOf(normalizedValue) > -1 ||
            this.normalize(item.lastname).indexOf(normalizedValue) > -1 ||
            this.normalize(item.email).indexOf(normalizedValue) > -1
          ));
        }
      ));

    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      if (platform && this.platform?.id_action !== platform?.id_action) {
        this.platform = platform;
        this.loadUsers();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loadUsers() {
    this.teamServ.getTeamsAndMembers().subscribe(response => {
      this.users = response.customers || [];
    });
  }

  public getDisplayFn() {
    return (val) => this.resetValueOnSelection ? '' : (val ? val.firstname + ' ' + val.lastname : val);
  }

  public onAutocompleteSelect(value) {
    if (this.resetValueOnSelection) {
      this.searchValue.setValue('');
    }
    this.selection.emit(value);
  }

}
