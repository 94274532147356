import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Utils } from '@helpers/utils';
import { GeneralService } from '../api';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ConnectionService } from '../connection.service';

export interface TrackingClientInterface {
  id: string;
  start_time: number;
}

@Injectable()
export class TrackingClientService {
  static CHALLENGES_ID = '34';
  static ESTORE_ID = '35';
  static FEED_ID = '36';
  static GALLERY_ID = '37';
  static QUIZ_ID = '38';
  static PROFILE_ID = '39';
  static RANKINGS_ID = '40';
  static FORMS_ID = '44';

  trackingData: TrackingClientInterface;

  private domainTracking: string = null;
  private domainSub: Subscription;

  constructor(
    private generalServ: GeneralService,
    private connectionServ: ConnectionService,
    private router: Router
  ) {
    this.trackingData = null;
  }

  /**
   * Set up tracking for domain and automatically send tracking data when domain was changed
   * @param domain domain to track
   * @param currentId current id
   */
  setUpDomainTracking(domain: string, currentId?: string) {
    if (this.domainTracking !== null) {
      return;
    }
    this.domainTracking = domain;

    const trackingData = this.setUpTracking(currentId);

    // Detect when user navigate outside domain
    this.domainSub = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe((res: NavigationEnd) => {
      if (res.url.indexOf(domain) === -1) {
        this.domainSub.unsubscribe();
        this.sendTracking(trackingData);
        this.domainTracking = null;
      }
    });
  }

  /**
   * Set Up Tracking
   * @param currentId current id
   */
  setUpTracking(currentId?: string): TrackingClientInterface {
    // If current Id not provided, determine according current route
    if (!currentId) {
      const currentPath = this.router.url;
      if (currentPath.includes('/challenges')) {
        currentId = TrackingClientService.CHALLENGES_ID;
      } else if (currentPath.includes('/e-store')) {
        currentId = TrackingClientService.ESTORE_ID;
      } else if (currentPath.includes('/gallery')) {
        currentId = TrackingClientService.GALLERY_ID;
      } else if (currentPath.includes('/quiz')) {
        currentId = TrackingClientService.QUIZ_ID;
      } else if (currentPath.includes('/edit-profile')) {
        currentId = TrackingClientService.PROFILE_ID;
      } else if (currentPath.includes('/forms')) {
        currentId = TrackingClientService.PROFILE_ID;
      } else if (currentPath === '/') {
        currentId = TrackingClientService.FEED_ID;
      }
    }
    const result = {
      id: currentId,
      start_time: Utils.getCurrentTimeStamp()
    };

    this.trackingData = result;

    return result;
  }

  getTrackingData() {
    return this.trackingData;
  }

  /**
   * Send tracking
   * @param stats data
   */
  sendTracking(stats: TrackingClientInterface): void {
    if (stats && stats.id && this.connectionServ.getSelectedPlatformValue()?.id_action > 0) {
      this.generalServ
        .setPageTime(
          stats.id,
          Utils.getCurrentTimeStamp() - stats.start_time,
        ).subscribe();
    }
  }

  /**
   * Send tracking in synchronous mode
   * @param stats data
   */
  sendTrackingSync(stats: TrackingClientInterface) {
    if (stats && stats.id) {
      this.generalServ
        .setPageTimeSync(
          stats.id,
          Utils.getCurrentTimeStamp() - stats.start_time
        );
    }
  }
}
