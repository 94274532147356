import {Pipe, PipeTransform} from '@angular/core';
import { CommonService } from '@services/common.service';
import { Utils } from '@helpers/utils';

@Pipe({
  name: 'translocoChallengeLabel'
})
export class TranslocoChallengeLabelPipe implements PipeTransform {

  constructor(private commonServ: CommonService) {
  }

  public transform(key: string, value: string|number = 1): string {
    value = Utils.toNumber(value);
    return this.commonServ.translateChallengeLabel(key, value);
  }
}
