import { Color, DarkColor, RGB } from './color';

export interface ColorGeneratorInt {
  property: string;
  value?: string;
  valueRgb?: string;
  contrast?: string;
  contrastRgb?: string;
  tint?: string;
  shade?: string;
}

/**
 * Generate color for application
 * @param property property of color
 * @param value hex value of color
 * @param useDark convert color for dark theme from light theme
 */
export const generateColor = (property: string, value: string, useDark = false): ColorGeneratorInt => {
  const color = useDark ? new DarkColor(value) : new Color(value);
  const contrast = useDark ? color.contrast(64) : color.contrast(192);
  const tint = color.tint();
  const shade = color.shade();

  return {
    property,
    value: color.hex,
    valueRgb: rgbToString(color.rgb),
    contrast: contrast.hex,
    contrastRgb: rgbToString(contrast.rgb),
    tint: useDark ? shade.hex : tint.hex,
    shade: useDark ? tint.hex : shade.hex,
  };
};

const rgbToString = (c: RGB): string => {
  return `${c.r},${c.g},${c.b}`;
};
