import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

/**
 * This pipe is a patch for correction of bad iframe link provided by backend after rewriting
 */

@Pipe({
  name: 'safePlayer'
})
export class SafePlayerPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  public transform(value: string): string {
    let matches;

    // Youtube
    matches = value.match(/www.youtube.com\/embed\/([a-zA-Z0-9_-]+)/g);
    if (matches) {
      const idYoutube = matches.pop().split('/').pop();
      if (idYoutube) {
        return 'https://www.youtube.com/embed/' + idYoutube;
      }
    }

    // Dailymotion
    matches = value.match(/www.dailymotion.com\/video\/([a-zA-Z0-9]+)/g);
    if (matches) {
      const idYoutube = matches.pop().split('/').pop();
      if (idYoutube) {
        return 'https://www.dailymotion.com/embed/video/' + idYoutube;
      }
    }

    return value;
  }
}
