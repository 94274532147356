import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2
} from '@angular/core';
import { SelectButtonOptionComponent } from './select-button-option.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-button',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./select-button.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectButtonComponent),
    multi: true,
  },
],
})
export class SelectButtonComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {

  @Input() set position(value: 'left' | 'right' | 'center') {
    this.rdr.removeClass(this.elt.nativeElement, 'position-left');
    this.rdr.removeClass(this.elt.nativeElement, 'position-right');
    this.rdr.removeClass(this.elt.nativeElement, 'position-center');
    this.rdr.addClass(this.elt.nativeElement, 'position-' + value);
  }

  @ContentChildren(SelectButtonOptionComponent) options: QueryList<SelectButtonOptionComponent>;

  value: any;

  private subscriptions: Subscription[] = [];

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor(private elt: ElementRef,
              private rdr: Renderer2) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Initialize last and first
    this.options?.toArray().forEach((optionFn, index) => {
      optionFn.isFirst = index === 0;
      optionFn.isLast = index === this.options.length - 1;
    });

    // Initialize event subscription
    this.options.toArray().forEach(optionFn => {
      this.subscriptions.push(optionFn.select.subscribe(value => {
        this.value = value;
        this.applySelection();
      }));
    });

    this.applySelection();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Apply selection
   */
  applySelection() {
    // Update UI
    this.options?.toArray().forEach(optionFn => {
      optionFn.isSelected = optionFn.value === this.value;
    });

    this.onChange(this.value);
  }

  /**
   * ControlValueAccesor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.value = inputValue;
    this.applySelection();
    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.options?.forEach(opt => opt.setDisabledState(isDisabled));
  }
}
