import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Utils } from '@helpers/utils';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-emoji-panel',
  templateUrl: './emoji-panel.component.html',
  styleUrls: ['./emoji-panel.component.scss'],
})
export class EmojiPanelComponent implements OnInit {

  @Input() textEl: HTMLTextAreaElement | Editor;

  constructor(private popoverCtrl: PopoverController) {
  }

  ngOnInit() {
  }

  selectEmoji(event) {
    if (this.textEl && this.textEl instanceof HTMLTextAreaElement) {
      Utils.inputInsertAtCursor(this.textEl, event?.emoji.native);
    } else if (this.textEl && this.textEl instanceof Editor) {
      this.textEl.commands.insertText(event?.emoji.native).exec();
    } else {
      this.popoverCtrl.dismiss(event?.emoji.native);
    }
  }

}
