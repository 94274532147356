import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnInit,
  OnDestroy,
  ElementRef,
  SimpleChanges,
  NgZone,
  Injector
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription, timer } from 'rxjs';

/**
 * @document https://github.com/bootsoon/ng-circle-progress
 */

export interface CircleProgressOptionsInterface {
  class?: string;
  backgroundGradient?: boolean;
  backgroundColor?: string;
  backgroundGradientStopColor?: string;
  backgroundOpacity?: number;
  backgroundStroke?: string;
  backgroundStrokeWidth?: number;
  backgroundPadding?: number;
  percent?: number;
  radius?: number;
  space?: number;
  toFixed?: number;
  maxPercent?: number;
  renderOnClick?: boolean;
  units?: string;
  unitsFontSize?: string;
  unitsFontWeight?: string;
  unitsColor?: string;
  outerStrokeGradient?: boolean;
  outerStrokeWidth?: number;
  outerStrokeColor?: string;
  outerStrokeGradientStopColor?: string;
  outerStrokeLinecap?: string;
  innerStrokeColor?: string;
  outerStrokeOverColor?: string;
  innerStrokeWidth?: number;
  titleFormat?: () => void;
  title?: string | Array<string>;
  titleColor?: string;
  titleFontSize?: string;
  titleFontWeight?: string;
  subtitleFormat?: () => void;
  subtitle?: string | Array<string>;
  subtitleColor?: string;
  subtitleFontSize?: string;
  subtitleFontWeight?: string;
  imageSrc?: string;
  imageHeight?: number;
  imageWidth?: number;
  animation?: boolean;
  animateTitle?: boolean;
  animateSubtitle?: boolean;
  animationDuration?: number;
  animateWithFrame?: boolean;
  showTitle?: boolean;
  showSubtitle?: boolean;
  showUnits?: boolean;
  showImage?: boolean;
  showBackground?: boolean;
  showInnerStroke?: boolean;
  clockwise?: boolean;
  responsive?: boolean;
  startFromZero?: boolean;
  showZeroOuterStroke?: boolean;
  endpoint?: 'circle' | null;
  endpointSize?: number;
  lazy?: boolean;
}

export class CircleProgressOptions implements CircleProgressOptionsInterface {
  class = '';
  backgroundGradient = false;
  backgroundColor = 'transparent';
  backgroundGradientStopColor = 'transparent';
  backgroundOpacity = 1;
  backgroundStroke = 'transparent';
  backgroundStrokeWidth = 0;
  backgroundPadding = 5;
  percent = 0;
  radius = 90;
  space = 4;
  toFixed = 0;
  maxPercent = 1000;
  renderOnClick = true;
  units = '%';
  unitsFontSize = '10';
  unitsFontWeight = 'normal';
  unitsColor = '#444444';
  outerStrokeGradient = false;
  outerStrokeWidth = 8;
  outerStrokeColor = '#78C000';
  outerStrokeGradientStopColor = 'transparent';
  outerStrokeLinecap = 'round';
  innerStrokeColor = '#C7E596';
  outerStrokeOverColor = '#7a8c5c';
  innerStrokeWidth = 4;
  titleFormat = undefined;
  title: string | Array<string> = 'auto';
  titleColor = '#444444';
  titleFontSize = '20';
  titleFontWeight = 'normal';
  subtitleFormat = undefined;
  subtitle: string | Array<string> = 'progress';
  subtitleColor = '#A9A9A9';
  subtitleFontSize = '10';
  subtitleFontWeight = 'normal';
  imageSrc = undefined;
  imageHeight = undefined;
  imageWidth = undefined;
  animation = true;
  animateTitle = true;
  animateSubtitle = false;
  animationDuration = 3000;
  animateWithFrame = true;
  showTitle = true;
  showSubtitle = true;
  showUnits = true;
  showImage = false;
  showBackground = true;
  showInnerStroke = true;
  clockwise = true;
  responsive = false;
  startFromZero = true;
  showZeroOuterStroke = true;
  endpoint = null;
  endpointSize = 10;
  lazy = false;
}

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent implements OnChanges, OnInit, OnDestroy {

  @Output() clickAction = new EventEmitter<MouseEvent>();

  @Input() name: string;
  @Input() class: string;
  @Input() backgroundGradient: boolean;
  @Input() backgroundColor: string;
  @Input() backgroundGradientStopColor: string;
  @Input() backgroundOpacity: number;
  @Input() backgroundStroke: string;
  @Input() backgroundStrokeWidth: number;
  @Input() backgroundPadding: number;

  @Input() radius: number;
  @Input() space: number;
  @Input() percent: number;
  @Input() toFixed: number;
  @Input() maxPercent: number;
  @Input() renderOnClick: boolean;

  @Input() units: string;
  @Input() unitsFontSize: string;
  @Input() unitsFontWeight: string;
  @Input() unitsColor: string;

  @Input() outerStrokeGradient: boolean;
  @Input() outerStrokeWidth: number;
  @Input() outerStrokeColor: string;
  @Input() outerStrokeGradientStopColor: string;
  @Input() outerStrokeLinecap: string;

  @Input() innerStrokeColor: string;
  @Input() outerStrokeOverColor: string;
  @Input() innerStrokeWidth: string | number;

  @Input() titleFormat: () => void;
  @Input() title: string | Array<string>;
  @Input() titleColor: string;
  @Input() titleFontSize: string;
  @Input() titleFontWeight: string;

  @Input() subtitleFormat: () => void;
  @Input() subtitle: string | string[];
  @Input() subtitleColor: string;
  @Input() subtitleFontSize: string;
  @Input() subtitleFontWeight: string;

  @Input() imageSrc: string;
  @Input() imageHeight: number;
  @Input() imageWidth: number;

  @Input() animation: boolean;
  @Input() animateTitle: boolean;
  @Input() animateSubtitle: boolean;
  @Input() animationDuration: number;

  @Input() showTitle: boolean;
  @Input() showSubtitle: boolean;
  @Input() showUnits: boolean;
  @Input() showImage: boolean;
  @Input() showBackground: boolean;
  @Input() showInnerStroke: boolean;
  @Input() clockwise: boolean;
  @Input() responsive: boolean;
  @Input() startFromZero: boolean;
  @Input() showZeroOuterStroke: boolean;

  @Input() endpoint: 'circle' | null;
  @Input() endpointSize: number;

  @Input() lazy: boolean;

  // tslint:disable-next-line:no-input-rename
  @Input('options') templateOptions: CircleProgressOptions;

  private timerSubscription: Subscription;
  private frameSubscription: number;
  private document: Document;

  // <svg> of component
  svgElement: HTMLElement = null;
  // whether <svg> is in viewport
  isInViewport = false;
  // event for notifying viewport change caused by scrolling or resizing
  onViewportChanged: EventEmitter<{ oldValue: boolean, newValue: boolean }> = new EventEmitter();
  window: Window;
  viewportChangedSubscriber: Subscription = null;

  svg: any;

  options: CircleProgressOptions = new CircleProgressOptions();
  defaultOptions: CircleProgressOptions = new CircleProgressOptions();
  lastPercent = 0;
  gradientUUID: string = null;

  render = () => {

    this.applyOptions();

    if (this.options.lazy) {
      // Draw svg if it doesn't exist
      if (this.svgElement === null) {
        this.draw(this.lastPercent);
      }
      // Draw it only when it's in the viewport
      if (this.isInViewport) {
        // Draw it at the latest position when I am in.
        if (this.options.animation && this.options.animationDuration > 0) {
          this.animate(this.lastPercent, this.options.percent);
        } else {
          this.draw(this.options.percent);
        }
        this.lastPercent = this.options.percent;
      }
    } else {
      if (this.options.animation && this.options.animationDuration > 0) {
        this.animate(this.lastPercent, this.options.percent);
      } else {
        this.draw(this.options.percent);
      }
      this.lastPercent = this.options.percent;
    }
  }

  polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadius = angleInDegrees * Math.PI / 180;
    const x = centerX + Math.sin(angleInRadius) * radius;
    const y = centerY - Math.cos(angleInRadius) * radius;
    return {x, y};
  }

  draw = (percent: number) => {
    // make percent reasonable
    percent = (percent === undefined) ? this.options.percent : Math.abs(percent);
    // circle percent shouldn't be greater than 100%.
    const isOver = (percent <= this.options.maxPercent) && (percent > 100);
    const circlePercent = isOver ? percent % 100 : percent;
    // determine box size
    let boxSize = this.options.radius * 2 + this.options.outerStrokeWidth * 2;
    if (this.options.showBackground) {
      boxSize += (this.options.backgroundStrokeWidth * 2 + this.max(0, this.options.backgroundPadding * 2));
    }
    // the centre of the circle
    const centre = {x: boxSize / 2, y: boxSize / 2};
    // the start point of the arc
    const startPoint = {x: centre.x, y: centre.y - this.options.radius};
    // get the end point of the arc
    const endPoint = this.polarToCartesian(centre.x, centre.y, this.options.radius, 360 * (this.options.clockwise ?
      circlePercent :
      (100 - circlePercent)) / 100);  // ####################
    // We'll get an end point with the same [x, y] as the start point when percent is 100%, so move x a little bit.
    if (circlePercent === 100) {
      endPoint.x = endPoint.x + (this.options.clockwise ? -0.01 : +0.01);
    }
    // largeArcFlag and sweepFlag
    let largeArcFlag: any;
    let sweepFlag: any;
    if (circlePercent > 50) {
      [largeArcFlag, sweepFlag] = this.options.clockwise ? [1, 1] : [1, 0];
    } else {
      [largeArcFlag, sweepFlag] = this.options.clockwise ? [0, 1] : [0, 0];
    }
    // percent may not equal the actual percent
    const titlePercent = this.options.animateTitle ? percent : this.options.percent;
    const titleTextPercent = titlePercent > this.options.maxPercent ?
      `${ this.options.maxPercent.toFixed(this.options.toFixed) }+` : titlePercent.toFixed(this.options.toFixed);
    const subtitlePercent = this.options.animateSubtitle ? percent : this.options.percent;
    // get title object
    const title = {
      x: centre.x,
      y: centre.y,
      textAnchor: 'middle',
      color: this.options.titleColor,
      fontSize: this.options.titleFontSize,
      fontWeight: this.options.titleFontWeight,
      texts: [],
      tspans: []
    };
    // from v0.9.9, both title and titleFormat(...) may be an array of string.
    if (this.options.titleFormat !== undefined && this.options.titleFormat.constructor.name === 'Function') {
      const formatted = this.options.titleFormat(titlePercent);
      if (formatted instanceof Array) {
        title.texts = [...formatted];
      } else {
        title.texts.push(formatted.toString());
      }
    } else {
      if (this.options.title === 'auto') {
        title.texts.push(titleTextPercent);
      } else {
        if (this.options.title instanceof Array) {
          title.texts = [...this.options.title];
        } else {
          title.texts.push(this.options.title.toString());
        }
      }
    }
    // get subtitle object
    const subtitle = {
      x: centre.x,
      y: centre.y,
      textAnchor: 'middle',
      color: this.options.subtitleColor,
      fontSize: this.options.subtitleFontSize,
      fontWeight: this.options.subtitleFontWeight,
      texts: [],
      tspans: []
    };

    // from v0.9.9, both subtitle and subtitleFormat(...) may be an array of string.
    if (this.options.subtitleFormat !== undefined && this.options.subtitleFormat.constructor.name === 'Function') {
      const formatted = this.options.subtitleFormat(subtitlePercent);
      if (formatted instanceof Array) {
        subtitle.texts = [...formatted];
      } else {
        subtitle.texts.push(formatted.toString());
      }
    } else {
      if (this.options.subtitle instanceof Array) {
        subtitle.texts = [...this.options.subtitle];
      } else {
        subtitle.texts.push(this.options.subtitle.toString());
      }
    }
    // get units object
    const units = {
      text: `${ this.options.units }`,
      fontSize: this.options.unitsFontSize,
      fontWeight: this.options.unitsFontWeight,
      color: this.options.unitsColor
    };
    // get total count of text lines to be shown
    let rowCount = 0;
    let rowNum = 1;
    if (this.options.showTitle) {
      rowCount += title.texts.length;
    }
    if (this.options.showSubtitle) {
      rowCount += subtitle.texts.length;
    }
    // calc dy for each tspan for title
    if (this.options.showTitle) {
      for (const span of title.texts) {
        title.tspans.push({span, dy: this.getRelativeY(rowNum, rowCount)});
        rowNum++;
      }
    }
    // calc dy for each tspan for subtitle
    if (this.options.showSubtitle) {
      for (const span of subtitle.texts) {
        subtitle.tspans.push({span, dy: this.getRelativeY(rowNum, rowCount)});
        rowNum++;
      }
    }
    // create ID for gradient element
    if (null === this.gradientUUID) {
      this.gradientUUID = this.uuid();
    }
    // Bring it all together
    this.svg = {
      viewBox: `0 0 ${ boxSize } ${ boxSize }`,
      // Set both width and height to '100%' if it's responsive
      width: this.options.responsive ? '100%' : boxSize,
      height: this.options.responsive ? '100%' : boxSize,
      widthDiv: this.options.responsive ? '100%' : boxSize + 'px',
      heightDiv: this.options.responsive ? '100%' : boxSize + 'px',
      backgroundCircle: {
        cx: centre.x,
        cy: centre.y,
        r: this.options.radius + this.options.outerStrokeWidth / 2 + this.options.backgroundPadding,
        fill: this.options.backgroundColor,
        fillOpacity: this.options.backgroundOpacity,
        stroke: this.options.backgroundStroke,
        strokeWidth: this.options.backgroundStrokeWidth,
      },
      path: {
        // A rx ry x-axis-rotation large-arc-flag sweep-flag x y (https://developer.mozilla.org/en/docs/Web/SVG/Tutorial/Paths#Arcs)
        d: `M ${ startPoint.x } ${ startPoint.y }
        A ${ this.options.radius } ${ this.options.radius } 0 ${ largeArcFlag } ${ sweepFlag } ${ endPoint.x } ${ endPoint.y }`,
        stroke: isOver ? this.options.outerStrokeOverColor : this.options.outerStrokeColor,
        strokeWidth: this.options.outerStrokeWidth,
        strokeLinecap: this.options.outerStrokeLinecap,
        fill: 'none'
      },
      endPoint: {
        cx: endPoint.x,
        cy: endPoint.y,
        r: this.options.endpointSize,
        fill: isOver ? this.options.outerStrokeOverColor : this.options.outerStrokeColor,
      },
      circle: {
        cx: centre.x,
        cy: centre.y,
        r: this.options.radius - this.options.space - this.options.outerStrokeWidth / 2 - this.options.innerStrokeWidth / 2,
        fill: 'none',
        stroke: isOver ? this.options.outerStrokeColor : this.options.innerStrokeColor,
        strokeWidth: this.options.innerStrokeWidth,
      },
      title,
      units,
      subtitle,
      outerLinearGradient: {
        id: 'outer-linear-' + this.gradientUUID,
        colorStop1: this.options.outerStrokeColor,
        colorStop2: isOver ?
          this.options.outerStrokeOverColor :
          this.options.outerStrokeGradientStopColor === 'transparent' ? '#FFF' : this.options.outerStrokeGradientStopColor,
      },
      radialGradient: {
        id: 'radial-' + this.gradientUUID,
        colorStop1: this.options.backgroundColor,
        colorStop2: this.options.backgroundGradientStopColor === 'transparent' ? '#FFF' : this.options.backgroundGradientStopColor,
      }
    };
    this.loadImageMeta(centre);
  }

  getAnimationParameters = (previousPercent: number, currentPercent: number) => {
    const MIN_INTERVAL = 10;
    let times: number;
    let step: number;
    let interval: number;
    const fromPercent = this.options.startFromZero ? 0 : (previousPercent < 0 ? 0 : previousPercent);
    const toPercent = currentPercent < 0 ? 0 : this.min(currentPercent, this.options.maxPercent);
    const delta = Math.abs(Math.round(toPercent - fromPercent));

    if (delta >= 100) {
      // we will finish animation in 100 times
      times = 100;
      if (!this.options.animateTitle && !this.options.animateSubtitle) {
        step = 1;
      } else {
        // show title or subtitle animation even if the arc is full, we also need to finish it in 100 times.
        step = Math.round(delta / times);
      }
    } else {
      // we will finish in as many times as the number of percent.
      times = delta;
      step = 1;
    }
    // Get the interval of timer
    interval = Math.round(this.options.animationDuration / times);
    // Readjust all values if the interval of timer is extremely small.
    if (interval < MIN_INTERVAL) {
      interval = MIN_INTERVAL;
      times = this.options.animationDuration / interval;
      if (!this.options.animateTitle && !this.options.animateSubtitle && delta > 100) {
        step = Math.round(100 / times);
      } else {
        step = Math.round(delta / times);
      }
    }
    // step must be greater than 0.
    if (step < 1) {
      step = 1;
    }
    return {times, step, interval};
  }

  animate = (previousPercent: number, currentPercent: number) => {
    if (this.timerSubscription && !this.timerSubscription.closed) {
      this.timerSubscription.unsubscribe();
    }
    if (this.frameSubscription) {
      cancelAnimationFrame(this.frameSubscription);
    }
    const fromPercent = this.options.startFromZero ? 0 : previousPercent;
    const toPercent = currentPercent;

    // Animate with Animation Frame to optimize JS resource
    if (this.options.animateWithFrame) {

      const callback = () => {
        const timestamp = performance.now();
        const timestampPos = (timestamp - timestampStart);
        if (timestampPos >= this.options.animationDuration) {
          this.draw(toPercent);
          cancelAnimationFrame(this.frameSubscription);
        } else {
          this.draw(fromPercent + (((toPercent - fromPercent) / this.options.animationDuration) * timestampPos));
          cancelAnimationFrame(this.frameSubscription);
          this.frameSubscription = requestAnimationFrame(callback);
        }
      };

      const timestampStart = performance.now();
      this.frameSubscription = requestAnimationFrame(callback);

    } else {
      const {step: step, interval: interval} = this.getAnimationParameters(fromPercent, toPercent);
      let count = fromPercent;

      if (fromPercent < toPercent) {
        this.timerSubscription = timer(0, interval).subscribe(() => {
          count += step;
          if (count <= toPercent) {
            if (!this.options.animateTitle && !this.options.animateSubtitle && count >= 100) {
              this.draw(toPercent);
              this.timerSubscription.unsubscribe();
            } else {
              this.draw(count);
            }
          } else {
            this.draw(toPercent);
            this.timerSubscription.unsubscribe();
          }
        });
      } else {
        this.timerSubscription = timer(0, interval).subscribe(() => {
          count -= step;
          if (count >= toPercent) {
            if (!this.options.animateTitle && !this.options.animateSubtitle && toPercent >= 100) {
              this.draw(toPercent);
              this.timerSubscription.unsubscribe();
            } else {
              this.draw(count);
            }
          } else {
            this.draw(toPercent);
            this.timerSubscription.unsubscribe();
          }
        });
      }
    }
  }

  emitClickEvent(event: MouseEvent): void {
    if (this.options.renderOnClick) {
      this.animate(0, this.options.percent);
    }
    if (this.clickAction.observers.length > 0) {
      this.clickAction.emit(event);
    }
  }

  private applyOptions = () => {
    // the options of <circle-progress> may change already
    for (const name of Object.keys(this.options)) {
      if (this.hasOwnProperty(name) && this[name] !== undefined) {
        this.options[name] = this[name];
      } else if (this.templateOptions && this.templateOptions[name] !== undefined) {
        this.options[name] = this.templateOptions[name];
      }
    }
    // make sure key options valid
    this.options.radius = Math.abs(+this.options.radius);
    this.options.space = +this.options.space;
    this.options.percent = +this.options.percent > 0 ? +this.options.percent : 0;
    this.options.maxPercent = Math.abs(+this.options.maxPercent);
    this.options.animationDuration = Math.abs(this.options.animationDuration);
    this.options.outerStrokeWidth = Math.abs(+this.options.outerStrokeWidth);
    this.options.innerStrokeWidth = Math.abs(+this.options.innerStrokeWidth);
    this.options.backgroundPadding = +this.options.backgroundPadding;
  }

  private getRelativeY = (rowNum: number, rowCount: number): string => {
    // why '-0.18em'? It's a magic number when property 'alignment-baseline' equals 'baseline'. :)
    const initialOffset = -0.18;
    const offset = 1;
    return (initialOffset + offset * (rowNum - rowCount / 2)).toFixed(2) + 'em';
  }

  private min = (a: number, b: number) => {
    return a < b ? a : b;
  }

  private max = (a: number, b: number) => {
    return a > b ? a : b;
  }

  private uuid = () => {
    // https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
    let dt = new Date().getTime();
    const uuidVal = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuidVal;
  }

  public isDrawing(): boolean {
    return (this.timerSubscription && !this.timerSubscription.closed);
  }

  public findSvgElement(): void {
    if (this.svgElement === null) {
      const tags = this.elRef.nativeElement.getElementsByTagName('svg');
      if (tags.length > 0) {
        this.svgElement = tags[0];
      }
    }
  }

  private isElementInViewport(el): boolean {
    // Return false if el has not been created in page.
    if (el === null || el === undefined) {
      return false;
    }
    // Check if the element is out of view due to a container scrolling
    const rect = el.getBoundingClientRect();
    let parent = el.parentNode;
    let parentRect;
    do {
      parentRect = parent.getBoundingClientRect();
      if (rect.top >= parentRect.bottom) {
        return false;
      }
      if (rect.bottom <= parentRect.top) {
        return false;
      }
      if (rect.left >= parentRect.right) {
        return false;
      }
      if (rect.right <= parentRect.left) {
        return false;
      }
      parent = parent.parentNode;
    } while (parent !== this.document.body);
    // Check its within the document viewport
    if (rect.top >= (this.window.innerHeight || this.document.documentElement.clientHeight)) {
      return false;
    }
    if (rect.bottom <= 0) {
      return false;
    }
    if (rect.left >= (this.window.innerWidth || this.document.documentElement.clientWidth)) {
      return false;
    }
    return rect.right > 0;
  }

  checkViewport = () => {
    this.findSvgElement();
    const previousValue = this.isInViewport;
    this.isInViewport = this.isElementInViewport(this.svgElement);
    if (previousValue !== this.isInViewport && this.onViewportChanged.observers.length > 0) {
      this.ngZone.run(() => {
        this.onViewportChanged.emit({oldValue: previousValue, newValue: this.isInViewport});
      });
    }
  }

  onScroll = (event: Event) => {
    this.checkViewport();
  }

  loadEventsForLazyMode = () => {
    if (this.options.lazy) {
      this.ngZone.runOutsideAngular(() => {
        this.document.addEventListener('scroll', this.onScroll, true);
        this.window.addEventListener('resize', this.onScroll, true);
      });
      if (this.viewportChangedSubscriber === null) {
        this.viewportChangedSubscriber = this.onViewportChanged.subscribe(({oldValue, newValue}) => {
          if (newValue) {
            this.render();
          }
        });
      }
      // svgElement must be created in DOM before being checked.
      // Is there a better way to check the existence of svgElemnt?
      const timerValue = timer(0, 50).subscribe(() => {
        this.svgElement === null ? this.checkViewport() : timerValue.unsubscribe();
      });
    }
  }

  unloadEventsForLazyMode = () => {
    // Remove event listeners
    this.document.removeEventListener('scroll', this.onScroll, true);
    this.window.removeEventListener('resize', this.onScroll, true);
    // Unsubscribe onViewportChanged
    if (this.viewportChangedSubscriber !== null) {
      this.viewportChangedSubscriber.unsubscribe();
      this.viewportChangedSubscriber = null;
    }
  }

  constructor(
    defaultOptions: CircleProgressOptions,
    private ngZone: NgZone,
    private elRef: ElementRef,
    injector: Injector,
  ) {
    this.document = injector.get(DOCUMENT);
    this.window = this.document.defaultView;
    Object.assign(this.options, defaultOptions);
    Object.assign(this.defaultOptions, defaultOptions);
  }

  ngOnInit() {
    this.loadEventsForLazyMode();
  }

  ngOnDestroy() {
    this.unloadEventsForLazyMode();
  }

  ngOnChanges(changes: SimpleChanges) {

    this.render();

    if ('lazy' in changes) {
      changes.lazy.currentValue ? this.loadEventsForLazyMode() : this.unloadEventsForLazyMode();
    }
  }

  loadImageMeta(centre) {
    if (this.options.imageSrc && (!this.options.imageHeight || !this.options.imageWidth)) {
      const img = new Image();
      const that = this;
      img.addEventListener('load', function() {
        that.applyImageMetaToSvg(centre, this.naturalWidth, this.naturalHeight);
      });
      img.src = this.options.imageSrc;
    } else {
      this.applyImageMetaToSvg(centre, this.options.imageWidth, this.options.imageHeight);
    }
  }

  applyImageMetaToSvg(centre, imgWidth, imgHeight) {
    this.svg = Object.assign(this.svg, {
      image: {
        x: centre.x - imgWidth / 2,
        y: centre.y - imgHeight / 2,
        src: this.options.imageSrc,
        width: imgWidth,
        height: imgHeight,
      }
    });
  }

}
