import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ToolbarModalComponent } from '@components/modals/toolbar-modal/toolbar-modal.component';



@NgModule({
  declarations: [
    ToolbarModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    ToolbarModalComponent
  ]
})
export class ToolbarModalModule { }
