import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class MatPaginatorIntlCustom extends MatPaginatorIntl {

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const start = page * pageSize;
    if ((start + pageSize) < length || page > 0) {
      return this.translateServ.translate('paginator.range', {
        page: start + 1,
        size: (start + pageSize) < length ? start + pageSize : length,
        total: length
      });
    } else {
      return this.translateServ.translate('paginator.range-without-total', {
        page: start + 1,
        size: length
      });
    }
  }



  constructor(protected translateServ: TranslocoService) {
    super();

    this.translateServ.langChanges$.subscribe(() => {
      // Delay translation change in order to get new translation file
      window.setTimeout(() => this.translateLabels(), 400);
    });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translateServ.translate('paginator.items-per-page');
    this.firstPageLabel = this.translateServ.translate('paginator.first-page');
    this.lastPageLabel = this.translateServ.translate('paginator.last-page');
    this.nextPageLabel = this.translateServ.translate('paginator.next-page');
    this.previousPageLabel = this.translateServ.translate('paginator.previous-page');
  }
}
