import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'dateMoment'
})
export class DateMomentPipe implements PipeTransform {

  constructor(
    private translate: TranslocoService
  ) {}

  transform(value: any, format?: string, arg1?): any {
    moment.locale(this.translate.getActiveLang());

    let date = moment(value);

    if (format === 'dt') {
      return date.format('YYYY-MM-DD HH:mm:ss');
    } else if (format === 'diff') {
      arg1 = arg1 || 'days';
      return date.diff(moment(), arg1);
    } else if (format === 'from_now') {
      return date.fromNow(!!arg1);
    } else if (format === 'to_now') {
      return date.toNow(!!arg1);
    } else if (format === 'feed') {
      // TODO: RDFR-191 - To remove when managing Timezone
      if (date.isAfter()) {
        date = moment();
      }
      if (date.isAfter(moment().subtract(2, 'days'))) {
        return date.fromNow();
      } else {
        return date.format('LL');
      }
    }

    return date.format(format || 'LL');
  }

}
