import { Injectable } from '@angular/core';
import { params } from '@environments/params';

/**
 * Provide interface to know what breakpoint is active
 */

@Injectable({providedIn: 'root'})
export class ResponsiveService {

  isMobile = false;
  is: { [key: string]: boolean} = {};

  buildResponsive() {
    const breakpoints = Object.keys(params.breakpoints);

    this.isMobile = window.innerWidth < params.breakpoints.md;

    breakpoints.forEach(bp => {
      this.is[bp] = window.innerWidth < params.breakpoints[bp];
    });

  }

}
