import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Color, DarkColor } from '@helpers/_colors';
import { ThemeService } from '@services/theme.service';

@Component({
  selector: 'app-color-chip',
  template: `
    <div class="container" [style.backgroundColor]="backgroundColor">
      {{text}}
    </div>
  `,
  styleUrls: ['./color-chip.component.scss'],
})
export class ColorChipComponent implements OnChanges {

  @Input() text: string;

  @Input() color: string;

  backgroundColor: string;

  constructor(private themeServ: ThemeService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.color && this.color) {
      const colorObj = this.themeServ.isDarkMode() ? new DarkColor(this.color) : new Color(this.color);
      this.backgroundColor = colorObj.hex;
    }
  }

}
