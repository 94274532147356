import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComputeTagInterface } from '@components/forms/compute-input/compute-input.interface';

/**
 * Compute that manage a compute tool with text box
 */

@Component({
  selector: 'app-compute-input',
  templateUrl: './compute-input.component.html',
  styleUrls: ['./compute-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComputeInputComponent),
      multi: true,
    },
  ],
})
export class ComputeInputComponent implements OnChanges {

  @ViewChild('textAreaElement', {static: false}) textAreaElement: ElementRef;

  @Input() value = '';
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() invalid: boolean;

  // Min Rows and Max Rows for text type only
  @Input() minRows = 3;
  @Input() maxRows = 5;

  @Input() tagStart = '{';
  @Input() tagEnd = '}';
  @Input() tags: ComputeTagInterface[];
  @Input() tagsTitle: string;


  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  isHl = false;
  isMouseOver = false;
  isInputFocus = false;

  search: string;
  tagsToDisplay: ComputeTagInterface[];

  operators: ComputeTagInterface[] = [
    {
      tag: ' + ',
      label: '+'
    },
    {
      tag: ' − ',
      label: '-'
    },
    {
      tag: ' * ',
      label: '×'
    },
    {
      tag: ' / ',
      label: '÷'
    },
  ];


  public onChange = (_: any) => {
  };
  public onTouch = () => {
  };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tags || changes.value) && this.tags?.length > 0 && this.value?.length > 0) {
      this.buildSelection();
    }

    if (changes.tags && this.tags?.length > 0) {
      this.buildTagsList();
    }
  }

  focus(options?: FocusOptions) {
    this.textAreaElement?.nativeElement?.focus(options);
  }

  blur() {
    this.textAreaElement?.nativeElement?.blur();
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.isMouseOver = true;
    this.enableHL();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isMouseOver = false;
    this.disableHL();
  }

  onInputFocus() {
    this.isInputFocus = true;
    this.enableHL();
  }

  onInputBlur() {
    this.isInputFocus = false;
    this.disableHL();
  }

  buildSelection() {
    this.tags?.forEach(tag => {
      tag.isAdded = this.value?.indexOf(`${ this.tagStart }${ tag.tag }${ this.tagEnd }`) > -1;
    });
    this.buildTagsList();
  }

  buildTagsList() {
    if (this.search?.length > 0) {
      this.tagsToDisplay = this.tags?.filter(tag => tag.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    } else {
      this.tagsToDisplay = this.tags;
    }
  }

  applyChange(value?: string) {
    value = value || this.value;
    this.buildSelection();
    this.onChange(value);
    this.valueChange.emit(value);
  }

  /**
   * Insert value in El
   * @param value value to add
   * @param isTag is tag ?
   */
  insertInEl(value: string, isTag = false) {
    const el = this.textAreaElement?.nativeElement;
    const valueToAdd = isTag ? `${ this.tagStart }${ value }${ this.tagEnd }` : value;
    if (el?.selectionStart >= 0) {
      const startPos = el.selectionStart;
      const endPos = el.selectionEnd;
      this.value = this.value.substring(0, startPos)
        + valueToAdd
        + this.value.substring(endPos, this.value.length);
    } else {
      this.value += valueToAdd;
    }
    el?.focus();
    this.applyChange(this.value);
  }

  onSearch(value: string) {
    this.search = value;
    this.buildTagsList();
  }

  /**
   * ControlValueAccesor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    this.value = inputValue;

    if (this.tags?.length > 0) {
      this.buildSelection();
    }

    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  /**
   * Private functions
   */
  private enableHL() {
    this.isHl = this.isInputFocus === true;
  }

  private disableHL() {
    if (!this.isInputFocus && !this.isMouseOver) {
      this.isHl = false;
    }
  }
}
