import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { ThemeService } from '@services/theme.service';
import { ConnectionInterceptor } from '@helpers/connection.interceptor';
import { ErrorsInterceptor } from '@helpers/errors.interceptor';
import { GeneralService } from '@services/api';
import { CartService, NewsFeedService } from '@services/api/client';
import { ConnectionService } from '@services/connection.service';
import { IonicStorageModule } from '@ionic/storage';
import { TranslocoService } from '@ngneat/transloco';
import { CookiesService } from '@services/cookies.service';
import { FormErrorsService } from '@services/form-errors.service';
import { ToolboxService } from '@services/toolbox.service';
import { CommonService } from '@services/common.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TrackingClientService } from '@services/client';
import { ProfileViewModule } from '@components/app/profile-view';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CguAgreementModule } from '@components/modals/cgu-agreement';
import { TeamService } from '@services/team.service';
import { PlatformModule } from '@angular/cdk/platform';
import { AccessControlService } from '@services/access-control';
import { CircleProgressModule } from '@components/utils/circle-progress';
import { NgxEditorModule } from 'ngx-editor';
import { AppHeaderModule } from '@components/app/header';
import { ResponsiveService } from '@services/responsive.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCustom } from '@helpers/_material/mat-paginator-intl-custom';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxMaskModule } from 'ngx-mask';
import { StateService } from '@services/state.service';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import { CalendarModule, DateAdapter as CalendarDateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { MediasService } from '@services/medias.service';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { IntercomModule } from 'ng-intercom';
import { environment } from '@environments/environment';
import { NotificationsService } from '@services/notifications';
import { OffersModule } from '@components/modals/offers';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/pt';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/it';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    // Transition:
    // - https://github.com/ionic-team/ionic-framework/blob/master/core/src/utils/transition/md.transition.ts
    // - https://www.damirscorner.com/blog/posts/20200501-CustomizingPageTransitionsInIonic5.html
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '_roadoo',
      driverOrder: ['localstorage', 'indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    OAuthModule.forRoot(),
    ProfileViewModule,
    BrowserAnimationsModule,
    CguAgreementModule,
    PlatformModule,
    CircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 10,
      outerStrokeColor: '#000000',
      innerStrokeColor: '#A7A9AB',
      animationDuration: 2000,
      space: -10,
      maxPercent: 10000,
      outerStrokeLinecap: 'square',
      animateTitle: false,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      showBackground: false,
      responsive: true,
      startFromZero: true,
      lazy: false,
      renderOnClick: false,
      showZeroOuterStroke: false
    }),
    NgxEditorModule,
    AppHeaderModule,
    GoogleChartsModule.forRoot({version: '49'}),
    NgxMaskModule.forRoot(),
    MaterialCssVarsModule.forRoot(),
    CalendarModule.forRoot({ provide: CalendarDateAdapter, useFactory: momentAdapterFactory }),
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
      vertical_padding: 20,
      horizontal_padding: 20,
    }),
    MaterialCssVarsModule.forRoot({
      darkThemeClass: 'dark-theme',
      lightThemeClass: 'light-theme'
    }),
    OffersModule,
    MatAutocompleteModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConnectionInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, deps: [TranslocoService], useFactory: (serv) => serv.getDefaultLang()},
    TranslocoService,
    CommonService,
    ThemeService,
    GeneralService,
    TrackingClientService,
    ConnectionService,
    CookiesService,
    MediaCapture,
    Camera,
    File,
    FormErrorsService,
    ToolboxService,
    NewsFeedService,
    TeamService,
    AccessControlService,
    ResponsiveService,
    StateService,
    NotificationsService,
    MediasService,
    CartService,
    // MAT_DATE_MODULE
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom},
    {provide: MAT_DATE_LOCALE, useValue: 'en-EN'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: NgxMatDateAdapter,
      useClass: NgxMatMomentAdapter,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'L, LT'
        },
        display: {
          dateInput: 'L, LT',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
