import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordInputComponent } from './password-input.component';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    PasswordInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    FormsModule,
    MatTooltipModule
  ],
  exports: [
    PasswordInputComponent
  ]
})
export class PasswordInputModule { }
