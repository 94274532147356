import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class FormErrorsService {

  private enabled = true;

  /**
   * Enable the service.
   * Can be used to treat with first submit
   */
  enable() {
    this.enabled = true;
  }

  /**
   * Disbale the service
   */
  disable() {
    this.enabled = false;
  }

  /**
   * Indicate if form control has defined error or not
   * > fieldName and errorCode are optional.
   * > If fieldName is not provide, form object is used to detect errors.
   * > If errorCode is not provied, all errors of control are used detect errors.
   */
  hasWrongValue(form: AbstractControl, fieldName: string = null, errorCode: string = null): boolean {
    return this.fieldHasErrors(form, fieldName, errorCode);
  }

  /**
   * Like hasWrongValue but for correct value
   */
  hasCorrectValue(form: AbstractControl, fieldName: string = null, errorCode: string = null): boolean {
    const control = this.findFieldControl(form, fieldName);
    // field found && user changed it && it doesn't hold a wrong value
    return control && !control.pristine && !this.hasWrongValue(form, fieldName, errorCode);
  }

  fieldHasErrors(form: AbstractControl, fieldName: string | null, errorCode: string | null): boolean {
    const control = this.findFieldControl(form, fieldName);

    if (control && this.enabled) {
      if (null === errorCode) {
        return control.invalid;
      } else {
        return control.hasError(errorCode);
      }
    } else {
      return false;
    }
  }

  dirty(form: AbstractControl, fieldName: string) {
    return this.findFieldControl(form, fieldName).dirty;
  }

  setErrors(form: AbstractControl, fieldName: string | null, inputErrors: any) {
    const control = this.findFieldControl(form, fieldName);
    let errors = Object.assign(control.errors || {}, inputErrors || {});

    if (inputErrors && typeof inputErrors === 'object') {
      for (const key of Object.keys(inputErrors)) {
        if (null === inputErrors[key] && errors[key]) {
          delete inputErrors[key];
        }
      }
    }

    if (0 === Object.keys(errors).length || null === inputErrors) {
      errors = null;
    }

    // console.log(fieldName, inputErrors, errors);

    if (null === fieldName) {
      form.setErrors(errors);
    } else {
      control.setErrors(errors);
    }
  }

  removeError(form: AbstractControl, fieldName: string | null, errorName: string) {
    const control = this.findFieldControl(form, fieldName);

    if (control.errors && Object.keys(control.errors).indexOf(errorName) > -1) {
      delete control.errors[errorName];
    }

    if (control.errors && Object.keys(control.errors).length < 1) {
      control.setErrors(null);
    }
  }

  getErrors(control: AbstractControl): string[] {
    return Object.keys(control.errors)
      .filter((error: any) => control.errors[error])
      .map((error: any) => {
        return error;
      });
  }

  private hasFieldName(form: AbstractControl, fieldName: string): boolean {
    const control = this.findFieldControl(form, fieldName);
    return control != null;
  }

  private findFieldControl(form: AbstractControl, fieldName: string | null): AbstractControl {
    if (null === fieldName) {
      return form;
    }
    return form.get(fieldName);
  }
}
