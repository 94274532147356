import { Injectable } from '@angular/core';
import { Color, DarkColor, generateColor } from '@helpers/_colors';
import { appColors } from '@environments/params';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ConnectionService } from './connection.service';
import { SchemeInterface, schemes } from '@environments/schemes';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Storage } from '@ionic/storage';

export interface ThemeInterface {
  primary: string;
  secondary: string;
  tertiary?: string;
  eStore?: string;
}

export type DarkModeOptions = 'dark' | 'light' | 'auto';

/**
 * Theme service
 * This component manage themes of app with:
 *   - custom theme
 *   - sub-domain theme
 *   - app scheme theme
 */

@Injectable({providedIn: 'root'})
export class ThemeService {
  currentClientTheme = null;
  scheme: SchemeInterface;

  private currentDarkMode: DarkModeOptions = 'auto';
  private isDarkModeState = false;

  /**
   * Themes for sub-domain
   */
  private themes = [
    {
      name: 'main',
      colors: {
        primary: '#3ab9dc',
        secondary: '#75cee6',
        tertiary: '#75cee6',
        dark: {
          primary: '#22a0c3',
          secondary: '#19728a',
          tertiary: '#19728a',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/fr/app/roadoo/id1013823680',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.roadoonetwork',
        downloadKey: 'login.download-mobile.default',
        isBusinessManager: false
      }
    },
    {
      name: 'sbmoffshore',
      faviconPath: '/assets/icons/favicons/sbmoffshore.png',
      colors: {
        primary: '#65666b',
        secondary: '#76777b',
        tertiary: '#76777b',
        dark: {
          primary: '#93949a',
          secondary: '#85868a',
          tertiary: '#757679',
        }
      },
      apps: false
    },
    {
      name: 'mylink',
      faviconPath: '/assets/icons/favicons/mylink.png',
      colors: {
        primary: '#4b4a4a',
        secondary: '#D8750F',
        tertiary: '#D8750F',
        dark: {
          primary: '#b6b4b4',
          secondary: '#f08c28',
          tertiary: '#f08c28',
        }
      },
      apps: {
        ios: false,
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.mylinknewrest',
        downloadKey: 'login.download-mobile.default',
        isBusinessManager: true
      }
    },
    {
      name: 'nexity',
      faviconPath: '/assets/icons/favicons/nexity.png',
      colors: {
        primary: '#d22238',
        secondary: '#9B192A',
        tertiary: '#9B192A',
        dark: {
          primary: '#dd2c41',
          secondary: '#e66577',
          tertiary: '#e66577',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/fr/app/les-experts-by-nexity-roadoo/id1301366611',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.nexity',
        downloadKey: 'login.download-mobile.default',
        isBusinessManager: false
      }
    },
    {
      name: 'flex',
      faviconPath: '/assets/icons/favicons/flex.png',
      colors: {
        primary: '#d5420c',
        secondary: '#782608',
        tertiary: '#782608',
        dark: {
          primary: '#f3602b',
          secondary: '#f7a587',
          tertiary: '#f7a587',
        }
      },
      apps: {
        ios: false,
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.flex',
        downloadKey: 'login.download-mobile.default',
        isBusinessManager: false
      }
    },
    {
      name: 'maslo',
      faviconPath: '/assets/icons/favicons/maslo.png',
      colors: {
        primary: '#182242',
        secondary: '#ff5757',
        tertiary: '#ff5757',
        dark: {
          primary: '#bcc6e7',
          secondary: '#a80000',
          tertiary: '#a80000',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/fr/app/maslo/id1618254425',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.maslo',
        downloadKey: 'login.download-mobile.maslo',
        isBusinessManager: false
      }
    },
    {
      name: 'pistonr',
      faviconPath: '/assets/icons/favicons/pistonr.png',
      colors: {
        primary: '#ff914d',
        secondary: '#664437',
        tertiary: '#cc743d',
        dark: {
          primary: '#b34400',
          secondary: '#c8a698',
          tertiary: '#c16a33',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/fr/app/pistonr/id1620543201',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.pistonr',
        downloadKey: 'login.download-mobile.pistonr',
        isBusinessManager: false
      }
    }, {
      name: 'frendli',
      faviconPath: '/assets/icons/favicons/frendli.png',
      colors: {
        primary: '#0a1667',
        secondary: '#E8EFFF',
        tertiary: '#0a1667',
        dark: {
          primary: '#99a5f5',
          secondary: '#00081a',
          tertiary: '#99a5f5',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/us/app/frendli/id1637127072',
        android: 'https://play.google.com/store/apps/details?id=fr.frendli',
        downloadKey: 'login.download-mobile.frendli',
        isBusinessManager: false
      }
    }];

  constructor(private materialCssVarsService: MaterialCssVarsService,
              private connectionServ: ConnectionService,
              private storage: Storage,
              private ionicPlatform: Platform) {
  }

  /**
   * Apply or get theme for dark mode
   * @param newTheme new theme to apply
   */
  async darkTheme(newTheme?: DarkModeOptions) {
    if (!newTheme) {
      this.currentDarkMode = (await this.storage.get('__dark-theme') as DarkModeOptions) || 'auto';
    } else {
      this.currentDarkMode = newTheme;
      await this.storage.set('__dark-theme', this.currentDarkMode);
    }
    this.isDarkModeState = this.currentDarkMode === 'dark' || (this.currentDarkMode !== 'light' && window.matchMedia('(prefers-color-scheme: dark)').matches);

    appColors.used = this.isDarkModeState ? appColors.dark : appColors.light;

    // Apply new theme
    this.materialCssVarsService.setDarkTheme(this.isDarkModeState);
    this.applyStatusBarStyle();

    if (this.currentClientTheme) {
      this.toggleThemeFromClientList(this.currentClientTheme);
    }

    return this.currentDarkMode;
  }

  /**
   * Indicate if is dark mode
   */
  isDarkMode() {
    return this.isDarkModeState;
  }

  /**
   * Apply style to status bar according dark theme
   */
  applyStatusBarStyle() {
    if (this.ionicPlatform.is('capacitor')) {
      StatusBar.setStyle({style: this.isDarkModeState ? Style.Dark : Style.Light}).then(/* Nothing to do */);

      if (this.ionicPlatform.is('android')) {
        StatusBar.setBackgroundColor({color: this.isDarkModeState ? '#121212' : '#FFFFFF'}).then(/* Nothing to do */);
      }
    }
  }

  /**
   * Toggle name color in document
   * @param name name if theme
   */
  toggleThemeFromClientList(name: string) {
    const theme = this.themes.find(themeInList => themeInList.name === name);

    let primary: string;
    let secondary: string;
    let tertiary: string;

    if (theme?.colors) {

      // Dark/Light mode
      if (this.isDarkModeState) {
        primary = theme.colors.dark.primary;
        secondary = theme.colors.dark.secondary;
        tertiary = theme.colors.dark.tertiary;
      } else {
        primary = theme.colors.primary;
        secondary = theme.colors.secondary;
        tertiary = theme.colors.tertiary;
      }

      appColors.used.roadooPrimary = primary;
      appColors.used.roadooSecondary = secondary;
      appColors.used.roadooTertiary = tertiary;

      document.body.style.setProperty(`--ion-color-primary`, primary);
      document.body.style.setProperty(`--ion-color-secondary`, secondary);
      document.body.style.setProperty(`--ion-color-tertiary`, tertiary);

      this.currentClientTheme = theme.name;

      return true;
    } else {
      this.currentClientTheme = null;

      return false;
    }
  }

  /**
   * Get current theme
   */
  getCurrentTheme(): ThemeInterface {
    const platform = this.connectionServ.getSelectedPlatformValue();

    return {
      primary: platform?.primary_color || appColors.used.roadooPrimary,
      secondary: platform?.secondary_color || appColors.used.roadooSecondary
    };
  }

  /**
   * Apply new theme
   * @param theme them object
   */
  applyTheme(theme: ThemeInterface) {
    this.applyColor('primary', theme.primary);
    this.applyColor('secondary', theme.secondary);

    let tertiary;
    if (theme.tertiary) {
      tertiary = theme.tertiary;
    } else {
      const color = new Color(theme.primary);
      tertiary = color.shade(.3).hex;
    }

    let eStore;
    if (theme.eStore) {
      eStore = theme.eStore;
    } else {
      const color = new Color(theme.primary);
      eStore = color.shade(.73).hex;
    }
    this.applyColor('tertiary', tertiary);
    this.applyColor('e-store', eStore, false);

    // Update JS colors
    appColors.light.primary = theme.primary;
    appColors.light.secondary = theme.secondary;
    appColors.light.tertiary = tertiary;
    appColors.light.eStore = eStore;

    // Update JS colors for Dark mode
    appColors.dark.primary = (new DarkColor(theme.primary).hex);
    appColors.dark.secondary = (new DarkColor(theme.secondary).hex);
    appColors.dark.tertiary = (new DarkColor(tertiary).hex);

    // Update Material
    this.materialCssVarsService.setPrimaryColor(theme.primary);
    this.materialCssVarsService.setAccentColor(theme.secondary);
  }

  /**
   * Remove current theme and apply default
   */
  removeTheme() {
    // Update CSS
    this.removeColor('primary');
    this.removeColor('secondary');
    this.removeColor('tertiary');
    this.removeColor('e-store');

    // Update JS
    ['light', 'dark'].forEach(key => {
      appColors[key].primary = appColors[key].roadooPrimary;
      appColors[key].secondary = appColors[key].roadooSecondary;
      appColors[key].tertiary = appColors[key].roadooTertiary;
      if (key === 'light') {
        const color = new Color(appColors[key].primary);
        appColors[key].eStore = color.shade(.73).hex;
      }
    });

    // Update Material
    // TODO: Propose a PR with a function to remove variables
    ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700'].forEach(key => {
      document.documentElement.style.removeProperty(`--palette-primary-${ key }`);
      document.documentElement.style.removeProperty(`--palette-primary-contrast-${ key }`);
      document.documentElement.style.removeProperty(`--palette-accent-${ key }`);
      document.documentElement.style.removeProperty(`--palette-accent-contrast-${ key }`);
    });
    // this.materialCssVarsService.setPrimaryColor(params.colors.roadooPrimary);
    // this.materialCssVarsService.setAccentColor(params.colors.roadooSecondary);

    if (this.currentClientTheme) {
      this.toggleThemeFromClientList(this.currentClientTheme);
    }
  }

  /**
   * Get theme by name
   * @param name theme name
   */
  getTheme(name: string) {
    return this.themes.find(themeInList => themeInList.name === name);
  }

  /**
   * Apply color and determine shade
   * @param property property name
   * @param colorValue color value
   * @param useDark use dark mode statut to determine color
   */
  applyColor(property: string, colorValue: string, useDark = true) {
    const color = generateColor(property, colorValue, useDark && this.isDarkModeState);
    document.body.style.setProperty(`--ion-color-${ property }`, color.value);
    document.body.style.setProperty(`--ion-color-${ property }-rgb`, color.valueRgb);
    document.body.style.setProperty(`--ion-color-${ property }-shade`, color.shade);
    document.body.style.setProperty(`--ion-color-${ property }-tint`, color.tint);
    document.body.style.setProperty(`--ion-color-${ property }-contrast`, color.contrast);
    document.body.style.setProperty(`--ion-color-${ property }-contrast-rgb`, color.contrastRgb);
  }

  /**
   * Remove ion-color from current app
   * @param property property name
   */
  removeColor(property: string) {
    document.body.style.removeProperty(`--ion-color-${ property }`);
    document.body.style.removeProperty(`--ion-color-${ property }-rgb`);
    document.body.style.removeProperty(`--ion-color-${ property }-shade`);
    document.body.style.removeProperty(`--ion-color-${ property }-tint`);
    document.body.style.removeProperty(`--ion-color-${ property }-contrast`);
    document.body.style.removeProperty(`--ion-color-${ property }-contrast-rgb`);
  }

  /**
   * Define app scheme
   * @param appId app Id
   */
  defineScheme(appId: string) {
    this.scheme = schemes.default;
    if (typeof appId === 'string') {
      schemes.customs?.forEach(item => {
        if (item.appIds?.indexOf(appId.toLowerCase()) > -1) {
          this.scheme = item;
        }
      });
    }
    return this.scheme;
  }

  /**
   * Get current scheme defined
   */
  getCurrentScheme() {
    return this.scheme || schemes.default;
  }

  /**
   * Get app links for different theme
   */
  getCurrentAppLinks(): {android: string | boolean, ios: string | boolean} | boolean {
    const themeName = this.currentClientTheme || 'main';
    return this.getTheme(themeName)?.apps || false;
  }

}
