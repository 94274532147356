/**
 * Useful functions
 */

export class Utils {
  static PATTERN_HTTP = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
  static PATTERN_WWW = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  static PATTERN_EMAIL = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

  /**
   * Get current time stamp
   */
  static getCurrentTimeStamp(): number {
    return Math.floor(Date.now() / 1000);
  }



  /**
   * Capitalize first char
   * @param value
   */
  static capitalizeFirst(value: string) {
    if (!value) {
      return value;
    }
    return value[0].toUpperCase() + value.substr(1).toLowerCase();
  }

  /**
   * Get osClient
   * @private
   */
  static getOsClient(): string {
    let osClient;
    switch (navigator.platform) {
      case 'Linux i686':
      case 'Linux armv7l':
      case 'Linux x86_64':
      case 'SunOS':
        osClient = 'linux';
        break;
      case 'Mac68K':
      case 'MacPPC':
      case 'MacIntel':
        osClient = 'mac osx';
        break;
      case 'Win16':
      case 'Win32':
      case 'Win64':
      case 'WinCE':
        osClient = 'windows 10';
        break;
    }
    return osClient;
  }

  /**
   * Return the url from string
   * @param value
   */
  static getUrlsFromString(value: string): string[] {
    let matches1 = value.match(Utils.PATTERN_HTTP);
    const matches2 = value.match(Utils.PATTERN_WWW);

    if (matches1 && matches2) {
      matches1 = matches1.concat(matches2);
    }

    return matches1 || matches2 || [];
  }

  /**
   * Return medias extensions
   * @param type
   */
  static getMediasExtensionsFromType(type: string) {
    switch (type) {
      case 'video':
        return ' .avi, .avs, .mp4, .mov, .3gp, .flv, .mkv,';
      case 'images':
      case 'image':
      case 'photo':
        return ' .jpg, .jpeg, .png, .JPG, .JPEG, .PNG, .gif, .GIF,';
      case 'pdf':
        return ' .pdf, .PDF,';
      case 'ppt':
        return ' .pptx, .ppt,';
      case 'doc':
      case 'word':
        return ' .doc, .docx, .odt , .docm,';
      case 'excel':
        return ' .xls, .xlt, .xlm, .xlsx, .csv,';
      case 'document':
        let extensions = Utils.getMediasExtensionsFromType('pdf');
        extensions += Utils.getMediasExtensionsFromType('ppt');
        extensions += Utils.getMediasExtensionsFromType('word');
        extensions += Utils.getMediasExtensionsFromType('excel');
        return extensions;
    }
    return '';
  }

  static getMimeTypeFromExtension(extension: string) {
    switch (extension.toLowerCase()) {
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      case '3gp':
        return 'video/3gpp';
      case 'jpeg':
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
    }
    return '';
  }

  /**
   * Extract the name from url
   * @param url
   */
  static extractFilename(url: string) {
    const urlVal = url?.replace('\\', '/') || '';
    return urlVal.split('/').pop();
  }

  /**
   * Extract the extension from url
   * @param url
   */
  static extractExtension(url: string) {
    return url
      .split('?')[0]
      .split('.').pop();
  }

  /**
   * Convert a file to URL resource
   * @param file
   */
  static convertBlobToUrl(file: File) {
    return URL.createObjectURL(file);
  }

  /**
   * Clean the url to get only useful path
   * - Remove http(s) and last slash
   * @param url
   */
  static cleanUrl(url: string): string {
    let urlVal = url.replace(/(https?:\/\/)/gi, '');
    urlVal = urlVal.replace(/\/$/, '');
    return urlVal;
  }

  /**
   * Insert value at input cursor
   * @param el input
   * @param value val
   */
  static inputInsertAtCursor(el, value) {
    // IE support
    if ((document as any).selection) {
      el.focus();
      const sel = (document as any).selection.createRange();
      sel.text = value;
    }
    // MOZILLA and others
    else if (el.selectionStart || parseInt(el.selectionStart, 10) === 0) {
      const startPos = el.selectionStart;
      const endPos = el.selectionEnd;
      el.value = el.value.substring(0, startPos)
        + value
        + el.value.substring(endPos, el.value.length);
    } else {
      el.value += value;
    }
  }

  /**
   * Round price
   * @param val
   */
  static roundPrice(val: number) {
    return Math.round(+val * 100) / 100;
  }

  /**
   * Convert string or other to number
   * @param value
   */
  static toNumber(value: any): number {
    if (typeof value === 'number') {
      return value;
    }
    return parseInt(value, 10);
  }

  /**
   * Convert value to string
   * @param value
   */
  static toString(value: any): string {
    if (typeof value === 'number') {
      return value.toString();
    }
    return String(value);
  }

  /**
   * Convert a result to number
   * @param value
   */
  static resultToNumber(value: any): number {
    return Utils.resultToBoolean(value) ? 1 : 0;
  }

  /**
   * Convert a result to boolean
   * @param value
   */
  static resultToBoolean(value: any): boolean {
    return value === 1 || value === true || value === '1';
  }

  /**
   * Convert number in string to float
   * @param value
   */
  static toFloat(value: any): number {
    if (typeof value === 'string') {
      value = value.replace(',', '.');
    }
    return parseFloat(value);
  }

  /**
   * Check if string is not empty
   * @param value
   */
  static isStringEmpty(value: any): boolean {
    if (value === undefined || value === null) {
      return true;
    }

    if (typeof value !== 'string') {
      return true;
    }

    return (value.length < 1);
  }

  /**
   * Convert emoji to HTML Entity Hex unicode (&#x<Hex code>;)
   * @param text
   */
  static emojiToUnicodeEntity(text: string): string {
    const matches = text.match(
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    );
    if (matches?.length > 0) {
      const emojiUnicode = (emoji) => {
        let comp;
        if (emoji.length === 1) {
          comp = emoji.charCodeAt(0);
        } else {
          comp = (
            (emoji.charCodeAt(0) - 0xD800) * 0x400
            + (emoji.charCodeAt(1) - 0xDC00) + 0x10000
          );
        }
        if (comp < 0) {
          comp = emoji.charCodeAt(0);
        }
        // '16' is mandatory to not break emoji conversion
        return comp.toString('16');
      };
      // matches.shift();
      matches.forEach(value => {
        const unicode = `&#x${ emojiUnicode(value) };`;
        text = text.replace(value, unicode);
      });
    }
    return text;
  }

  /**
   * Convert HTML Entity Hex unicode to emoji char (&#x<Hex code>;)
   * @param text
   */
  static unicodeEntityToEmoji(text: string): string {
    const matches = text.match(
      /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/ig
    );
    if (matches?.length > 0) {
      // matches.shift();
      matches.forEach(value => {
        const hex = value.substring(3, value.lastIndexOf(';'));
        const emo = String.fromCodePoint(parseInt('0x' + hex, null));
        text = text.replace(value, emo);
      });
    }
    return text;
  }


  /**
   * Return random integer between min (included) and max (excluded)
   * https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Math/random
   * @param min
   * @param max
   */
  static randomInt(min: number = 0, max: number = 101): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
   * Random string with length size
   * @param length
   */
  static randomString(length: number = 5) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  /**
   * Strip HTML tags in text
   * @param text
   * @param replace text to replace
   */
  static stripTags(text: string, replace = '') {
    return text.replace(/(<([^>]+)>)/gi, replace);
  }

  /**
   * Strip specific HTML tags
   * @param text text with HTML tags
   * @param tags tags to strip
   */
  static stripSpecificTags(text: string, tags: Array<string>) {
    if (text) {
      tags?.forEach(tag => {
        const regex = new RegExp('(<' + tag + '([^>]+)>)|(</' + tag + '([^>]*)>)', 'gi');
        text = text.replace(regex, '');
      });
    }
    return text;
  }

  /**
   * Download url
   * @param url url of file to download
   * @param target target of download
   */
  static download(url: string, target = '_blank') {
    const a = document.createElement('a');
    a.download = 'true';
    if (target) {
      a.target = target;
    }
    a.href = url;
    a.click();
  }

  /**
   * Prompt user with file selector
   * @param accept
   * @param multiple
   */
  static promptFile(accept?: string, multiple?: boolean): Promise<FileList> {
    return new Promise(resolve => {
      const input = document.createElement('input');
      input.type = 'file';
      if (multiple) {
        input.multiple = true;
      }
      if (accept) {
        input.accept = accept;
      }
      input.addEventListener('change', (event) => {
        resolve((event?.target as any)?.files);
      });
      input.click();
    });
  }

  /**
   * Indicate if is video from url extension
   * @param url path of video
   */
  static isVideo(url: string) {
    let result = false;
    switch (Utils.extractExtension(url).toLowerCase()) {
      case 'mp4':
      case 'avi':
      case 'wmv':
      case 'flv':
      case 'mov':
        result = true;
    }
    return result;
  }

  /**
   * Indicate if is document from url extension
   * @param url path of document
   */
  static isDocument(url: string) {
    let result = false;
    switch (Utils.extractExtension(url).toLowerCase()) {
      case 'pdf':
      case 'csv':
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
        result = true;
    }
    return result;
  }

  /**
   * Sanitize string/filename for path
   * @param input
   * @param replacement
   */
  static sanitizeForPath(input: string, replacement = '_') {
    const sanitized = input
      // Remove accent
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      // Replace non char by replacement
      .replace(/[^a-zA-Z0-9\- ]/g, replacement);
    return sanitized.slice(0, 255);
  }


  /**
   * Secure Hash Algorithm (SHA1)
   * http://www.webtoolkit.info/
   */
  static SHA1(msg: string): string {
    // tslint:disable:no-bitwise
    const rotateLeft = (n, s) => {
      // tslint:disable-next-line:no-bitwise
      return (n << s) | (n >>> (32 - s));
    };
    const cvtHex = (val) => {
      let str = '';
      let v;
      for (let idx = 7; idx >= 0; idx--) {
        v = (val >>> (idx * 4)) & 0x0f;
        str += v.toString(16);
      }
      return str;
    };
    const Utf8Encode = (str) => {
      str = str.replace(/\r\n/g, '\n');
      let utftext = '';
      for (let n = 0; n < str.length; n++) {
        const c = str.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }
      return utftext;
    };
    let blockstart;
    let i;
    let j;
    const W = new Array(80);
    let H0 = 0x67452301;
    let H1 = 0xEFCDAB89;
    let H2 = 0x98BADCFE;
    let H3 = 0x10325476;
    let H4 = 0xC3D2E1F0;
    // tslint:disable-next-line:one-variable-per-declaration
    let A, B, C, D, E;
    let tmp;
    msg = Utf8Encode(msg);
    const msgLen = msg.length;
    const wordArray = [];
    for (i = 0; i < msgLen - 3; i += 4) {
      j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
        msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
      wordArray.push(j);
    }
    switch (msgLen % 4) {
      case 0:
        i = 0x080000000;
        break;
      case 1:
        i = msg.charCodeAt(msgLen - 1) << 24 | 0x0800000;
        break;
      case 2:
        i = msg.charCodeAt(msgLen - 2) << 24 | msg.charCodeAt(msgLen - 1) << 16 | 0x08000;
        break;
      case 3:
        i = msg.charCodeAt(msgLen - 3) << 24 | msg.charCodeAt(msgLen - 2) << 16 | msg.charCodeAt(msgLen - 1) << 8 | 0x80;
        break;
    }
    wordArray.push(i);
    while ((wordArray.length % 16) !== 14) {
      wordArray.push(0);
    }
    wordArray.push(msgLen >>> 29);
    wordArray.push((msgLen << 3) & 0x0ffffffff);
    for (blockstart = 0; blockstart < wordArray.length; blockstart += 16) {
      for (i = 0; i < 16; i++) {
        W[i] = wordArray[blockstart + i];
      }
      for (i = 16; i <= 79; i++) {
        W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
      }
      A = H0;
      B = H1;
      C = H2;
      D = H3;
      E = H4;
      for (i = 0; i <= 19; i++) {
        tmp = (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotateLeft(B, 30);
        B = A;
        A = tmp;
      }
      for (i = 20; i <= 39; i++) {
        tmp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotateLeft(B, 30);
        B = A;
        A = tmp;
      }
      for (i = 40; i <= 59; i++) {
        tmp = (rotateLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotateLeft(B, 30);
        B = A;
        A = tmp;
      }
      for (i = 60; i <= 79; i++) {
        tmp = (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotateLeft(B, 30);
        B = A;
        A = tmp;
      }
      H0 = (H0 + A) & 0x0ffffffff;
      H1 = (H1 + B) & 0x0ffffffff;
      H2 = (H2 + C) & 0x0ffffffff;
      H3 = (H3 + D) & 0x0ffffffff;
      H4 = (H4 + E) & 0x0ffffffff;
    }
    tmp = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);

    return tmp.toLowerCase();
  }

  /**
   * Get a random color
   */
  static randomColor() {
    const colors = [
      '#3AB9DC', '#ff6508', '#ef4751', '#00c401',
      '#8307E6', '#e0c300', '#E60240', '#86a924',
      '#DA01E6', '#13984b', '#A1563F', '#02857d',
    ];
    return colors[Utils.randomInt(0, colors.length)];
  }
}
