import {
  BadgeNotificationsEventsIdsInterface,
  BadgeNotificationsEventsInterface
} from '@services/notifications/notifications.interface';
import { NotificationsUtils } from '@services/notifications/notifications.utils';


/**
 * Model to manage badge notifications
 */
export class BadgeNotificationsEvents implements BadgeNotificationsEventsInterface {
  accessFlag = false;
  challengesFlag = false;
  coinsFlag = false;
  feedFlag = false;
  formsFlag = false;
  quizzesFlag = false;
  roadboxFlag = false;
  surveysFlag = false;

  challengesCategoriesIds = [];
  roadboxCategoriesIds = [];

  challengesIds = [];
  coinsIds = [];
  feedIds = [];
  formsIds = [];
  quizzesIds = [];
  roadboxIds = [];
  surveysIds = [];

  /**
   * Add id to events
   * @param type type of notification
   * @param relationId relation id
   * @param notificationId notification id
   */
  addId(type: string, relationId: number, notificationId: number) {
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        this.addRelationId(this.challengesIds, relationId, notificationId);
        break;
      case NotificationsUtils.COINS:
        this.addRelationId(this.coinsIds, relationId, notificationId);
        break;
      case NotificationsUtils.QUIZ:
        this.addRelationId(this.quizzesIds, relationId, notificationId);
        break;
      case NotificationsUtils.FORM:
        this.addRelationId(this.formsIds, relationId, notificationId);
        break;
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        this.addRelationId(this.feedIds, relationId, notificationId);
        break;
      case NotificationsUtils.SURVEY:
        this.addRelationId(this.surveysIds, relationId, notificationId);
        break;
      case NotificationsUtils.ROADBOX:
        this.addRelationId(this.roadboxIds, relationId, notificationId);
        break;
    }
    this.buildFlags();
  }

  /**
   * Remove id to events
   * @param type type of notification
   * @param relationId relation id
   */
  removeId(type: string, relationId: number) {
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        this.removeRelationId(this.challengesIds, relationId);
        break;
      case NotificationsUtils.COINS:
        this.removeRelationId(this.coinsIds, relationId);
        break;
      case NotificationsUtils.QUIZ:
        this.removeRelationId(this.quizzesIds, relationId);
        break;
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        this.removeRelationId(this.feedIds, relationId);
        break;
      case NotificationsUtils.FORM:
        this.removeRelationId(this.formsIds, relationId);
        break;
      case NotificationsUtils.SURVEY:
        this.removeRelationId(this.surveysIds, relationId);
        break;
      case NotificationsUtils.ROADBOX:
        this.removeRelationId(this.roadboxIds, relationId);
        break;
    }
  }

  /**
   * Build Flags
   */
  buildFlags() {
    this.challengesFlag = this.challengesIds?.length > 0;
    this.coinsFlag = this.coinsIds?.length > 0;
    this.quizzesFlag = this.quizzesIds?.length > 0;
    this.feedFlag = this.feedIds?.length > 0;
    this.formsFlag = this.formsIds?.length > 0;
    this.surveysFlag = this.surveysIds?.length > 0;
    this.roadboxFlag = this.roadboxIds?.length > 0;
    this.accessFlag = this.challengesFlag || this.coinsFlag || this.quizzesFlag ||
      this.feedFlag || this.formsFlag || this.surveysFlag || this.roadboxFlag;
  }

  /**
   * Has relation event
   * @param type type of notification
   * @param relationId relation id
   */
  hasRelationEvent(type: string, relationId: number) {
    const ids: BadgeNotificationsEventsIdsInterface[] = NotificationsUtils.badgeListByType(this, type);
    return ids.map(val => val.relationId).indexOf(relationId) > -1;
  }

  /**
   * Has relation category event
   * @param type type of notification
   * @param relationId relation id
   */
  hasRelationCategoryEvent(type: string, relationId: number) {
    let ids: Array<number>;
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        ids = this.challengesCategoriesIds;
        break;
      case NotificationsUtils.ROADBOX:
        ids = this.roadboxCategoriesIds;
        break;
    }
    return ids.indexOf(relationId) > -1;
  }

  private removeRelationId(list: BadgeNotificationsEventsIdsInterface[], relationId: number) {
    const index = list.findIndex(item => item.relationId === relationId);
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  private addRelationId(list: BadgeNotificationsEventsIdsInterface[], relationId: number, notificationId: number) {
    const event = list.find(item => item.relationId === relationId) || null;
    if (event) {
      if (event.notificationIds?.indexOf(notificationId) === -1) {
        event.notificationIds.push(notificationId);
      }
    } else {
      list.push({
        relationId,
        notificationIds: [notificationId]
      });
    }
  }
}
