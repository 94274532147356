import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TalkConversation } from '@components/app/talk';
import { UserProfileBase } from '@interfaces/connection.interface';
import { ModalController } from '@ionic/angular';
import { TalkConversationsParticipantsModal } from '@components/app/talk/conversations/participants/participants.modal';

@Component({
  selector: 'app-talk-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
})
export class TalkConversationsComponent {

  @Input() conversations: TalkConversation[];

  @Input() noSelection = false;

  @Output() selectionChange: EventEmitter<TalkConversation> = new EventEmitter<TalkConversation>();

  toStrParticipants = (users: UserProfileBase[]) => {
    return users?.map(item => item.firstname)?.join(', ') || '';
  }

  constructor(private modalCtrl: ModalController) {
  }

  removeConversation(index: number, event: Event) {
    event.stopPropagation();
    this.conversations.splice(index, 1);
    if (this.conversations?.length > 0) {
      this.selectionChange.emit(this.conversations[0]);
    }
  }

  /**
   * Show participants
   */
  async showParticipants(participants: UserProfileBase[], event: Event) {
    event.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: TalkConversationsParticipantsModal,
      componentProps: {participants},
      cssClass: 'standard-modal-popup messages-group-start-modal',
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }

}
