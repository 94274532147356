import {Pipe, PipeTransform} from '@angular/core';

/**
 * Wrap text without cut word if higher than {length} and add ...
 */

@Pipe({
    name: 'textWrap'
})
export class TextWrapPipe implements PipeTransform {

    constructor() { }

    public transform(value: string, length = 100): string {
        if (value?.length <= length) {
            return value;
        }

        const indexToRemove = value
          .slice(0, length - 3)
          .split('').reverse().join('')
          .indexOf(' ');

        if (indexToRemove > -1) {
            return value.slice(0, length - 3 - indexToRemove) + '...';
        }

        return value.slice(0, length - 3) + '...';
    }
}
