/**
 * Schemes definition for custom apps
 */

export interface SchemeInterface {
  appIds: Array<string>;
  iosBatchId: string;
  androidBatchId: string;
  logoPath?: string;  // Path to logo of scheme
  default?: boolean;  // Is default scheme ?
  landing?: string;  // If shall load configuration of landing page (fr SSO)
  oAuthRedirectBase?: string;          // Oauth config used for redirection
  colors?: {
    primary: string;
    secondary: string;
    primaryDark?: string;
    secondaryDark?: string;
  };
}

interface Schemes {
  default: SchemeInterface;
  customs: SchemeInterface[];
}

export const schemes: Schemes = {
  default: {
    // Default scheme
    appIds: ['roadoo.roadoo', 'com.roadoo.roadoonetwork'],
    iosBatchId: '58F885BF6AE36B995F1D7898E2488D',
    androidBatchId: '595E47297BD54987398D0F3B4022DA',
    default: true
  },
  customs: [
    {
      // Nexity Scheme
      appIds: ['roadoo.nexity', 'com.roadoo.nexity'],
      iosBatchId: '59F1EE402FFBE560A4A9A5AEE52C8F',
      androidBatchId: '59F1B580A7CA9C2DAA82216E0AD62A',
      logoPath: '/assets/images/external/nexity.png',
      landing: 'nexity',
      colors: {
        primary: '#d22238',
        secondary: '#d22238',
        primaryDark: '#9B192A'
      }
    },
    {
      // Flex Scheme
      appIds: ['roadoo.flex', 'com.roadoo.flex'],
      iosBatchId: '603CC007D74D75835276C5209DC5A7',
      androidBatchId: '603CBFA89D4DC49A8B2084D2CE86F7',
      logoPath: '/assets/images/external/flex.png',
      landing: 'flex',
      colors: {
        primary: '#d5420c',
        secondary: '#d5420c',
        primaryDark: '#782608'
      }
    },
    {
      // Mylink Scheme
      appIds: ['roadoo.mylink', 'com.roadoo.mylinknewrest'],
      iosBatchId: '5FF44AE93A668C8F03FCC33786E0F3',
      androidBatchId: '5FD201CA4844F8A19932ECC86CD7D9',
      logoPath: '/assets/images/external/mylink.png',
      landing: 'mylink',
      oAuthRedirectBase: 'https://mylink.roadoo.com',
      colors: {
        primary: '#D8750F',
        secondary: '#B6630D',
        primaryDark: '#8F4E0B'
      }
    },
    {
      // Maslo Scheme
      appIds: ['roadoo.maslo', 'com.roadoo.maslo'],
      iosBatchId: '624D6BE6A04936FD813522C1908BFB',
      androidBatchId: '624C63E42C4CD9C3CC13FB1EC04A1C',
      logoPath: '/assets/images/external/maslo.png',
      landing: 'maslo',
      oAuthRedirectBase: 'https://maslo.roadoo.com',
      colors: {
        primary: '#182242',
        secondary: '#ff5757',
        primaryDark: '#182242'
      }
    },
    {
      // PistonR Scheme
      appIds: ['roadoo.pistonr', 'com.roadoo.pistonr'],
      iosBatchId: '625EB0B48EAA115B9572E47495B7EC',
      androidBatchId: '625EAEBD112C5144E2DD3F18499BA1',
      logoPath: '/assets/images/external/pistonr.png',
      landing: 'pistonr',
      oAuthRedirectBase: 'https://pistonr.roadoo.com',
      colors: {
        primary: '#ff914d',
        secondary: '#664437',
        primaryDark: '#cc743d'
      }
    },
    {
      // Frendli Scheme
      appIds: ['fr.frendli', 'fr.frendli'],
      iosBatchId: '62DFF74EEF6BE3500906AB6993E32F',
      androidBatchId: '62DFCE9086B32E0A86D9B7C170D352',
      logoPath: '/assets/images/external/frendli.png',
      landing: 'frendli',
      oAuthRedirectBase: 'https://frendli.roadoo.com',
      colors: {
        primary: '#0a1667',
        secondary: '#E8EFFF',
        primaryDark: '#0a1667'
      }
    }
  ]
};

