import { UrlTree } from '@angular/router';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { NotificationInterface } from '@interfaces/api';
import { BadgeNotificationsEventsInterface } from '@services/notifications/notifications.interface';

export interface NotificationsRouteInterface {
  url: string | UrlTree | any[];
  options?: NavigationOptions;
}

/**
 * Static Notifications Utils
 */
export class NotificationsUtils {
  static COINS = 'CoinsNotification';
  static FEED = 'FeedNotification';
  static LIKE = 'LikeNotification';
  static ROADBOX = 'GalerieNotification';
  static QUIZ = 'QuizzNotification';
  static SURVEY = 'SondageNotification';
  static FORM = 'FormNotification';
  static CHALLENGE = 'ChallengeNotification';
  static CHALLENGE_UPDATE = 'ChallengeUpdateNotification';
  static TALK = 'TalkNotification';

  /**
   * Get route according Notifications params
   * @param type type of notification
   * @param referenceId reference Id
   * @param isAdmin is admin ?
   */
  static getRoute(type: string, referenceId?, isAdmin = false): NotificationsRouteInterface {

    // Build route according admin and reference id
    const buildRoute = (clientRoute,
                        clientWithIdRoute,
                        adminRoute,
                        adminWithIdRoute,
                        options?): NotificationsRouteInterface => {
      if (isAdmin) {
        return referenceId ? {url: adminWithIdRoute, options} : {url: adminRoute, options};
      } else {
        return referenceId ? {url: clientWithIdRoute, options} : {url: clientRoute, options};
      }
    };

    switch (type) {
      case NotificationsUtils.COINS:
        return {
          url: [`/my-earnings`]
        };
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        return referenceId ? {
          url: [`/feed`, referenceId],
        } : {
          url: [`/`]
        };
      case NotificationsUtils.ROADBOX:
        return buildRoute(
          [`/roadbox`],
          [`/roadbox`, referenceId],
          [`/dashboard/roadbox/open`],
          [`/dashboard/roadbox/open`, referenceId]
        );
      case NotificationsUtils.QUIZ:
        return buildRoute(
          [`/quizzes`],
          [`/quizzes`],
          [`/dashboard/quizzes`],
          [`/dashboard/quizzes`, referenceId]
        );
      case NotificationsUtils.SURVEY:
        return buildRoute(
          [`/`],
          [`/`],
          [`/dashboard/surveys`],
          [`/dashboard/surveys`],
          {fragment: referenceId}
        );
      case NotificationsUtils.FORM:
        return buildRoute(
          [`/forms`],
          [`/forms`],
          [`/dashboard/forms`],
          [`/dashboard/forms`, referenceId]
        );
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        return buildRoute(
          [`/challenges`],
          [`/challenge`, referenceId],
          [`/dashboard/challenges`],
          [`/dashboard/challenges/overview`, referenceId]
        );
      case NotificationsUtils.TALK:
        return buildRoute(
          [`/messages/conversation`],
          [`/messages/conversation`, referenceId],
          [`/messages/conversation`],
          [`/messages/conversation`, referenceId]
        );
    }
  }

  /**
   * Get reference id of notification
   * @param notification object
   */
  static getReferenceId(notification: NotificationInterface) {
    if (notification?.items?.length > 0) {
      return notification?.items.pop().reference_id;
    }
    return null;
  }

  /**
   * List all notification items by event type of badge model
   * @param events list of events
   * @param type type of notification
   */
  static badgeListByType(events: BadgeNotificationsEventsInterface, type: string) {
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        return events.challengesIds;
      case NotificationsUtils.COINS:
        return events.coinsIds;
      case NotificationsUtils.QUIZ:
        return events.quizzesIds;
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        return events.feedIds;
      case NotificationsUtils.FORM:
        return events.formsIds;
      case NotificationsUtils.SURVEY:
        return events.surveysIds;
      case NotificationsUtils.ROADBOX:
        return events.roadboxIds;
    }
  }

  /**
   * Get ids key by type applied to badge model
   * @param type type of notification
   */
  static badgeIdsKeyByType(type: string) {
    switch (type) {
      case NotificationsUtils.CHALLENGE:
      case NotificationsUtils.CHALLENGE_UPDATE:
        return 'challengesIds';
      case NotificationsUtils.COINS:
        return 'coinsIds';
      case NotificationsUtils.QUIZ:
        return 'quizzesIds';
      case NotificationsUtils.FEED:
      case NotificationsUtils.LIKE:
        return 'feedIds';
      case NotificationsUtils.FORM:
        return 'formsIds';
      case NotificationsUtils.SURVEY:
        return 'surveysIds';
      case NotificationsUtils.ROADBOX:
        return 'roadboxIds';
    }
  }
}
