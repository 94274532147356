import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Utils } from '@helpers/utils';

/**
 * Component to display image or icon with name if not displayable
 */
@Component({
  selector: 'app-img-smart',
  templateUrl: './img-smart.component.html',
  styleUrls: ['./img-smart.component.scss'],
})
export class ImgSmartComponent implements OnChanges {

  // File path
  @Input() src: string;
  // Default image preview in case of error
  @Input() default: string;

  extension: string;
  color: string;

  isImage: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.src) {
      this.buildPreview();
    }
  }

  buildPreview(error = false) {
    this.extension = Utils.extractExtension(this.src).toUpperCase();
    this.color = 'rgb(150,150,150)';
    this.isImage = false;

    switch (this.extension.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'webp':
        if (error) {
          if (this.default) {
            this.isImage = true;
            this.src = this.default;
          }
        } else {
          this.isImage = true;
        }
        break;
      case 'xls':
      case 'xlsx':
      case 'csv':
        this.color = 'rgb(46,125,50)';
        break;
      case 'doc':
      case 'docx':
      case 'odt':
        this.color = 'rgb(21,101,192)';
        break;
      case 'pdf':
        this.color = 'rgb(241,86,66)';
        break;
      case 'ppt':
      case 'pptx':
        this.color = 'rgb(255,100,7)';
        break;
    }
  }

}
