import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-autocomplete-input',
  template: `
    <div class="container ion-color-{{color || 'primary'}}" [class.focused]="isFocused">
      <input [(ngModel)]="value" [matAutocomplete]="template"
             [placeholder]="placeholder"
             inputmode="search" title=""
             (focus)="isFocused = true" (blur)="isFocused = false" (keyup)="onChange(value)"/>
      <ion-icon name="rd-glass" [color]="iconColor || 'medium'"></ion-icon>
    </div>`,
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    }
  ],
})
export class AutocompleteInputComponent implements OnInit {

  @Input() value: string;

  @Input() color: string;
  @Input() iconColor: string;
  @Input() placeholder = '';

  @Input() template: any;

  isFocused = false;

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * ControlValueAccesor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {
    this.value = inputValue;
    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
