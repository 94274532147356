import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileViewComponent } from './profile-view.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { UsersService } from '@services/api/client';
import { FormsModule } from '@angular/forms';
import { PlatformSelectModule } from '@components/app/platform-select';
import { ImgAvatarModule } from '@components/images';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@directives/directives.module';



@NgModule({
  declarations: [
    ProfileViewComponent
  ],
  exports: [
    ProfileViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    IonicModule,
    TranslocoModule,
    PlatformSelectModule,
    ImgAvatarModule,
    TranslocoLocaleModule,
    MatTooltipModule,
    DirectivesModule
  ],
  providers: [
    UsersService
  ]
})
export class ProfileViewModule { }
