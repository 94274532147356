import { Component, Input } from '@angular/core';
import { TalkNotificationToastOptions } from './notification-toast.controller';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-talk-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
})
export class TalkNotificationToastComponent {

  @Input() data: TalkNotificationToastOptions;

  constructor(private modalCtrl: ModalController) {
  }

  close() {
    this.modalCtrl.dismiss()?.then(/* Nothing to do */);
  }

  onClick() {
    if (this.data?.onClick) {
      this.close();
      this.data.onClick();
    }
  }

}
