import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DashboardChallengeAddEditTypeModal } from './type.modal';

/**
 * Component that manage challenge type modal
 */

@Injectable()
export class DashboardChallengeAddEditTypeController {

  constructor(protected modalCtrl: ModalController) {
  }

  /**
   * Open modal
   */
  async open(idCat?: string) {
    const modal = await this.modalCtrl.create({
      component: DashboardChallengeAddEditTypeModal,
      componentProps: {idCat},
      cssClass: 'standard-modal-popup dashboard-challenge-add-edit-type-modal',
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }


}
