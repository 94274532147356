import { NgModule } from '@angular/core';
import { ImagePreloadDirective } from './images/image-preload.directive';
import { LoadFileDirective } from './load-file/load-file.directive';
import { HideOnErrorDirective } from '@directives/images/hide-on-error.directive';

@NgModule({
  declarations: [
    ImagePreloadDirective,
    LoadFileDirective,
    HideOnErrorDirective
  ],
  exports: [
    ImagePreloadDirective,
    LoadFileDirective,
    HideOnErrorDirective
  ]
})
export class DirectivesModule {}
