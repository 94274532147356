import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkStatusToast } from '@components/utils/network-status/network-status.toast';
import { NetworkStatusService } from '@components/utils/network-status/network-status.service';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [
    NetworkStatusToast
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule
  ],
  providers: [
    NetworkStatusService
  ]
})
export class NetworkStatusModule { }
