import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from '@services/connection.service';
import { Subscription } from 'rxjs';
import { UserProfileInterface } from '@interfaces/connection.interface';
import { PlatformInterface } from '@interfaces/api';
import { CommonService } from '@services/common.service';
import { ResponsiveService } from '@services/responsive.service';
import { AccessControlService, PermissionsForClientInterface } from '@services/access-control';
import { Router } from '@angular/router';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { StateService } from '@services/state.service';
import { ChallengesService } from '@services/api/client';
import { Utils } from '@helpers/utils';
import { BadgeNotificationsEventsInterface, NotificationsService } from '@services/notifications';
import { params } from '@environments/params';

/**
 * Component to manage left side profile view
 */

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
})
export class ProfileViewComponent implements OnInit, OnDestroy {

  profile: UserProfileInterface;
  platform: PlatformInterface;

  availablePlatforms: PlatformInterface[];
  selectedPlatformId: number;

  subscriptions: Subscription[];

  isCoinsDisplay: boolean;
  isStoreDisplayed: boolean;
  permissions: PermissionsForClientInterface;

  nbPlatforms = 10;
  pointsChallenges = 0;
  pointsChallengeId = 0;

  coins = 0;
  badgeEvents: BadgeNotificationsEventsInterface;
  hasDashboardEvents = false;

  mobileVersion = null;
  dashboardMainRoute = '/';

  platformColor = () => {
    return this.ionicPlatform.is('ios') ? 'medium' : 'green';
  }

  constructor(protected connectionServ: ConnectionService,
              protected accessServ: AccessControlService,
              protected router: Router,
              protected ionicPlatform: Platform,
              protected navCtrl: NavController,
              protected menuCtrl: MenuController,
              protected challengesServ: ChallengesService,
              protected stateServ: StateService,
              protected notificationsServ: NotificationsService,
              public responsiveServ: ResponsiveService,
              public commonServ: CommonService) {
    this.subscriptions = [];
  }

  ngOnInit() {

    this.subscriptions.push(this.connectionServ.getAuthState().subscribe(token => {
      if (token) {
        this.initData(token.user, this.connectionServ.getSelectedPlatformValue());
      }
    }));

    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      if (platform) {
        this.initData(this.connectionServ.getTokenValue().user, platform);
      }
    }));

    this.subscriptions.push(this.stateServ.getUserCoinsState().subscribe(value => this.coins = value));

    if (!this.profile || !this.platform) {
      this.initData(this.connectionServ.getTokenValue()?.user, this.connectionServ.getSelectedPlatformValue());
    }

    this.connectionServ.getPlatforms().subscribe(platforms => {
      this.availablePlatforms = platforms;
    });

    if (this.ionicPlatform.is('capacitor')) {
      this.mobileVersion = this.connectionServ.getCurrentVersion();
    }

    // Badge
    this.subscriptions.push(this.notificationsServ.eventsState().subscribe(events => this.badgeEvents = events));
    this.subscriptions.push(this.notificationsServ.eventsState(true).subscribe(events => this.hasDashboardEvents = events.accessFlag));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  initData(user: UserProfileInterface, platform: PlatformInterface) {
    this.profile = user;
    this.platform = platform;
    this.selectedPlatformId = platform?.id_action;

    this.isCoinsDisplay = Utils.resultToBoolean(platform?.allow_display_coins);
    this.isStoreDisplayed = Utils.resultToBoolean(platform?.allow_display_coins);

    this.permissions = this.accessServ.permissionsForClient();
    this.dashboardMainRoute = this.accessServ.getDashboardMainRoute();

    // Snippet for Laforet
    if (Utils.toNumber(this.selectedPlatformId) === 477) {
      this.challengesServ.pointsChallenge().subscribe(value => {
        const challenges = value.challenges;
        if (challenges && challenges.length > 0) {
          this.pointsChallenges = Utils.toNumber(challenges[0].points_wins);
          this.pointsChallengeId = challenges[0].id;
        }
      });
    }
  }

  /**
   * Change platform action
   * @param idAction id action
   */
  changePlatform(idAction: number) {
    this.menuCtrl.close()?.then(/* Nothing to do */);
    this.connectionServ.setSelectedPlatformId(idAction).subscribe();
  }

  /**
   * callback when platform change
   */
  onPlatformChange() {
    this.menuCtrl.close()?.then(/* Nothing to do */);
  }

  /**
   * Indicate if route is enabled
   * @param path path to test
   */
  isRouteEnabled(path: string): boolean {
    return this.router.url.startsWith(path);
  }

  /**
   * Navigate to route
   * @param route routes string
   * @param direction direction string
   */
  navigateTo(route: string, direction = 'root') {
    if (direction === 'root') {
      this.navCtrl.navigateRoot(route)?.then(/* Nothing to do */);
    } else {
      this.navCtrl.navigateForward(route)?.then(/* Nothing to do */);
    }
    this.menuCtrl.close()?.then(/* Nothing to do */);
  }

  helpAction() {
    this.commonServ.openUrl(params.links.user_help_link);
  }

}
