import { Component, HostListener, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserProfileBase } from '@interfaces/connection.interface';
import { TalkService } from '@components/app/talk/talk.service';
import { ConnectionService } from '@services/connection.service';

@Component({
  selector: 'app-talk-conversation-start-modal',
  templateUrl: './conversation-start.modal.html',
  styleUrls: ['./conversation-start.modal.scss'],
})
export class TalkConversationStartModal implements OnInit {

  title = '';

  query = '';
  users: UserProfileBase[];

  participants: UserProfileBase[] = [];

  constructor(private modalCtrl: ModalController,
              private talkServ: TalkService,
              private connectionServ: ConnectionService) { }

  ngOnInit() {
    const profile = this.connectionServ.getTokenValue()?.user;

    if (profile) {
      this.participants.push(profile);
    }
  }


  onSearch() {
    if (!(this.query?.length > 2)) {
      return;
    }
    this.talkServ.getUsers({query: this.query}).subscribe(response => {
      this.users = response.users;
    });
  }

  addUser(user: UserProfileBase) {
    const index = this.participants.findIndex(item => item.id_customer === user.id_customer);
    if (index === -1 && this.participants?.length < 20) {
      this.participants.push(user);
    }
  }

  removeUser(index: number) {
    this.participants.splice(index, 1);
  }

  validate() {
    this.modalCtrl.dismiss({
      participants: this.participants,
      title: this.title
    }, 'apply').then(/* Nothing to do */);
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel').then(/* Nothing to do */);
  }
}
