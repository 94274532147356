import { Injectable } from '@angular/core';
import { AnimationController, ModalController } from '@ionic/angular';
import { NetworkStatusToast } from '@components/utils/network-status/network-status.toast';
import { fromEvent, Subscription } from 'rxjs';

/**
 * Network Status service
 * This component manage the network status events
 */

@Injectable({providedIn: 'root'})
export class NetworkStatusService {

  private subscriptions: Subscription[] = [];

  constructor(private modalCtrl: ModalController,
              private animationCtrl: AnimationController) {
  }

  async init() {
    const onlineEvent = fromEvent(window, 'online');
    const offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(onlineEvent.subscribe(() => this.displayToast('online')));
    this.subscriptions.push(offlineEvent.subscribe(() => this.displayToast('offline')));

    // Preload modal to avoid error on connection lost if module not loaded
    const modal = await this.modalCtrl.create({
      component: NetworkStatusToast
    });
    await modal.dismiss();

    return false;
  }

  destroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async displayToast(status: string) {
    const modal = await this.modalCtrl.create({
      component: NetworkStatusToast,
      componentProps: {status},
      cssClass: 'network-status-toast',
      showBackdrop: false,
      enterAnimation: (baseEl) => {
        return this.animationCtrl.create()
          .addElement(baseEl.querySelector('.modal-wrapper'))
          .duration(400)
          .beforeStyles({
            opacity: 1
          })
          .fromTo('transform', 'translateY(-100%)', 'translateY(0px)');
      },
      leaveAnimation: (baseEl) => {
        return this.animationCtrl.create()
          .addElement(baseEl.querySelector('.modal-wrapper'))
          .duration(400)
          .beforeStyles({
            opacity: 1
          })
          .fromTo('transform', 'translateY(0px)', 'translateY(-100%)');
      }
    });

    // Close modal after 2.5s
    setTimeout(() => modal.dismiss(), 2500);

    return await modal.present();
  }

}
