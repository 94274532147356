import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  AdminRoadboxData,
  ReqAdminRoadboxInterface, ReqAdminRoadboxListInterface,
} from '@interfaces/api/admin';
import { StandardExportInterface, StandardResponseInterface } from '@interfaces/api';
import { map, switchMap, tap } from 'rxjs/operators';
import { GalleryCategoryInterface } from '@interfaces/api/client';
import { Utils } from '@helpers/utils';
import { RoadBoxService } from '@services/api/client';

@Injectable()
export class AdminRoadboxService extends HttpBaseService {

  // Indicate if card is under drag
  public isRoadboxDrag: boolean;

  private lastRequest: { idCategory: string, list: ReqAdminRoadboxListInterface };

  private nbRootRoadbox = 0;
  private categoriesData: GalleryCategoryInterface[];

  /**
   * Used to get the list of all platform gallery.
   * @param idCategory id of category
   * @param reload reload list
   */
  roadboxList(idCategory?: string, reload = false): Observable<ReqAdminRoadboxListInterface> {
    const body = new FormData();
    body.append('id_category', idCategory);
    if (this.lastRequest && this.lastRequest?.idCategory === idCategory && !reload) {
      return of(this.lastRequest.list);
    } else {
      return this.stdRequest<ReqAdminRoadboxListInterface>(
        this.http.post<ReqAdminRoadboxListInterface>(`${ this.rootApi }/administration/Gallery`, body)
      ).pipe(tap(response => {
        this.lastRequest = {
          idCategory,
          list: response
        };
        if (!idCategory) {
          this.categoriesData = response.categories;
          this.nbRootRoadbox = response?.galleries.filter(item => item.categories?.length < 1)?.length || 0;
        }
      }));
    }
  }

  /**
   * Get catagories data
   */
  categories(): Observable<GalleryCategoryInterface[]> {
    if (this.categoriesData) {
      return of(this.categoriesData);
    } else {
      return this.roadboxList().pipe(map(response => response.categories));
    }
  }

  /**
   * Get nb root roadbox
   */
  getNbRootRoadbox(): number {
    return this.nbRootRoadbox;
  }

  /**
   * Get category by id
   * @param idCategory id of category
   */
  getCategory(idCategory: string): Observable<GalleryCategoryInterface> {
    return this.categories().pipe(switchMap(response => {
      return of(response?.find(item => Utils.toNumber(item.id_gallery_category) === Utils.toNumber(idCategory)));
    }));
  }

  /**
   * Used to get a gallery.
   * @param idGallery id of gallery
   */
  roadbox(idGallery: string): Observable<ReqAdminRoadboxInterface> {
    const body = new FormData();
    body.append('id_gallery', idGallery);
    return this.stdRequest<ReqAdminRoadboxInterface>(this.http.post<ReqAdminRoadboxInterface>(`${ this.rootApi }/administration/Gallery`, body)).pipe(tap(response => {
      RoadBoxService.buildUnsafeMedias(response?.gallery);
    }));
  }

  /**
   * Used to export a gallery.
   * @param idGallery id of gallery
   */
  export(idGallery: string): Observable<StandardExportInterface> {
    return this.stdRequest(this.http.post<StandardExportInterface>(`${ this.rootApi }/administration/Gallery/${idGallery}/export`, null));
  }

  /**
   * Used to add a publication into newsfeed about this gallery.
   * @param idGallery id of gallery
   */
  publish(idGallery: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_gallery', idGallery);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gallery/publish`, body));
  }

  /**
   * Used to delete a gallery.
   * @param idGallery id of gallery
   */
  delete(idGallery: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_gallery', idGallery);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gallery/delete`, body));
  }

  /**
   * Used to delete a category of gallery.
   * @param idCategory id of category
   */
  deleteCategory(idCategory: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_category', idCategory);
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gallery/deleteCategory`, body));
  }

  /**
   * Used to update name a category of gallery.
   * @param idCategory id of category
   * @param name name of category
   */
  updateCategory(idCategory: string, name: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_category', idCategory);
    body.append('category', JSON.stringify({name}));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gallery/updateCategory`, body));
  }

  /**
   * Used to add new category in gallery.
   * @param name name of category
   */
  addCategory(name: string): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('category', JSON.stringify({name}));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gallery/addCategory`, body));
  }

  /**
   * Used to add a new gallery.
   */
  add(data: AdminRoadboxData): Observable<StandardResponseInterface> {
    return this.addEdit('add', data);
  }

  /**
   * Used to add a new gallery.
   */
  update(idGallery: string, data: AdminRoadboxData): Observable<StandardResponseInterface> {
    return this.addEdit('update', data, idGallery);
  }

  /**
   * Used to add or edit a new gallery.
   */
  private addEdit(type: 'add' | 'update', data: AdminRoadboxData, idGallery?: string): Observable<StandardResponseInterface> {
    const body = new FormData();

    if (idGallery) {
      body.append('id_gallery', idGallery);
    }

    // Build file
    data?.medias?.forEach((media, index) => {
      if (media.file) {
        body.append(`add_media_gallery[${ index.toString() }]`, media.file);
      }
      if (media.embed?.length > 0 && media.id_media === 0) {
        body.append(`add_media_gallery[${ index.toString() }]`, media.embed);
      }
    });

    body.append('gallery', JSON.stringify(data));
    return this.stdRequest<StandardResponseInterface>(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gallery/${ type }`, body)).pipe(tap(() => {
      data?.medias?.forEach((media, index) => delete data.medias[index].file);
    }));
  }
}
