import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleInputComponent } from './rule-input.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    RuleInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TextFieldModule,
    FormsModule,
    MatTooltipModule,
    TranslocoModule,
    MatMenuModule
  ],
  exports: [
    RuleInputComponent
  ]
})
export class RuleInputModule { }
