import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TeamInterface } from '@interfaces/api/client';
import { PaginatorBase } from '@helpers/paginator.base';

@Component({
  selector: 'app-team-feed-select-modal',
  templateUrl: './team-feed-select.modal.html',
  styleUrls: ['./team-feed-select.modal.scss']
})
export class TeamFeedSelectModal extends PaginatorBase implements OnInit {

  @Input() list: TeamInterface[];

  listToDisplay: TeamInterface[];
  filterSearch = '';

  selection: TeamInterface;

  constructor(private modalCtrl: ModalController) {
    super();
  }

  ngOnInit() {
    this.buildListToDisplay();

    this.setPaginatorCallback(() => this.buildListToDisplay());
  }

  /**
   * Build list according filter
   */
  buildListToDisplay() {
    const search = this.filterSearch?.toLowerCase().trim() || '';

    let newList: TeamInterface[];

    if (search.length > 0) {
      newList = this.list?.filter(item => item.nom.toLowerCase().trim().indexOf(search) > -1) || [];
    } else {
      newList = this.list || [];
    }

    // Build paginator list
    this.listToDisplay = this.buildPaginatorList(newList);
  }

  select(team: TeamInterface) {
    this.list?.forEach(item => item.isSelected = false);
    team.isSelected = true;

    this.selection = team;
  }

  apply() {
    this.modalCtrl.dismiss(this.selection, 'apply')?.then(/* Nothing to do */);
  }

  // Auto close modal on back button click
  @HostListener('window:popstate')
  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel')?.then(/* Nothing to do */);
  }
}
