import { Injectable } from '@angular/core';
import { AnimationController, ModalController } from '@ionic/angular';
import { TalkNotificationToastComponent } from './notification-toast.component';
import { params } from '@environments/params';

export interface TalkNotificationToastOptions {
  color?: string;
  message: string;
  title?: string;
  delay?: number;
  onDismiss?: () => void;
  onClick?: () => void;
}

@Injectable({providedIn: 'root'})
export class TalkNotificationToastController {

  modals: Array<{
    el: HTMLIonModalElement,
    timer?: any,
    syncTimestamp: number,
    syncDuration: number
  }>;

  constructor(private animationCtrl: AnimationController,
              private modalController: ModalController) {
    this.modals = [];
  }

  /**
   * Show Notification toast
   * @param options parameters
   */
  async show(options: TalkNotificationToastOptions) {
    const modal = await this.modalController.create({
      component: TalkNotificationToastComponent,
      componentProps: {data: options},
      showBackdrop: false,
      cssClass: 'talk-notification-toast',
      enterAnimation: (baseEl) => {
        const animation = this.animationCtrl.create()
          .addElement(baseEl.querySelector('.modal-wrapper'))
          .duration(400)
          .beforeStyles({
            opacity: 1
          });
        if (window.innerWidth < params.breakpoints.lg) {
          animation.fromTo('transform', 'translateY(100%)', 'translateY(0px)');
        } else {
          animation.fromTo('transform', 'translateX(500px)', 'translateX(0px)');
        }
        return animation;
      },
      leaveAnimation: (baseEl) => {
        const animation = this.animationCtrl.create()
          .addElement(baseEl.querySelector('.modal-wrapper'))
          .duration(400)
          .beforeStyles({
            opacity: 1
          });
        if (window.innerWidth < params.breakpoints.lg) {
          animation.fromTo('transform', 'translateY(0px)', 'translateY(100%)');
        } else {
          animation.fromTo('transform', 'translateX(0px)', 'translateX(500px)');
        }
        return animation;
      }
    });

    let modalTimer = null;

    // When modal is closed
    modal.onDidDismiss().then(() => {
      if (modalTimer) {
        clearTimeout(modalTimer);
        modalTimer = null;
      }
      if (options.onDismiss) {
        options.onDismiss();
      }
    });

    // Close all existing modal
    this.closeAllLoading();
    const timestamp = (new Date()).getTime();

    // Manage auto close
    if (+options.delay > 0) {
      modalTimer = setTimeout(() => {
        if (modal) {
          modal.dismiss();
        }
      }, +options.delay);
    }

    this.modals.push({el: modal, timer: modalTimer, syncDuration: +options.delay, syncTimestamp: timestamp});

    // Display modal
    await modal.present().then();
  }

  private closeAllLoading() {
    for (const m of this.modals) {
      const modal = this.modals.pop();
      if (modal.timer) {
        clearTimeout(modal.timer);
        modal.timer = null;
      }
      modal.el.dismiss()?.then(/* Nothing to do */);
    }
  }

}

