/**
 * This service help to manage cookies
 */
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable()
export class CookiesService {


  /**
   * Set a cookie
   * @param cname cookie name
   * @param cvalue cookie value
   * @param exdays cookie expiration in days
   */
  setCookie(cname: string, cvalue: any, exdays = 90) {
    const D = new Date();
    D.setTime(D.getTime() + exdays * 24 * 60 * 60 * 1000);

    const expires = 'expires=' + D.toUTCString();
    const domain = window.location.hostname === 'localhost' ? 'localhost' : environment.cookieDomain;
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;samesite=lax;domain=' + domain ;
  }

  /**
   * Get cookie
   * @param cname cookie name
   */
  getCookie(cname: string): string {
    const name = cname + '=';
    const cookie = decodeURIComponent(document.cookie);
    const CA = cookie.split(';');

    for (let i = 0; i < CA.length; i++) {
      let c = CA[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   * Remove all cookies
   */
  removeCookies() {
    const res = document.cookie;
    const multiple = res.split(';');
    for (let i = 0; i < multiple.length; i++) {
      const key = multiple[i].split('=');
      document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
    }
  }
}
