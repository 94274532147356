
export const params = {
  // Maximum File size allowed for upload
  max_file_size_allowed: 31457280,
  // Breakpoint for responsive resize
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    lx: 1092,
    xl: 1200,
    xxl: 1476
  },
  // Reload link
  reload_link: {
    fr: 'https://roadoo.typeform.com/to/stpbywV9',
    en: 'https://roadoo.typeform.com/to/FPtE9VJF',
    es: 'https://roadoo.typeform.com/to/jO7odlGY',
    pt: 'https://roadoo.typeform.com/to/mQqMppUf',
    de: 'https://roadoo.typeform.com/to/Ls5JDSOC',
    it: 'https://roadoo.typeform.com/to/ODqBBpyX'
  },
  // Help link
  help_link: {
    fr: 'https://roadoo.typeform.com/to/SwGxSgdD',
    en: 'https://roadoo.typeform.com/to/NcKW76kB',
    es: 'https://roadoo.typeform.com/to/Le5Yr6bi',
    pt: 'https://roadoo.typeform.com/to/Uzr3mZgo',
    de: 'https://roadoo.typeform.com/to/xT9peOB3',
    it: 'https://roadoo.typeform.com/to/DJ0gWeUZ'
  },
  // Stats challenge call to action
  stats_help_link: {
    fr: 'https://roadoo.typeform.com/to/KuC8VElu',
    en: 'https://roadoo.typeform.com/to/U6KD0I6Q',
    es: 'https://roadoo.typeform.com/to/U6KD0I6Q',
    pt: 'https://roadoo.typeform.com/to/U6KD0I6Q',
    de: 'https://roadoo.typeform.com/to/bw3e5TQc',
    it: 'https://roadoo.typeform.com/to/NtPW5tJ5'
  },
  // Buy licenses link
  buy_licenses_link: {
    fr: 'https://roadoo.typeform.com/to/IOOdWgSr',
    en: 'https://roadoo.typeform.com/to/E6IRaHuR',
    es: 'https://roadoo.typeform.com/to/NloREXE6',
    pt: 'https://roadoo.typeform.com/to/ARJqkWnn',
    de: 'https://roadoo.typeform.com/to/gxvouz1j',
    it: 'https://roadoo.typeform.com/to/m5PK8tMM'
  },
  // Links:
  links: {
    user_help_link: 'https://roadoo.notion.site/roadoo/42ddcc1cb2354a32baf9a981d448979b?v=ff103b61ae0b4398b455e55070d3fc3a'
  },
  // Roadbox configuration
  roadbox: {
    allowedFilesMime: 'image/jpeg,image/png,video/avi,video/mpeg,video/mp4,video/quicktime,video/3gpp,application/pdf,text/csv,application/octet-stream,.xml,.doc,.docx,.xls,.xlsx,.ppt,.pptx',
    documentsAccept: '.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx',
    imagesAccept: '.jpg,.jpeg,.png',
    videosAccept: '.avi,.mp4,.mpg,.mov,.3gp'
  },
  // Timeout for serviceState request
  timeoutService: 30000
};

// Main colors
export const appColors: {used: Colors, light: Colors, dark: Colors} = {
  // Used color
  used: null,
  // Light Mode
  light: {
    primary: '#3AB9DC',
    secondary: '#229BBD',
    tertiary: '#1A7d99',
    success: '#2dd36f',
    warning: '#ffc409',
    danger: '#eb445a',
    dark: '#404041',
    medium: '#A7A9AB',
    light: '#f1f1f2',
    blue: '#3AB9DC',
    orange: '#ff6508',
    red: '#ef4751',
    green: '#00c401',
    yellow: '#e0c300',
    foreground: '#000000',
    background: '#FFFFFF',
    eStore: '#00313B',
    challenges: '#229BBD',
    roadooPrimary: '#3AB9DC',
    roadooSecondary: '#229BBD',
    roadooTertiary: '#1A7d99',
  },
  // Dark Mode
  dark: {
    primary: '#22a0c3',
    secondary: '#40bbdd',
    tertiary: '#66cae5',
    success: '#2dd26f',
    warning: '#f5bc00',
    danger: '#bd142a',
    dark: '#bfbfc0',
    medium: '#696969',
    foreground: '#ffffff',
    background: '#1D1E20',
    light: '#25262a',
    blue: '#22a0c3',
    orange: '#f55e00',
    red: '#b7101b',
    green: '#3dff3d',
    yellow: '#ffe11f',
    eStore: '#00313B',
    challenges: '#66cae5',
    roadooPrimary: '#22a0c3',
    roadooSecondary: '#40bbdd',
    roadooTertiary: '#66cae5',
  }
};

interface Colors {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  success?: string;
  warning?: string;
  danger?: string;
  dark?: string;
  medium?: string;
  light?: string;
  foreground?: string;
  background?: string;
  blue?: string;
  orange?: string;
  red?: string;
  green?: string;
  yellow?: string;
  eStore?: string;
  challenges?: string;
  roadooPrimary?: string;
  roadooSecondary?: string;
  roadooTertiary?: string;
}

