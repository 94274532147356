import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from '@helpers/utils';

/**
 * This pipe safe url link with http for href
 */

@Pipe({
  name: 'safeHref'
})
export class SafeHrefPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  public transform(value: string): string {
    if (value.search('http') === -1) {
      value = value.replace(Utils.PATTERN_WWW, 'http://$2');
    }
    return value;
  }
}
