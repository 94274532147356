import { PlatformInterface } from '@interfaces/api';
import { PermissionsForSettingsInterface } from '@services/access-control/permissions-for-settings.model';
import { PermissionsForClientInterface } from '@services/access-control/permissions-for-client.model';

/**
 * Dashboard permissions objects
 */
export interface PermissionsForDashboardInterface {
  platform: PlatformInterface;

  isMainSettingsEnabled: boolean;
  isUsersEnabled: boolean;
  isTeamsEnabled: boolean;
  isChallengesEnabled: boolean;
  isCoinsEnabled: boolean;
  isRoadboxEnabled: boolean;
  isFormsEnabled: boolean;
  isBillingEnabled: boolean;
  isQuizEnabled: boolean;
  isSurveysEnabled: boolean;
  isStatsEnabled: boolean;
  isUpdateChallengeProgressionEnabled: boolean;
  isChallengeCategoriesEnabled: boolean;
  isFeedEnabled: boolean;
  isApiEnabled: boolean;

  clientPermissions: PermissionsForClientInterface;

  // Indicate that user is admin
  isAdminUser: boolean;

  permissions: { [key: string]: { view: boolean, add: boolean, update: boolean, delete: boolean } };
  settings?: PermissionsForSettingsInterface;
  // Valid state of data
  valid: boolean;

  buildPermissionsFromRouteList(routeList: any, clientPermissions: PermissionsForClientInterface): void;

  buildPermissionsFromAccessList(accessList: any, clientPermissions: PermissionsForClientInterface): void;

  resetPermissions(): void;
}

export class PermissionsForDashboardModel implements PermissionsForDashboardInterface {
  platform = null;

  isMainSettingsEnabled = false;
  isUsersEnabled = false;
  isTeamsEnabled = false;
  isChallengesEnabled = false;
  isUpdateChallengeProgressionEnabled = false;
  isChallengeCategoriesEnabled = false;
  isCoinsEnabled = false;
  isRoadboxEnabled = false;
  isFormsEnabled = false;
  isBillingEnabled = false;
  isQuizEnabled = false;
  isSurveysEnabled = false;
  isStatsEnabled = false;
  isFeedEnabled = false;
  isApiEnabled = false;

  isAdminUser = false;

  settings = null;
  valid = false;
  permissions = {};

  clientPermissions: PermissionsForClientInterface = null;

  /**
   * Initialize Access permissions with view, add, update and delete flag
   * @param accessList access list
   */
  initAccessPermissions(accessList: any) {
    accessList.forEach((element) => {
      this.permissions[element.route] = {
        view: element.list.view ?? false,
        add: element.list.add ?? false,
        update: element.list.update ?? false,
        delete: element.list.delete ?? false
      };
    });
  }

  /**
   * Build current permissions of model from Route List
   * @param routeList route list
   * @param clientPermissions client permissions
   */
  buildPermissionsFromRouteList(routeList: any, clientPermissions: PermissionsForClientInterface) {
    this.clientPermissions = clientPermissions;
    this.buildPermissions(routeList, false);
  }

  /**
   * Build current permissions of model from Access List
   * @param accessList access list
   * @param clientPermissions client permissions
   */
  buildPermissionsFromAccessList(accessList: any, clientPermissions: PermissionsForClientInterface) {
    this.clientPermissions = clientPermissions;
    this.buildPermissions(accessList, true);
  }

  /**
   * Reset permissions
   */
  resetPermissions() {
    this.platform = null;

    this.isMainSettingsEnabled = false;
    this.isUsersEnabled = false;
    this.isTeamsEnabled = false;
    this.isChallengesEnabled = false;
    this.isChallengeCategoriesEnabled = false;
    this.isUpdateChallengeProgressionEnabled = false;
    this.isCoinsEnabled = false;
    this.isRoadboxEnabled = false;
    this.isQuizEnabled = false;
    this.isSurveysEnabled = false;
    this.isFormsEnabled = false;
    this.isBillingEnabled = false;
    this.isStatsEnabled = false;
    this.isFeedEnabled = false;
    this.isApiEnabled = false;

    this.isAdminUser = false;

    this.valid = false;

    if (this.settings) {
      this.settings.resetPermissions();
    }
  }

  /**
   * Build permissions
   * @param list route/access list
   * @param isAccessList is access list ?
   * @private
   */
  private buildPermissions(list: any, isAccessList = false) {
    if (list) {
      const routeListArray = Object.keys(list).map((key) => {
        return isAccessList ? list[key]?.route : list[key];
      });
      if (isAccessList) {
        this.initAccessPermissions(list);
      } else {
        // init routes full permissions
        Object.keys(list).map((key) => {
          this.permissions [list[key]] = {
            view: true,
            add: true,
            update: true,
            delete: true
          };
        });
      }
      // For some feature, dashboard activation is conditioned by client activation (i.e. challenges, forms, roadbox...)
      this.isMainSettingsEnabled = routeListArray.indexOf('Main') > -1;
      this.isUsersEnabled = routeListArray.indexOf('User') > -1;
      this.isTeamsEnabled = routeListArray.indexOf('Team') > -1;
      this.isChallengesEnabled = this.clientPermissions?.isChallengesEnabled && routeListArray.indexOf('Challenge') > -1;
      this.isChallengeCategoriesEnabled = this.clientPermissions?.isChallengesEnabled && routeListArray.indexOf('challengeCategories') > -1;
      this.isUpdateChallengeProgressionEnabled = routeListArray.indexOf('updateChallengeProgression') > -1;
      this.isCoinsEnabled = routeListArray.indexOf('Coins') > -1;
      this.isRoadboxEnabled = this.clientPermissions?.isRoadboxEnabled && routeListArray.indexOf('Gallery') > -1;
      this.isQuizEnabled = this.clientPermissions?.isQuizEnabled && routeListArray.indexOf('Quizz') > -1;
      this.isSurveysEnabled = routeListArray.indexOf('Sondage') > -1;
      this.isFormsEnabled = this.clientPermissions?.isFormsEnabled && routeListArray.indexOf('Form') > -1;
      this.isBillingEnabled = routeListArray.indexOf('Billing') > -1;
      this.isStatsEnabled = routeListArray.indexOf('Stats') > -1;
      this.isApiEnabled = routeListArray.indexOf('Api') > -1;
      this.isFeedEnabled = this.clientPermissions?.isFeedEnabled && this.isMainSettingsEnabled;

      this.valid = true;
    }
  }
}
