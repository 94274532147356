import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  ReqChallengeUsersListInterface, ReqCoinsHistoryChartInterface,
  ReqCoinsHistoryInterface, ReqPublicProfileInterface,
  ReqTeamsAndMembersInterface,
  ReqTeamsForFeedInterface, ReqUpdateUserInterface,
  UserCoinsInterface, UserInterface, UserProfileData
} from '@interfaces/api/client';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Utils } from '@helpers/utils';
import { UserProfileInterface } from '@interfaces/connection.interface';
import * as moment from 'moment';

/**
 * Service to interface with Users API section
 */

@Injectable()
export class UsersService extends HttpBaseService {

  /**
   * Used to get the amount of coins of the current user
   */
  getUsersCoins(): Observable<UserCoinsInterface> {
    return this.stdRequest(this.http.post<UserCoinsInterface>(`${ this.rootApi }/getusercoins`, null));
  }

  /**
   * Used to get all users and teams from the platform
   */
  getTeamsAndMembers(userIds?: Array<number>, teamIds?: Array<number>): Observable<ReqTeamsAndMembersInterface> {
    const body = new FormData();
    if (userIds?.length > 0) {
      body.append('id_customers', JSON.stringify(userIds));
    }
    if (teamIds?.length > 0) {
      body.append('id_teams', JSON.stringify(teamIds));
    }
    return this.stdRequest(this.http.post<ReqTeamsAndMembersInterface>(`${ this.rootApi }/getteamsandmembers`, body));
  }

  /**
   * Get teams for feed
   * @param idTeam id team
   */
  getTeamsForFeed(idTeam = 0): Observable<ReqTeamsForFeedInterface> {
    const body = new FormData();
    body.append('id_team', idTeam.toString());

    return this.stdRequest(this.http.post<ReqTeamsForFeedInterface>(`${ this.rootApi }/getteamsforfeed`, body));
  }

  /**
   * Get challenge users
   * @param search filter
   * @param idTeam id of the team to get users
   */
  getChallengeUsersList(search?: string, idTeam = 0): Observable<ReqChallengeUsersListInterface> {
    const body = new FormData();
    if (search) {
      body.append('search', search);
    }

    if (idTeam > 0) {
      body.append('id_team', Utils.toString(idTeam));
    }

    return this.stdRequest(this.http.post<ReqChallengeUsersListInterface>(`${ this.rootApi }/getChallengeUserList`, body));
  }

  /**
   * Get coins History
   */
  coinsHistory(): Observable<ReqCoinsHistoryInterface> {
    return this.stdRequest<ReqCoinsHistoryInterface>(
      this.http.post<ReqCoinsHistoryInterface>(`${ this.rootApi }/getpointshistory`, null)
    ).pipe(
      tap(response => response?.points_history?.forEach(item => {
        item.added_amount_num = Utils.toFloat(item.added_amount);
      }))
    );
  }

  /**
   * Used to get coins History Chart data
   */
  coinsHistoryChart(): Observable<ReqCoinsHistoryChartInterface> {
    return this.stdRequest<ReqCoinsHistoryChartInterface>(
      this.http.post<ReqCoinsHistoryChartInterface>(`${ this.rootApi }/getpointshistorychart`, null)
    );
  }

  /**
   * Get public profile of user
   * @param idUser id of user
   */
  publicProfile(idUser: number): Observable<ReqPublicProfileInterface> {
    const body = new FormData();
    body.append('id_user', idUser.toString());
    return this.stdRequest(this.http.post<ReqPublicProfileInterface>(`${ this.rootApi }/getpublicprofile`, body));
  }

  /**
   * Update user in backend
   * @param data use data
   * @param profileAvatar profile avatar
   * @param profileCover profile cover
   * @param customFields: custom fields to add
   */
  updateUser(data: UserProfileData,
             profileAvatar?: File,
             profileCover?: File,
             customFields?: { [key: string]: string }): Observable<ReqUpdateUserInterface> {
    const body = new FormData();
    let newData = data;
    // Add custom fields to user JSON object
    if (customFields && typeof customFields === 'object') {
      newData = Object.assign(data, customFields);
    }
    body.append('user', JSON.stringify(newData));
    if (profileAvatar) {
      body.append('profil_pic', profileAvatar);
    }
    if (profileCover) {
      body.append('profil_bg', profileCover);
    }
    return this.stdRequest(this.http.post<ReqUpdateUserInterface>(`${ this.rootApi }/updateuser`, body));
  }
  /**
   * Retrieve link to download iOs custom apps
   */
  getCodeLink(): Observable<any> {
    return this.stdRequest(this.http.post<any>(`${ this.rootApi }/getCodeLink`, null));
  }

  /**
   * Convert UserProfileInterface to UserProfileData
   * @param user UserProfileData object
   */
  profileToData(user: UserProfileInterface): UserProfileData {
    return {
      allow_display_profile: !!user.allow_display_profile,
      allow_notifs_mail: user.allow_notifs_mail ? '1' : '0',
      allow_notifs_push: user.allow_notifs_push ? '1' : '0',
      allow_notifs_sound: user.allow_notifs_sound ? '1' : '0',
      id: user.id.toString(),
      id_gender: user.id_gender,
      collaboration: user.collaboration,
      firstname: user.firstname,
      lastname: user.lastname,
      nickname: user.nickname,
      birthday: user.birthday && user.birthday !== 'Invalid date' ? moment(user.birthday).format('X') : '',
      ville: user.ville,
      email: user.email,
      phone: user.phone,
      service: user.service,
      societe: user.societe,
      poste: user.poste,
      departement: user.departement,
      region: user.region,
      pays: user.pays,
      optin: user.optin,
      optin_medias: user.optin_medias,
      prefered_language: user.prefered_language,
      notificationCount: user.notificationCount,
      profil_pic: user.profil_pic,
      profil_bg: user.profile_bg,
      citation: user.citation
    };
  }

  /**
   * TODO: To remove - get random user for podium interface
   * @param nb nb of user to get
   */
  random(nb = 10): Observable<UserInterface[]> {
    return this.getTeamsAndMembers().pipe(switchMap(response => {
      const result: UserInterface[] = [];
      if (response?.customers?.length > 0) {
        for (let i = 0; i < nb; i++) {
          result.push(Object.assign({}, response.customers[Utils.randomInt(0, response.customers.length)]));
        }
      }
      return of(result);
    }));
  }
}
