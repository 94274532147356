import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {

    constructor() { }

    public transform(value: string): number {
        if (value.indexOf('.') > -1) {
            return parseFloat(value);
        }
        return parseInt(value, 10);
    }
}
