import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import {
  ReqFeedPostsInterface,
  FeedPostsData,
  ReqFeedCategoriesInterface,
  FeedCategoryData,
  FeedSortCategoryData,
  ReqFeedLinkInterface,
  StandardPostInterface,
  FeedPostData,
  FeedPostInterface,
  ReqFeedPostsStatsInterface,
  ReqFeedScrapContentInterface,
  FeedSharePerformanceData,
  ReqFeedPostInterface,
  ChallengeFilterData
} from '@interfaces/api/client';
import { StandardResponseInterface } from '@interfaces/api';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Utils } from '@helpers/utils';
import { environment } from '@environments/environment';
import { RoadBoxService } from '@services/api/client/roadbox.service';

@Injectable()
export class NewsFeedService extends HttpBaseService {

  static NUMBER_OF_POSTS = 10;

  private lastPosts: {
    result?: ReqFeedPostsInterface,
    debut: number,
    limit: number
  };

  constructor(protected http: HttpClient) {
    super(http);

    this.lastPosts = {debut: 0, limit: NewsFeedService.NUMBER_OF_POSTS};
  }

  /**
   * Used to get the news feed channel
   * @param params request params
   * @param next get the next page
   */
  getPosts(params?: FeedPostsData, next = false): Observable<ReqFeedPostsInterface> {
    // Init
    if (next === false) {
      this.lastPosts = {result: null, debut: 0, limit: NewsFeedService.NUMBER_OF_POSTS};
    } else {
      this.lastPosts.debut += NewsFeedService.NUMBER_OF_POSTS;

      if (this.lastPosts.debut > Utils.toNumber(this.lastPosts.result?.total_post_count)) {
        return of(this.lastPosts.result);
      }
    }
    const body = new FormData();
    body.append('debut', this.lastPosts.debut.toString());
    body.append('limit', this.lastPosts.limit.toString());
    if (params?.categories) {
      body.append('categories', JSON.stringify(params?.categories));
    }
    if (params?.teams) {
      body.append('id_team', JSON.stringify(params?.teams));
    }
    if (params?.id_customer) {
      body.append('id_customer', params.id_customer.toString());
    }
    if (params?.id_team_feed) {
      body.append('id_team_feed', params?.id_team_feed.toString());
    }
    if (params?.pending_posts) {
      body.append('pending_posts', params?.pending_posts.toString());
    }
    if (params?.date_from) {
      body.append('date_from', params.date_from);
    }
    if (params?.date_to) {
      body.append('date_to', params.date_to);
    }

    return this.stdRequest(this.http.post<ReqFeedPostsInterface>(`${ this.rootApi }/getposts`, body).pipe(
      tap(response => {
        // Build unsafe media path
        response?.posts?.forEach(post => RoadBoxService.buildUnsafeMedias(post?.gallery));
        if (next) {
          // Update if next
          this.lastPosts.result.posts = this.lastPosts.result.posts.concat(response.posts);
          this.lastPosts.result.notification_count = response.notification_count;
          this.lastPosts.result.pending_posts = response.pending_posts;
          this.lastPosts.result.user_coins = response.user_coins;
        } else {
          this.lastPosts.result = response;
        }
      }),
      map(() => this.lastPosts.result)
    ));
  }

  /**
   * Used to get post by id
   * @param idPost id of the post
   */
  getPost(idPost: string): Observable<ReqFeedPostInterface> {
    return this.stdRequest(this.http.post<ReqFeedPostInterface>(`${ this.rootApi }/getpost/${ idPost }`, null));
  }

  /**
   * Get posts stats for user
   * @param params parameters
   */
  getPostsStats(params: FeedPostsData): Observable<ReqFeedPostsStatsInterface> {
    const body = new FormData();
    body.append('id_customer', params.id_customer.toString());

    if (params?.date_from) {
      body.append('date_from', params.date_from);
    }
    if (params?.date_to) {
      body.append('date_to', params.date_to);
    }

    return this.stdRequest(this.http.post<ReqFeedPostsStatsInterface>(`${ this.rootApi }/getPostsStats`, body));
  }

  /**
   * Used to get the list of available categories to filter posts.
   */
  getCategories(idTeam?: number): Observable<ReqFeedCategoriesInterface> {
    const body = new FormData();
    if (idTeam) {
      body.append('id_team_feed', idTeam.toString());
    }
    return this.stdRequest(this.http.post<ReqFeedCategoriesInterface>(`${ this.rootApi }/getpostcategories`, body));
  }

  /**
   * Used to create a new post category
   * @param category category data
   */
  addPostCategory(category: FeedCategoryData): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('category', JSON.stringify(category));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/addpostcategory`, body));
  }

  /**
   * Used to update post category
   * @param idCategory id category to update
   * @param category category data
   */
  updatePostCategory(idCategory: number, category: FeedCategoryData): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('category', JSON.stringify(category));
    body.append('id_category', idCategory.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/updatepostcategory`, body));
  }

  /**
   * Used to delete post category
   * @param idCategory id category to update
   */
  deletePostCategory(idCategory: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_category', idCategory.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/deletepostcategory`, body));
  }

  /**
   * Used to sort all post category in one time
   * @param categories  array of category objects
   */
  sortPostCategory(categories: Array<FeedSortCategoryData>): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('categories', JSON.stringify(categories));
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/sortpostcategory`, body));
  }

  /**
   * Used to share challenge rank or quizz rank into the news feed
   * @param type type of the share. Can be ‘rank’ to share his position into a challenge or ‘quizz_rank’ to share quizz position.
   * @param objectId the id of the object to share, can be a challenge id or a quizz id
   */
  share(type: string, objectId: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('type', type);
    body.append('object_id', objectId.toString());
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/share`, body));
  }

  /**
   * Used to share performance on feed
   */
  sharePerformance(params: FeedSharePerformanceData, filters?: ChallengeFilterData[]): Observable<StandardResponseInterface> {
    const body = new FormData();
    if (params) {
      Object.keys(params).forEach(key => {
        body.append(key, params[key]);
      });
    }
    if (filters) {
      body.append('filters', JSON.stringify(filters));
    }
    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/performance/share`, body)
    );
  }

  /**
   * Used to get an url preview.
   * @param link url to search.
   */
  explodeLink(link: string): Observable<ReqFeedLinkInterface> {
    const body = new FormData();
    body.append('link', link);
    return this.stdRequest(this.http.post<ReqFeedLinkInterface>(`${ this.rootApi }/explodelink`, body));
  }

  /**
   * Used to get links preview.
   * @param links urls to search.
   */
  scrapContent(links: Array<string>): Observable<ReqFeedScrapContentInterface> {
    const body = new FormData();
    body.append('links', JSON.stringify(links));
    return this.stdRequest(this.http.post<ReqFeedScrapContentInterface>(`${ this.rootApi }/scrapContent`, body));
  }

  /**
   * Used to add or update a post
   * @param data post data
   */
  addPost(data: FeedPostData): Observable<StandardPostInterface> {
    const body: FormData = new FormData();
    body.append('post', JSON.stringify(data.post));
    if (data.id_action_post) {
      body.append('id_action_post', data.id_action_post.toString());
    }
    // Images
    if (data.images) {
      data.images.forEach((file, index) => {
        body.append(`img${ index }`, file, file.name);
      });
    }
    if (data.keep_images) {
      body.append('keep_images', JSON.stringify(data.keep_images));
    }
    // Videos
    if (data.videos) {
      data.videos.forEach((file, index) => {
        body.append(`vid${ index }`, file, file.name);
      });
    }
    if (data.keep_videos) {
      body.append('keep_videos', JSON.stringify(data.keep_videos));
    }
    // Files
    if (data.keep_files) {
      body.append('keep_files', JSON.stringify(data.keep_files));
    }
    // TODO: implement others params
    return this.stdRequest(this.http.post<StandardPostInterface>(`${ this.rootApi }/addpost`, body));
  }

  /**
   * Update post from FeedPostInterface
   * @param post post data
   */
  updatePost(post: FeedPostInterface): Observable<StandardPostInterface> {
    const data: FeedPostData = {
      id_action_post: post.id_action_post,
      post: {
        // Manage shared media
        id_action: post.id_action,
        categories: post.categories?.map(item => item.id_action_post_category),
        texte: post.html,
        on_top: post.on_top,
        links: post.links?.map(item => item.url),
        frames: post.frames,
        id_customers: post.id_customers,
        id_teams: post.id_teams,
        id_team_feed: post.id_team_feed,
        gif_url: post.gif_url
      },
      keep_images: post.images,
      keep_videos: post.videos,
      keep_files: post.files?.map(item => (item as any).url)
    };

    return this.addPost(data);
  }

  /**
   * Delete a post (only admin or post owner)
   * @param idPost id post to remove
   */
  removePost(idPost: number): Observable<StandardPostInterface> {
    return this.stdRequest(this.http.post<StandardPostInterface>(`${ this.rootApi }/removepost/${ idPost.toString() }`, null));
  }

  /**
   * Report a post
   * @param idPost id post to remove
   */
  reportPost(idPost: number): Observable<StandardPostInterface> {
    return this.stdRequest(this.http.post<StandardPostInterface>(`${ this.rootApi }/postReport/${ idPost.toString() }`, null));
  }


  /**
   * Used to report a post to platform administrators
   * @param image the image to upload.
   */
  uploadImage(image: File): Observable<StandardPostInterface> {
    return this.stdRequest(new Observable(observer => {
      const formData: FormData = new FormData();

      formData.append('image', image);

      const req = new HttpRequest('POST', `${ this.rootApi }/uploadimage`, formData, {
        reportProgress: true
      });
      this.http.request(req).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            /** @var {HttpProgressEvent} event */
            // This is an upload progress event. Compute and show the % done:
            // this.percentDone = event.loaded / event.total;
          } else if (event instanceof HttpResponse) {
            observer.next(event.body);
            observer.complete();
          }
        },
        error: (err) => {
          console.log(err);
          observer.error(err);
        }
      });
    }));
  }

  /**
   * Return the pending posts
   */
  pendingPosts(): Observable<ReqFeedPostsInterface> {
    return this.stdRequest<ReqFeedPostsInterface>(this.http.post<ReqFeedPostsInterface>(`${ this.rootApi }/administration/Posts`, null)).pipe(tap(response => {
      response?.posts?.forEach(post => {
        post?.gallery?.medias?.forEach(media => {
          media.src_unsafe = `${ environment.mediaRoot }/clients/gallery/${ post.gallery.h }/${ Utils.extractFilename(media.src) }`;
        });
      });
    }));
  }

  /**
   * Validate the pending posts
   * @param idPostsToEnable array of ids to enable
   * @param idPostsToDelete array of ids to delete
   */
  validatePendingPosts(idPostsToEnable?: Array<number>, idPostsToDelete?: Array<number>): Observable<StandardResponseInterface> {
    const body = new FormData();
    if (idPostsToEnable) {
      body.append('id_posts', JSON.stringify(idPostsToEnable));
    }
    if (idPostsToDelete) {
      body.append('delete_posts', JSON.stringify(idPostsToDelete));
    }
    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Posts/validate`, body));
  }


}
