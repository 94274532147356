import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '@services/common.service';

@Pipe({
  name: 'translocoCoins'
})
export class TranslocoCoinsPipe implements PipeTransform {

  constructor(private commonServ: CommonService) {
  }

  public transform(key: string): string {
    // TODO: Problem with loading translation
    return this.commonServ.translateCoins(key);
  }

}
