import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-img-store',
  templateUrl: './img-store.component.html',
  styleUrls: ['./img-store.component.scss'],
})
export class ImgStoreComponent implements OnInit, OnDestroy {

  @Input() store: 'apple' | 'google' | 'abm';

  @Input() size: 'small' | 'normal' = 'normal';

  lang: string;

  private subscriptions: Subscription[] = [];

  constructor(protected translateServ: TranslocoService) { }

  ngOnInit() {
    this.lang = this.translateServ.getActiveLang();

    this.subscriptions.push(this.translateServ.langChanges$.subscribe(lang => {
      if (lang) {
        this.lang = lang;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
