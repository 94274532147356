import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconTooltipComponent } from './icon-tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    IconTooltipComponent
  ],
  exports: [
    IconTooltipComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatTooltipModule
  ]
})
export class IconTooltipModule { }
