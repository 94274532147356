import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {

  @Input() icon: string;
  @Input() iconPosition: 'top' | 'bottom' = 'top';

  @Input() color = 'primary';
  @Input() disabled = false;

  ngOnInit() {}

}
