import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorChipComponent } from './color-chip.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    ColorChipComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    ColorChipComponent
  ]
})
export class ColorChipModule { }
