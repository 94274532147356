import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminApiInterface, AdminMainData, AdminMainInterface, ReqAdminApiInterface, ReqAdminMainInterface } from '@interfaces/api/admin';
import { StandardResponseInterface } from '@interfaces/api';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminMainService extends HttpBaseService {


  /**
   * Get main settings
   */
  main(): Observable<AdminMainInterface> {
    return this.stdRequest(this.http.post<ReqAdminMainInterface>(`${ this.rootApi }/administration/Main`, null).pipe(
      map(response => response.mainInfos)
    ));
  }

  /**
   * Update main settings
   * @param data input data
   */
  updateMain(data: AdminMainData): Observable<StandardResponseInterface> {
    const body = new FormData();

    body.append('main_infos', JSON.stringify(data.main_infos));

    if (data.logo) {
      body.append('logo', data.logo);
    }
    if (data.logo_square) {
      body.append('logo_square', data.logo_square);
    }
    if (data.image) {
      body.append('image', data.image);
    }

    return this.stdRequest(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Main/update`, body));
  }

  /**
   * Get api info
   */
  api(): Observable<AdminApiInterface> {
    return this.stdRequest(this.http.post<ReqAdminApiInterface>(`${ this.rootApi }/administration/Api`, null).pipe(
      map(response => response.ApiInfos)
    ));
  }

  /**
   * Renew api info
   */
  apiRenew(): Observable<AdminApiInterface> {
    return this.stdRequest(this.http.post<ReqAdminApiInterface>(`${ this.rootApi }/administration/Api/renew`, null).pipe(
      map(response => response.ApiInfos)
    ));
  }

}
