import {Pipe, PipeTransform} from '@angular/core';
import { Utils } from '@helpers/utils';

@Pipe({
    name: 'extractName'
})
export class ExtractNamePipe implements PipeTransform {

    constructor() { }

    public transform(value: string): string {
        return Utils.extractFilename(value || '');
    }
}
