import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { IconCircleModule, ImgAvatarModule } from '@components/images';
import { TalkConversationsComponent } from './conversations/conversations.component';
import { TalkPopupConversationsComponent } from '@components/app/talk/popup-conversations/popup-conversations.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@pipes';
import { TalkUserSelectModule } from '@components/app/talk/_user-select/user-select.module';
import { TalkConversationsParticipantsModal } from '@components/app/talk/conversations/participants/participants.modal';
import { ToolbarModalModule } from '@components/modals/toolbar-modal';

@NgModule({
  declarations: [
    TalkPopupConversationsComponent,
    TalkConversationsComponent,
    TalkConversationsParticipantsModal
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    MatTooltipModule,
    ToolbarModalModule,
    IconCircleModule,
    ImgAvatarModule,
    PipesModule,
    TalkUserSelectModule
  ],
  exports: [
    TalkPopupConversationsComponent,
    TalkConversationsComponent
  ]
})
export class TalkConversationsModule { }







