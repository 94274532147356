/**
 * Linkify plain text and nl 2 br
 * Manage mentions
 */

import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '@services/common.service';

@Pipe({name: 'smartText'})
export class SmartTextPipe implements PipeTransform {

  constructor(private commonServ: CommonService) {
  }

  transform(value: string): string {
    return this.commonServ.formatHTMLText(value);
  }
}
