import { WebPlugin } from '@capacitor/core';
import { StoreListingPlugin } from './definitions';

export class StoreListingWeb extends WebPlugin implements StoreListingPlugin {
  openStoreListing(options: { appId: string }): Promise<void> {
    console.warn(options);
    throw new Error('Method not implemented.');
  }
}

import { registerPlugin } from '@capacitor/core';

const StoreListing = registerPlugin<StoreListingPlugin>('StoreListing');

export default StoreListing;
