import { BehaviorSubject, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { HttpBaseService } from './api/http-base.service';
import { AuthCodeInterface, TokenInterface, UserProfileInterface } from '@interfaces/connection.interface';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { InitInterface, PlatformInterface, ServicesStateInterface, ServiceStateFlags } from '@interfaces/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { Md5 } from 'ts-md5';
import { Platform } from '@ionic/angular';
import { Utils } from '@helpers/utils';
import { params } from '@environments/params';

declare var batch;

/**
 * Connection service to manage authentication and connection.
 * User is considered authenticated if token is set.
 * User is considered connected if token and platform are set
 */
@Injectable({
  providedIn: 'root'
})
export class ConnectionService extends HttpBaseService {

  private initInProgress = false;
  private initValue: InitInterface;
  private servicesState: ServiceStateFlags = null;
  private appId: string;
  private currentVersion: string;

  /**
   * Subject to manage authentication state.
   * State changed when authentication state is updated (connected / Unconnected)
   */
  private authState: BehaviorSubject<TokenInterface> = new BehaviorSubject(null);

  /**
   * Subject to manage platform state
   * @private
   */
  private platformState: BehaviorSubject<PlatformInterface> = new BehaviorSubject(null);

  /**
   * Subject to manage connection
   * @private
   */
  private connectionState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(protected http: HttpClient,
              private storage: Storage,
              private translate: TranslocoService,
              private platform: Platform) {
    super(http);
  }


  /**
   * Check if value is valid
   * @param value
   * @private
   */
  static isValid(value: any) {
    return value !== null && value !== undefined;
  }

  /**
   * Initialize Auth Service
   * Shall be called in main app
   */
  public init() {

    // Check if user has token in order to load local data
    this.getToken().subscribe((res) => {
      if (res) {
        // Check session and refresh user token
        this.verifySession().subscribe({
          next: response => {
            if (response?.user) {
              this.updateTokenProfile(response.user);
            }
            // Refresh local platforms
            this.initPlatform(this.getTokenValue()).subscribe();
          },
          error: err => {
            console.error(err);
            this.logout();
          }
        });
      }
    });

    this.authState.subscribe(token => {
      this.checkConnectionState(token, this.platformState.getValue());
    });

    this.platformState.subscribe(platform => {
      this.checkConnectionState(this.authState.getValue(), platform);
    });
  }

  /**
   * Return the auth state to manage connection change event
   */
  public getAuthState(): Observable<TokenInterface> {
    return this.authState.asObservable();
  }

  /**
   * Return the connection state value
   */
  public getConnectionState(): Observable<boolean> {
    return this.connectionState.asObservable();
  }

  /**
   * Return the platform state
   */
  public getPlatformState() {
    return this.platformState.asObservable();
  }

  /**
   * Logout
   */
  public logout() {
    this.batchSetId(null, true)?.then(/* Nothing to do */);
    this.flush();
  }

  /**
   * Flush data
   */
  public flush() {
    this.savePlatforms(null);
    this.saveToken(null);
    this.platformState.next(null);
    this.authState.next(null);
    this.initValue = null;
  }

  /**
   * Get current action id
   */
  public getCurrentActionId(): number {
    return this.platformState.getValue()?.id_action;
  }

  /**
   * Check Services State
   */
  public checkServicesState(): Observable<ServiceStateFlags> {
    this.servicesState = null;
    return this.http.post<ServicesStateInterface>(`${ this.rootApi }/servicesState`, null).pipe(
      timeout(params.timeoutService),
      catchError(err => {
        // Force maintenance state in case of error
        this.servicesState = {
          isMaintenance: true,
          isUpdate: false,
          isMajor: false
        };
        return throwError(err);
      }),
      switchMap((service) => {
        if (service.error) {
          return throwError(service.message);
        } else {
          this.determineServiceState(service);
          return of(this.servicesState);
        }
      })
    );
  }

  public getAppId(): string {
    return this.appId;
  }

  public getCurrentVersion(): string {
    return this.currentVersion;
  }

  public getServicesState(): ServiceStateFlags {
    return this.servicesState;
  }


  /**
   * Login to backend
   * @param email email for login
   * @param password password for login
   */
  public loginByCredentials(email: string, password: string): Observable<InitInterface> {
    const body = new FormData();
    body.append('email', email);
    body.append('passwd', password);

    return this.http.post<TokenInterface>(`${ this.rootApi }/auth`, body, {withCredentials: true}).pipe(
      switchMap((tokenData) => {
        if (tokenData.error) {
          return throwError(tokenData.message);
        } else {
          this.saveToken(tokenData);
          this.batchSetId(tokenData.user.id_customer)?.then(/* Nothing to do */);
          return this.initPlatform(tokenData);
        }
      })
    );
  }

  /**
   * Login to backend by OAuth
   * @param token OAuth token
   * @param cid OAuth client id
   */
  public loginByOAuth(token: string, cid?: string) {
    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    const body = new FormData();
    if (cid) {
      body.append('cid', cid);
    }

    return this.http.post<TokenInterface>(`${ this.rootApi }/auth`, body, {headers, withCredentials: true}).pipe(
      switchMap((tokenData) => {
        if (tokenData.error) {
          return throwError(tokenData.message);
        } else {
          this.saveToken(tokenData);
          this.batchSetId(tokenData.user.id_customer)?.then(/* Nothing to do */);
          return this.initPlatform(tokenData);
        }
      })
    );
  }

  /**
   * Get Token from Auth code
   * @param code
   * @param clientId
   * @param clientSecret
   * @param redirectUri
   */
  public oauthCode(code: string,
                   clientId: string,
                   clientSecret: string,
                   redirectUri: string): Observable<AuthCodeInterface> {
    const body = new FormData();
    body.append('grant_type', 'authorization_code');
    body.append('code', code);
    body.append('client_id', clientId);
    body.append('client_secret', clientSecret);
    body.append('redirect_uri', redirectUri);
    return this.http.post<AuthCodeInterface>(`${ this.rootApi }/oauth/token`, body);
  }

  /**
   * Initialize to get platforms
   * @param token
   * @private
   */
  private initPlatform(token: TokenInterface) {
    if (!this.initInProgress) {

      this.initInProgress = true;

      return this.initRequest(token.token, this.translate.getActiveLang()).pipe(
        tap((initData) => {
          this.savePlatforms(initData?.actions);

          // Update data of current platform
          const currentActionId = this.platformState.value?.id_action;
          if (currentActionId) {
            const platform = initData?.actions?.find(action => Utils.toNumber(action.id_action) === Utils.toNumber(currentActionId));
            if (platform) {
              this.platformState.next(platform);
            }
          }

          this.initInProgress = false;
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Verify session  with session token and get new tokens
   */
  verifySession() {
    if (this.authState.getValue().session_token) {
      const body = new FormData();
      body.set('code', this.authState.getValue().session_token);

      return this.http.post<TokenInterface>(`${ this.rootApi }/verifysession`, body, {withCredentials: true}).pipe(
        switchMap((tokenData) => {
          if (tokenData.error) {
            return throwError(tokenData.message);
          } else {
            this.saveToken(tokenData);
            this.batchSetId(tokenData.user.id_customer)?.then(/* Nothing to do */);
            this.authState.next(tokenData);
            return of(tokenData);
          }
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Use to initiate an environment.
   * Return list of all user platforms.
   * @param token token get from authentication
   * @param lang language code
   */
  initRequest(token: string, lang: string): Observable<InitInterface> {
    if (this.initValue) {
      return of(this.initValue);
    } else {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + token);
      headers = headers.set('lang', lang);
      return this.http.post<InitInterface>(`${ this.rootApi }/init`, null, {headers}).pipe(
        tap({
          next: response => {
            if (response.error) {
              throwError(response.message);
            } else {
              this.initValue = response;
            }
          },
          error: err => {
            console.log('[ERRRoadooApiService0003] ', err);
          }
        })
      );
    }
  }

  /**
   * Indicate if user is connected
   * Shall have token and platform defined
   * Use token for state
   */
  public isConnected(): Observable<boolean> {
    return forkJoin({
      token: this.getToken(),
      platform: this.getSelectedPlatform()
    }).pipe(map(res => ConnectionService.isValid(res.token) && ConnectionService.isValid(res.platform)));
  }

  /**
   * Indicate if user is connected (without observable)
   */
  public isConnectedValue(): boolean {
    return ConnectionService.isValid(this.authState.getValue()) && ConnectionService.isValid(this.platformState.getValue());
  }

  /**
   * Get the token from storage
   */
  public getToken(): Observable<TokenInterface> {
    if (this.authState.getValue()) {
      return of(this.authState.getValue());
    } else {
      return from(this.storage.get('__token')).pipe(
        tap({
          next: (data: TokenInterface) => this.authState.next(data),
          error: () => this.authState.next(null)
        })
      );
    }
  }

  /**
   * Get the platform from storage
   */
  public getPlatforms(): Observable<PlatformInterface[]> {
    return from(this.storage.get('__platforms'));
  }

  /**
   * Get selected platform
   */
  public getSelectedPlatform(): Observable<PlatformInterface> {
    if (this.platformState.getValue()) {
      return of(this.platformState.getValue());
    } else {
      return forkJoin({
        platforms: this.getPlatforms(),
        action_id: from(this.storage.get('__platform_action_id'))
      }).pipe(
        switchMap(data => {
          if (data.platforms && data.action_id) {
            const selectedPlatform = data.platforms.find(platform => Utils.toNumber(platform.id_action) === Utils.toNumber(data.action_id));
            this.platformState.next(selectedPlatform);
            return of(selectedPlatform);
          } else {
            if (this.platformState.getValue()) {
              this.platformState.next(null);
            }
            return of(null);
          }
        })
      );
    }
  }

  /**
   * Set the selected platform
   * @param idAction
   */
  public setSelectedPlatformId(idAction?: number): Observable<PlatformInterface> {
    return new Observable<PlatformInterface>(observer => {
      if (idAction) {
        this.storage.set('__platform_action_id', idAction)?.then(/* Nothing to do */);
        this.getPlatforms().subscribe(platforms => {
          this.platformState.next(platforms.find(platform => Utils.toNumber(platform.id_action) === Utils.toNumber(idAction)));
          observer.next(this.platformState.getValue());
          observer.complete();
        });
      } else {
        this.storage.remove('__platform_action_id')?.then(/* Nothing to do */);
        observer.next(this.platformState.getValue());
        observer.complete();
      }
    });
  }

  /**
   * Update pofile of token
   * @param user
   */
  public updateTokenProfile(user: UserProfileInterface) {
    const token = this.getTokenValue();

    if (user.id_customer === token?.user.id_customer) {
      token.user = user;
      this.saveToken(token);
      this.authState.next(token);
      return true;
    }

    return false;
  }

  /**
   * Update platform data
   * @param platform
   */
  public updatePlatformData(platform: PlatformInterface) {
    const curPlatform = this.getSelectedPlatformValue();

    if (Utils.toNumber(platform.id_action) === Utils.toNumber(curPlatform.id_action)) {
      this.platformState.next(platform);
    }
  }


  /**
   * Save the token object
   */
  public saveToken(token: TokenInterface) {
    if (token) {
      this.storage.set('__token', token)?.then(/* Nothing to do */);
    } else {
      this.storage.remove('__token')?.then(/* Nothing to do */);
    }
  }

  /**
   * Save platform object
   * @param platforms
   */
  public savePlatforms(platforms: PlatformInterface[]) {
    if (platforms) {
      this.storage.set('__platforms', platforms)?.then(/* Nothing to do */);
    } else {
      this.storage.remove('__platforms')?.then(/* Nothing to do */);
      this.storage.remove('__platform_action_id')?.then(/* Nothing to do */);
    }
  }

  /**
   * Get token auth
   */
  public getTokenValue() {
    return this.authState.getValue();
  }

  /**
   * Get selected platform
   */
  public getSelectedPlatformValue() {
    return this.platformState.getValue();
  }

  /**
   * Save mobile app version & id
   * @param value App Infos
   */
  setAppInfo(value) {
    this.currentVersion = value.version;
    this.appId = value.id;
  }

  /**
   * Determine Service State flags
   * @param state
   * @private
   */
  private determineServiceState(state: ServicesStateInterface) {
    this.servicesState = {
      isMaintenance: false,
      isUpdate: false,
      isMajor: false
    };
    // Check maintenance for all platforms
    if (state.maintenance_enabled) {
      this.servicesState.isMaintenance = true;
    }
    // check update for only mobile platform
    if (this.platform.is('capacitor')) {
      const liveVersion = this.platform.is('android') ? state.android_version : state.ios_version;
      const splitLive = liveVersion.split('.');
      const splitCurrent = this.currentVersion.split('.');

      // To avoid IndexOutOfBounds
      const maxIndex = Math.min(splitCurrent.length, splitLive.length);
      let update = false;
      for (let i = 0; i < maxIndex; i++) {
        const currentVersionPart = splitCurrent[i];
        const liveVersionPart = splitLive[i];

        if (currentVersionPart < liveVersionPart) {
          if (i === 0) {
            this.servicesState.isMajor = true;
          }
          update = true;
          break;
        } else if (currentVersionPart > liveVersionPart) {
          update = false;
          break;
        }
      }
      // If versions are the same so far, but they have different length...
      if (!update && splitCurrent.length !== splitLive.length) {
        update = (splitCurrent.length <= splitLive.length);
      }
      if (update) {
        this.servicesState.isUpdate = true;
      }
    }
  }

  /**
   * Check if user a connected
   * @param authValue
   * @param platformValue
   * @private
   */
  private checkConnectionState(authValue: TokenInterface, platformValue: PlatformInterface) {
    const connected = ConnectionService.isValid(authValue) && ConnectionService.isValid(platformValue);
    if (connected !== this.connectionState.getValue()) {
      this.connectionState.next(connected);
    }
  }

  /**
   * Batch set Id and Manage FirebaseCrashlytics
   * @param userId user id
   * @param disconnect disconnect batch ?
   * @private
   */
  private async batchSetId(userId: string = null, disconnect = false) {
    // Execute only on native mobile device
    if (this.platform.is('capacitor')) {
      await FirebaseCrashlytics.setUserId({
        userId
      });
      // console.log('id_customer : ' + userId ? Md5.hashStr(userId) : 'null');
      batch?.user.getEditor()
        .setIdentifier(userId ? Md5.hashStr(userId).toUpperCase() : null)
        .save();
      if (disconnect) {
        batch?.optOut();
      }
    }
  }
}
