import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-circle',
  template: `<div class="container ion-color-{{backgroundColor || 'primary'}}" [class.small-padding]="smallPadding">
    <ion-icon [name]="name" *ngIf="!iconColor"></ion-icon>
    <ion-icon [name]="name" [color]="iconColor" *ngIf="iconColor"></ion-icon>
  </div>`,
  styleUrls: ['./icon-circle.component.scss'],
})
export class IconCircleComponent implements OnInit {

  @Input() name: string;

  @Input() backgroundColor: string;
  @Input() iconColor: string;

  @Input() smallPadding: boolean;

  constructor() { }

  ngOnInit() {
    this.smallPadding = this.smallPadding !== undefined && this.smallPadding !== false;
  }

}
