import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { ReqLandingPageInterface, PlatformInterface } from '@interfaces/api';
import { GeneralService } from './api';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { ConnectionService } from './connection.service';
import { TrackingClientService } from './client';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Locale, NumberTypes } from '@ngneat/transloco-locale/lib/transloco-locale.types';
import { LinkItemInterface } from '@interfaces/common.interface';
import * as moment from 'moment';
import { UserProfileInterface } from '@interfaces/connection.interface';
import { Utils } from '@helpers/utils';
import { TeamService } from '@services/team.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ThemeService } from '@services/theme.service';
import { Browser } from '@roadoo/browser';
import { params as EnvParams } from '@environments/params';
import { RateApp } from 'capacitor-rate-app';
import { AccessControlService } from '@services/access-control';
import { DarkColor } from '@helpers/_colors';

/**
 * Common service for common task
 */

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  static AUTH_STORAGE_KEY = '__auth-config';

  landingPage: ReqLandingPageInterface;

  private kpiKeyValues = {
    Appointments: 'kpi.appointments',
    Project: 'kpi.opportunity',
    Projects: 'kpi.opportunity',
    Post: 'kpi.post',
    'Post image': 'kpi.post-image',
    'Post video': 'kpi.post-video',
    'Like feed': 'kpi.like-feed',
    'Like galery': 'kpi.like-gallery',
    'Comment feed': 'kpi.comment-feed',
    'Comment galery': 'kpi.comment-gallery',
    Points: 'kpi.points',
    points: 'kpi.points'
  };

  private params: any;

  constructor(private generalServ: GeneralService,
              private connectionServ: ConnectionService,
              private trackingServ: TrackingClientService,
              private accessServ: AccessControlService,
              private navCtrl: NavController,
              private translateServ: TranslocoService,
              private teamServ: TeamService,
              private ionicPlatform: Platform,
              private oauthServ: OAuthService,
              private transLocaleServ: TranslocoLocaleService,
              private themeServ: ThemeService,
              private storage: Storage) {
  }

  /**
   * Get current profile
   */
  getProfile(): UserProfileInterface {
    return this.connectionServ.getTokenValue()?.user;
  }

  /**
   * Get current platform
   */
  getCurrentPlatform(): PlatformInterface {
    return this.connectionServ.getSelectedPlatformValue();
  }

  /**
   * Return absolute url if exist
   */
  getAbsoluteDomainUrl(): string | null {
    if (
      window &&
      'location' in window &&
      'protocol' in window.location &&
      'host' in window.location
    ) {
      return window.location.hostname;
    }
    return null;
  }

  /**
   * Check if landing shall be retrieved and return it
   */
  checkAndGetLandingPage(): Observable<ReqLandingPageInterface | null> {

    // If landing page already get, return it
    if (this.landingPage) {
      return of(this.landingPage);
    }

    const sub = this.getWhiteMarkContext();

    if (sub) {
      return this.generalServ.getLandingPage(sub, this.translateServ.getActiveLang()).pipe(
        tap(response => this.landingPage = response)
      );
    }

    return of(null);
  }

  /**
   * Get current scheme
   */
  getCurrentScheme() {
    return this.themeServ.getCurrentScheme();
  }

  /**
   * Get whiteMark context of App
   */
  getWhiteMarkContext() {
    return this.getCurrentScheme().landing || this.getSubDomain();
  }

  /**
   * Return the subdomain if provided and different from www and app
   */
  getSubDomain(): string {
    // return 'mylink';
    const url = this.getAbsoluteDomainUrl();
    if (!url) {
      return null;
    }
    if (url === 'localhost') {
      return 'rework';
    }
    const parts = url.split('.');
    if (parts.length >= 3) {
      const sub = parts[0];
      if (sub && sub !== 'www' && sub !== 'app') {
        return sub;
      }
    }
    return null;
  }

  /**
   * Logout user from platform
   */
  logout() {
    this.trackingServ.sendTracking(this.trackingServ.getTrackingData());
    this.teamServ.flush();
    this.connectionServ.logout();
    if (this.oauthServ.hasValidAccessToken()) {
      this.oauthServ.logOut();
      this.dataRemove(CommonService.AUTH_STORAGE_KEY)?.then(/* Nothing to do */);
    }
    this.navCtrl.navigateBack(['/login'])?.then(/* Nothing to do */);
  }

  /**
   * Get permission from platform
   * @param keys string of array of the key to check
   */
  checkPermissionFromPlatform(keys: string | Array<string>): boolean | any {
    const routes = this.connectionServ.getSelectedPlatformValue()?.access?.routeList;

    if (!routes) {
      return false;
    }

    let results: any = false;

    // Build authorized routes
    const authorizedRoutes = [];
    Object.keys(routes).forEach(key => {
      authorizedRoutes.push(routes[key]);
    });

    if (typeof keys === 'string') {
      return authorizedRoutes.indexOf(keys) > -1;
    } else if (Array.isArray(keys)) {
      results = {};
      keys.forEach(key => {
        results[key] = authorizedRoutes.indexOf(keys) > -1;
      });
    }

    return results;
  }

  /**
   * Transform color in dark if platform is dark mode
   * @param hexLightColor light color to convert
   */
  public colorFromLightInput(hexLightColor: string) {
    return this.themeServ.isDarkMode() ? (new DarkColor(hexLightColor)).hex : hexLightColor;
  }

  /**
   * Shortcut to access localizeNumber
   */
  public localizeNumber(value: number | string, type: NumberTypes, locale?: Locale, options?: Intl.NumberFormatOptions) {
    return this.transLocaleServ.localizeNumber(value, type, locale, options);
  }

  /**
   * Shortcut access to translate
   */
  public translate(key: TranslateParams, params?: HashMap, lang?: string) {
    return this.translateServ.translate(key, params, lang);
  }

  /**
   * Shortcut to access getActiveLang
   */
  public getActiveLang() {
    return this.translateServ.getActiveLang();
  }

  /**
   * Translate challenge label
   * @param key key to translate
   * @param value value associated to label
   */
  translateChallengeLabel(key: string, value = 1) {
    const keys = Object.keys(this.kpiKeyValues);

    // Check if key is in list
    const findKey = keys.find(val => val === key);
    if (findKey) {
      return this.translateServ.translate(this.kpiKeyValues[findKey], {num: value});
    }

    if (key === 'Percent' || key === 'percent') {
      return '%';
    }

    if (key === 'ca' || key === 'CA') {
      return '€';
    }

    return key;
  }

  /**
   * Indicate if custom kpi
   * @param kpi kpi to check
   * @param hasChildren is multi challenge
   */
  isCustomKpi(kpi: string, hasChildren: boolean): boolean {
    // CA or percent kpi
    if (kpi === 'ca' || kpi === 'CA' || kpi === '%') {
      return false;
    }
    if (kpi.toLowerCase() === 'points' && !hasChildren) {
      return true;
    }
    const keys = Object.keys(this.kpiKeyValues);
    return keys.indexOf(kpi) === -1;
  }

  /**
   * Return transloco events
   */
  translocoEvents() {
    return this.translateServ.events$;
  }

  /**
   * Translate key by inserting coins as param
   * @param key translate key
   */
  translateCoins(key: string) {
    const platform = this.connectionServ.getSelectedPlatformValue();
    return this.translateServ.translate(key, {
      coins: platform.money_name || 'Coins'
    });
  }

  /**
   * Translate challenge label
   * @param key key to translate
   */
  translateFilterLabel(key: string) {
    const formKeys = [
      'service', 'poste', 'departement',
      'region', 'pays', 'ville',
      'societe', 'team', 'nom_equipe'
    ];

    // Check if key is in list
    const findKey = formKeys.find(val => val === key);
    if (findKey) {
      return this.translateServ.translate('form.' + findKey);
    }

    return key;
  }

  /**
   * Indicate if user can access to dashboard
   */
  canAccessDashboard(): boolean {
    return this.accessServ.hasDashboardAccess();
  }

  /**
   * Storage management
   */
  async dataSave(key: string, data: any) {
    return await this.storage.set(key, data);
  }

  /**
   * Get data as observable
   * @param key key of data
   */
  dataGet(key: string): Observable<any> {
    return from(this.storage.get(key));
  }

  async dataRemove(key: string) {
    return await this.storage.remove(key);
  }

  /**
   * Get data keys as observable
   */
  dataKeys(): Observable<string[]> {
    return from(this.storage.keys());
  }

  /**
   * Format HTML text and include mentions, link...
   * @param html string
   */
  formatHTMLText(html: string): string {
    if (typeof html !== 'string') {
      return html;
    }

    // NL2BR
    html = html.replace(/\n/g, '<br />');

    // http://, https://, ftp://
    html = html.replace(
      Utils.PATTERN_HTTP,
      '<a href="$&" target="_blank">$&</a>'
    );

    // www. without http:// or https://
    html = html.replace(
      Utils.PATTERN_WWW,
      '$1<a href="http://$2" target="_blank">$2</a>'
    );

    // Emails
    html = html.replace(
      Utils.PATTERN_EMAIL,
      '<a href="mailto:$&" target="_blank">$&</a>'
    );

    // Mentions
    html = html.replace(/{{([^{}]*)}}/g, (c, p) => {
      const part = p.split(':');

      if (part[0] === '@') {
        return part[3] === 'user' ?
          `<a class="mention" data-m-id="${ part[1] }" data-m-name="${ part[2] }" data-m-type="${ part[3] }" href="/profile/${ part[1] }">${ part[2] }</a> ` :
          `<a class="mention" data-m-id="${ part[1] }" data-m-name="${ part[2] }" data-m-type="${ part[3] }">${ part[2] }</a> `;
      } else {
        return c;
      }
    });

    return html;
  }

  /**
   * Find links in text
   * @param text search text
   */
  findLinks(text: string) {
    const results: LinkItemInterface[] = [];

    let matches: Array<string> = text?.match(Utils.PATTERN_HTTP);
    matches?.map(val => val?.trim())?.forEach(value => results.push({value, href: value, type: 'url'}));

    matches = text?.match(Utils.PATTERN_WWW);
    matches?.map(val => val?.trim())?.forEach(value => results.push({value, href: 'http://' + value, type: 'url'}));

    matches = text?.match(Utils.PATTERN_EMAIL);
    matches?.map(val => val?.trim())?.forEach(value => results.push({value, href: 'http://' + value, type: 'email'}));

    return results;
  }

  /**
   * Build smart display date (not repeat year or month if the same)
   * @param dateFrom date from
   * @param dateTo date to
   */
  buildSmartFromToDate(dateFrom, dateTo) {
    moment.locale(this.translateServ.getActiveLang());

    const start = moment(dateFrom);
    const end = moment(dateTo);

    let startLabel;
    let endLabel;

    if (start.isSame(end, 'month')) {
      startLabel = start.format('D');
      endLabel = end.format('LL');
    } else if (start.isSame(end, 'year')) {
      startLabel = start.format('D MMMM');
      endLabel = end.format('LL');
    } else {
      startLabel = start.format('LL');
      endLabel = end.format('LL');
    }

    return this.translateServ.translate('utils.from-to', {
      start: startLabel,
      end: endLabel
    });
  }

  /**
   * Open URL in new window
   * @param url url to open
   */
  openUrl(url: string) {
    // Open device browser
    if (this.ionicPlatform.is('capacitor')) {
      Browser.open({url, view: 'default'}).then(/* Nothing to do */);
    } else {
      window.open(url, '_blank');
    }
  }

  /**
   * Download url
   * @param url link to download
   */
  downloadUrl(url: string) {
    if (this.ionicPlatform.is('capacitor')) {
      Browser.open({url, view: 'default'}).then(/* Nothing to do */);
    } else {
      Utils.download(url);
    }
  }

  /**
   * Open link to reload account
   */
  reloadAccount() {
    const lang = this.translateServ.getActiveLang();
    this.openUrl(EnvParams.reload_link[lang]);
  }

  /*
   * Display prompt to rate the app
   */
  promptAppRating() {
    setTimeout(() => {
      if (this.ionicPlatform.is('capacitor')) {
        const currentTime = Utils.getCurrentTimeStamp();
        this.storage.get('__lastRequestedRatingPrompt').then(lastRequestedPrompt => {
          if (!!lastRequestedPrompt) {
            // Check if diff is superior to 30 days
            if ((currentTime - lastRequestedPrompt) > 2592000) {
              RateApp.requestReview().then(_ => {
                this.storage.set('__lastRequestedRatingPrompt', currentTime);
              });
            }
          } else {
            // Init timestamp if not set
            this.storage.set('__lastRequestedRatingPrompt', currentTime).then(/* Nothing to do */);
          }
        });
      }
    }, 20000);
  }

  /**
   * Manage Root level params
   * @param params params object
   */
  setParams(params: any) {
    this.params = params;
  }

  getParams() {
    return this.params;
  }

}
