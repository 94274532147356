import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { DurationInputArg1, DurationInputArg2 } from 'moment';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    }
  ]
})
export class DateInputComponent implements OnInit, ControlValueAccessor {

  // TODO: Implement a modal to manage time type and take in consideration i18n
  @Input() type: 'date' | 'datetime' | 'time' = 'date';

  @Input() showSeconds = false;

  @Input() min: any;
  @Input() max: any;

  @Input() magicTool: boolean | string = null;

  @Input() isDisabled = false;
  @Input() placeholder: string;

  private dateStart: moment.Moment;

  public isFocused = false;

  public date: string | moment.Moment;
  public isTimeOpened = false;

  public onChange = (_: any) => {}
  public onTouch = () => {}

  constructor() {
  }

  ngOnInit() {
    if (typeof this.magicTool === 'string') {
      this.dateStart = moment(this.magicTool);
    } else if (this.magicTool === true) {
      this.dateStart = moment();
    }
  }

  @HostListener('click')
  hostTouched() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  /**
   * Add unit to date
   * @param num num to add
   * @param unit unit
   */
  addUnit(num: DurationInputArg1, unit: DurationInputArg2) {
    const current = moment(this.date);
    const start = moment(this.dateStart);

    start.add(num, unit);
    start.hour(current.hour());
    start.minute(current.minute());
    start.second(current.second());

    this.date = start.format('YYYY-MM-DD HH:mm:ss');
    this.applyChange();
  }

  /**
   * Apply change using string as output
   */
  applyChange() {
    const format = this.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
    this.onChange(moment(this.date).format(format));
  }

  applyTimeChange() {
    const regex = this.showSeconds ? /(\d+):(\d+):(\d+)/g : /(\d+):(\d+)/g;
    let date: string = this.date as string;

    if (date.match(regex)) {
      date = date.replace(regex, (s, p1, p2, p3) => {
        const dateM = moment().hour(p1).minute(p2).second(0);
        if (this.showSeconds) {
          dateM.second(p3);
        }
        return dateM.format('HH:mm:ss');
      });
      this.onChange(date);
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    if (this.type === 'time' && inputValue) {
      const format = this.showSeconds ? 'HH:mm:ss' : 'HH:mm';
      if (moment.isMoment(inputValue)) {
        this.date = inputValue.format(format);
      } else {
        this.date = (inputValue as string).replace(/(\d+):(\d+):(\d+)/g, (s, p1, p2, p3) => {
          const date = moment().hour(p1).minute(p2).second(p3);
          return date.format(format);
        });
      }
    } else {
      this.date = inputValue;
    }

    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
