import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButtonComponent } from '@components/forms/select-button/select-button.component';
import { SelectButtonOptionComponent } from '@components/forms/select-button/select-button-option.component';



@NgModule({
  declarations: [
    SelectButtonComponent,
    SelectButtonOptionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SelectButtonComponent,
    SelectButtonOptionComponent
  ]
})
export class SelectButtonModule { }
