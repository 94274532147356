import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export abstract class HttpBaseService {

  rootApi = environment.apiRoot;

  constructor(@Inject(HttpClient) protected http: HttpClient) {
  }

  /**
   * Make a standard request which manage standard response
   * @param request request to do
   */
  stdRequest<T>(request: Observable<any>): Observable<T> {
    return request.pipe(
      switchMap((response: any) => {
        if (response?.error) {
          return throwError(response);
        } else {
          return of(response);
        }
      })
    );
  }

  /**
   * Remove mat tooltip
   * TODO: check for better integration
   * There is an existing issue about tooltip stuck after route change
   * -> https://github.com/angular/components/issues/11478
   *
   * The following process shall be executed after each page initialization that request mat-tooltip removing/refreshing
   * So it is provided by this class to be reachable for all component using route navigation
   * Other solution is to use Navigation event (NavigationEnd) but it add a additional event to be supervised,
   * not recommanded  for optimization
   *
   * Other possible solution: https://www.npmjs.com/package/ng2-tooltip-directive
   */
  removeMatTooltip() {
    while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
      document.getElementsByTagName('mat-tooltip-component')[0].remove();
    }
  }
}
