import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appHideOnError]'
})

export class HideOnErrorDirective {

  constructor(private elt: ElementRef) {
  }

  @HostListener('error')
  error() {
    if (this.elt) {
      this.elt.nativeElement.style.display = 'none';
    }
  }

}
