import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgStoreComponent } from '@components/images/img-store/img-store.component';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [
    ImgStoreComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    ImgStoreComponent
  ]
})
export class ImgStoreModule { }
