import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ConnectionService } from '@services/connection.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public auth: ConnectionService, private router: NavController) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (await this.auth.isConnected().toPromise()) {
      return await true;
    }

    // not logged in so redirect to login page with the return url
    // Remove pid and token queryParams from returnUrl and apply to login redirection
    // -> Transform /challenges?pid=...&token=...&cat=... to /login?returnUrl=%2Fchallenges%3Fcat=...&pid=...&token=...
    let urlParams;
    const urlParts = state.url.split('?');

    if (urlParts?.length > 1) {
      urlParams = new URLSearchParams(urlParts[1]);
      urlParams.delete('pid');
      urlParams.delete('token');
    }
    const queryParams: any = {
      returnUrl: urlParams?.toString()?.length > 0 ? `${urlParts[0]}?${urlParams.toString()}` : urlParts[0]
    };
    // Add pid and token queryParams
    ['pid', 'token'].forEach(key => {
      if (route.queryParams[key]) {
        queryParams[key] = route.queryParams[key];
      }
    });
    this.router.navigateRoot(['/login'], {queryParams});
    return await false;
  }
}
