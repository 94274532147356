import { Injectable } from '@angular/core';
import { HttpBaseService } from '../http-base.service';
import {
  ReqValidateInterface,
  SurveyValidateItemData
} from '@interfaces/api/client';
import { Observable } from 'rxjs';


@Injectable()
export class SurveysService extends HttpBaseService {


  /**
   * Used to validate a survey. Surveys are displayed into newsfeed.
   * @param idSurvey id of the survey.
   * @param answers each element contains the id of the question and the id of the response.
   */
  validate(idSurvey: string, answers: SurveyValidateItemData[]): Observable<ReqValidateInterface> {
    const body = new FormData();
    body.append('id_qa', idSurvey);
    body.append('reponses', JSON.stringify(answers));
    return this.stdRequest(
      this.http.post<ReqValidateInterface>(`${ this.rootApi }/validateqa`, body)
    );
  }

}
