import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { AppMainMenuComponent } from './main-menu/main-menu.component';
import { AppSideMenuComponent } from './side-menu/side-menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NotificationsPopover } from './notifications/notifications.popover';
import { PipesModule } from '@pipes';
import { AppHeaderLogoComponent } from './logo/logo.component';
import { DirectivesModule } from '@directives/directives.module';
import { ClientMenuButtonComponent } from '@components/app/header/menu-button/menu-button.component';


@NgModule({
  declarations: [
    AppHeaderComponent,
    AppMainMenuComponent,
    AppSideMenuComponent,
    AppHeaderLogoComponent,
    ClientMenuButtonComponent,
    NotificationsPopover
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    MatTooltipModule,
    FormsModule,
    TranslocoModule,
    PipesModule,
    DirectivesModule
  ],
  exports: [
    AppHeaderComponent,
    AppMainMenuComponent,
    AppSideMenuComponent,
    AppHeaderLogoComponent,
    ClientMenuButtonComponent,
    NotificationsPopover
  ]
})
export class AppHeaderModule {
}
