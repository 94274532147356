import { Injectable } from '@angular/core';
import { ConnectionService } from '@services/connection.service';
import { PlatformInterface } from '@interfaces/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfileInterface } from '@interfaces/connection.interface';
import { PermissionsForDashboardInterface, PermissionsForDashboardModel } from '@services/access-control/permissions-for-dashboard.model';
import { PermissionsForClientInterface, PermissionsForClientModel } from '@services/access-control/permissions-for-client.model';
import { PermissionsForSettingsInterface, PermissionsForSettingsModel } from '@services/access-control/permissions-for-settings.model';

/**
 * This service allow access control of app.
 * There are 3 kinds of access permissions:
 * - The client permissions (Used to control client users to access feature)
 * - The dashboard permissions (Used to control admin users to access dashboard feature)
 * - The settings permissions (Used to enable/disable common feature)
 *
 * The client permissions and dashboard permissions are managed through Observable event, and settings permissions are linked to both permissions.
 *
 * There are 4 ways to build permissions:
 * - From "action.access.routeList" list to build client permissions
 * - From "action.access.routeList.administration" list to build dashboard permissions
 * - From "action.access.routeList.settings" list to build settings permissions
 * - From "action.access.accessList" list to build dashboard permissions of user with specific roles
 */
@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  private canAccessDashboard: boolean;

  private dashboardPermissionsState: BehaviorSubject<PermissionsForDashboardInterface> = new BehaviorSubject<PermissionsForDashboardInterface>(new PermissionsForDashboardModel());
  private clientPermissionsState: BehaviorSubject<PermissionsForClientInterface> = new BehaviorSubject<PermissionsForClientInterface>(new PermissionsForClientModel());

  private platform: PlatformInterface;
  // "settings" is not managed by events but included in client/dashboard model
  private settings: PermissionsForSettingsInterface = new PermissionsForSettingsModel();

  constructor(private connectionServ: ConnectionService) {
  }

  /**
   * Callback for platform change
   */
  onPlatformChange() {
    this.platform = this.connectionServ.getSelectedPlatformValue();
    this.buildPermissionsForSettings();
    this.buildPermissionsForClient();
    this.buildPermissionsForDashboard();
  }

  /**
   * Return current platform
   */
  getCurrentPlatform(): PlatformInterface {
    return this.connectionServ.getSelectedPlatformValue();
  }

  /**
   * Return current profile
   */
  getCurrentProfile(): UserProfileInterface {
    return this.connectionServ.getTokenValue()?.user;
  }

  /**
   * Return platform state
   */
  getPlatformState(): Observable<PlatformInterface> {
    return this.connectionServ.getPlatformState();
  }

  /**
   * Return dashboard access status
   */
  hasDashboardAccess(): boolean {
    return this.canAccessDashboard;
  }

  /**
   * Return current client permissions value
   */
  permissionsForClient(): PermissionsForClientInterface {
    return this.clientPermissionsState.getValue();
  }

  /**
   * Return current client permissions as Observable
   */
  permissionsForClientState(): Observable<PermissionsForClientInterface> {
    return this.clientPermissionsState.asObservable();
  }

  /**
   * Return current dashboard permissions value
   */
  permissionsForDashboard(): PermissionsForDashboardInterface {
    return this.dashboardPermissionsState.getValue();
  }

  /**
   * Return current dashboard permission as Observable
   */
  permissionsForDashboardState(): Observable<PermissionsForDashboardInterface> {
    return this.dashboardPermissionsState.asObservable();
  }

  /**
   * fetches available route client
   */
  getClientMainRoute(): string {
    const permissions = this.clientPermissionsState.getValue();

    if (permissions.isFeedEnabled) {
      return '/feed';
    }
    if (permissions.isChallengesEnabled) {
      return '/challenges';
    }
    if (permissions.isStoreEnabled) {
      return '/e-store';
    }
    if (permissions.isFormsEnabled) {
      return '/forms';
    }
    if (permissions.isQuizEnabled) {
      return '/quizzes';
    }
    if (permissions.isRoadboxEnabled) {
      return '/roadbox';
    }

    // Settings page is default page
    return '/settings';
  }

  /**
   * fetches available route for animation
   */
  getDashboardMainRoute(): string {
    const permissions = this.dashboardPermissionsState.getValue();
    if (permissions.isStatsEnabled) {
      return this.getDashboardStatsRoute();
    }
    if (permissions.isMainSettingsEnabled) {
      return '/dashboard/settings';
    }
    if (permissions.isUsersEnabled) {
      return '/dashboard/members';
    }
    if (permissions.isTeamsEnabled) {
      return '/dashboard/teams';
    }
    if (permissions.isChallengesEnabled) {
      return '/dashboard/challenges';
    }
    if (permissions.isBillingEnabled) {
      return '/dashboard/e-store';
    }
    if (permissions.isCoinsEnabled) {
      return '/dashboard/coins';
    }
    if (
      permissions.isRoadboxEnabled ||
      permissions.isQuizEnabled ||
      permissions.isSurveysEnabled ||
      permissions.isFormsEnabled
    ) {
      return this.getDashboardAnimationRoute();
    }
    return '/';

  }

  /**
   * fetches available route for animation
   */
  getDashboardAnimationRoute(): string {
    const permissions = this.dashboardPermissionsState.getValue();
    if (permissions.isFeedEnabled && (permissions.isMainSettingsEnabled || (permissions.isTeamsEnabled && !!permissions.permissions.Team?.update))) {
      return '/dashboard/feed';
    }
    if (permissions.isRoadboxEnabled) {
      return '/dashboard/roadbox';
    }
    if (permissions.isQuizEnabled) {
      return '/dashboard/quizzes';
    }
    if (permissions.isFeedEnabled && permissions.isSurveysEnabled) {
      return '/dashboard/surveys';
    }
    if (permissions.isFormsEnabled) {
      return '/dashboard/forms';
    }
    return '/';
  }

  /**
   * fetches route for stats
   */
  getDashboardStatsRoute() {
    const permissions = this.dashboardPermissionsState.getValue();

    if (permissions.isChallengesEnabled) {
      return '/dashboard/stats/challenges';
    }
    if (permissions.isFeedEnabled) {
      return '/dashboard/stats/interactions';
    }

    return '/dashboard/stats/audience';
  }

  /**
   * fetches available route for users or teams
   */
  getDashboardUsersTeamsRoute(): string {
    const permissions = this.dashboardPermissionsState.getValue();
    if (permissions.isUsersEnabled) {
      return '/dashboard/members';
    }
    if (permissions.isTeamsEnabled) {
      return '/dashboard/teams';
    }
  }

  /**
   * Compute permissions for dashboard according user
   */
  private buildPermissionsForDashboard() {
    if (this.platform?.is_admin) {
      this.buildPermissionsForDashboardAdminUser();
    } else {
      this.buildPermissionsForDashboardUserWithRole();
    }
  }

  /**
   * Compute permissions for dashboard for admin user
   */
  private buildPermissionsForSettings() {
    if (this.platform) {
      this.settings.buildPermissions(this.platform?.access?.routeList?.settings);
    }
  }

  /**
   * Compute permissions for dashboard for admin user
   */
  private buildPermissionsForDashboardAdminUser() {
    const permissions = this.dashboardPermissionsState.getValue();

    if (this.platform) {
      permissions.platform = this.platform;
      permissions.settings = this.settings;
      permissions.isAdminUser = true;
      permissions.buildPermissionsFromRouteList(this.platform?.access?.routeList?.administration, this.clientPermissionsState.getValue());
      this.canAccessDashboard = true;
    } else {
      permissions.resetPermissions();
      this.canAccessDashboard = false;
    }

    this.dashboardPermissionsState.next(permissions);
  }

  /**
   * Compute permissions for dashboard for user with roles
   * -> If user has no role, he can't access dashboard.
   */
  private buildPermissionsForDashboardUserWithRole() {
    const permissions = this.dashboardPermissionsState.getValue();

    if (this.platform) {
      permissions.platform = this.platform;
      permissions.settings = this.settings;
      permissions.buildPermissionsFromAccessList(this.platform?.access?.accessList, this.clientPermissionsState.getValue());
      this.canAccessDashboard = this.getDashboardMainRoute() !== '/';
    } else {
      permissions.resetPermissions();
      this.canAccessDashboard = false;
    }

    this.dashboardPermissionsState.next(permissions);
  }

  /**
   * Compute permissions for client access
   */
  private buildPermissionsForClient() {
    const permissions = this.clientPermissionsState.getValue();

    if (this.platform) {
      permissions.platform = this.platform;
      permissions.settings = this.settings;
      permissions.buildPermissions(this.platform?.access?.routeList);
    } else {
      permissions.resetPermissions();
    }

    this.clientPermissionsState.next(permissions);
  }

}
