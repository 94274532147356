import { Injectable } from '@angular/core';
import { HttpBaseService } from '../http-base.service';
import {
  CommentData,
  GalleryInterface,
  ReqAddRemoveLikeInterface, ReqAddCommentInterface,
  ReqLikesInterface,
  ReqViewersInterface
} from '@interfaces/api/client';
import { StandardResponseInterface } from '@interfaces/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Utils } from '@helpers/utils';

/**
 * Service to provide interface for social interactions:
 *  - comments
 *  - likes
 */

@Injectable()
export class SocialService extends HttpBaseService {


  /**
   * Used to add or update a comment on a post
   * @param comment json object of the comment to add.
   * @param idComment if set, the comment will be updated instead of added
   * @param isGallery use WS for gallery update
   */
  addComment(comment: CommentData, idComment?: number, isGallery?: boolean): Observable<ReqAddCommentInterface> {
    const body = new FormData();
    body.append('post', JSON.stringify(comment));
    if (idComment) {
      body.append('id_action_post', idComment.toString());
    }
    return this.stdRequest(this.http.post<ReqAddCommentInterface>(`${ this.rootApi }/addcomment${isGallery ? 'gallery' : ''}`, body));
  }

  /**
   * Get the viewers of post
   * @param idObject id of object
   * @param type type of object
   */
  getViewers(idObject: number, type: string): Observable<ReqViewersInterface> {
    return this.stdRequest(
      this.http.post<ReqViewersInterface>(`${ this.rootApi }/getviewers/${ type }/${ idObject.toString() }`, null)
    );
  }

  /**
   * Set view to objects
   * @param idObject id of object
   * @param type type of object
   * @param idObjects array of objects id
   */
  viewObject(idObject: number, type: string, idObjects?: Array<number>): Observable<StandardResponseInterface> {
    const body = new FormData();

    if (idObjects) {
      body.append('ids_objects', JSON.stringify(idObjects));
    }

    return this.stdRequest(
      this.http.post<StandardResponseInterface>(`${ this.rootApi }/viewobject/${ type }/${ idObject.toString() }`, body)
    );
  }

  /**
   * Used to get the list of user who like a publication or a gallery. Id_object is the id of a post or of a pictures gallery.
   * @param idObject id of object
   * @param type can be ‘action_gallery’ or ‘action_post’
   */
  getLikes(idObject: number, type: string): Observable<ReqLikesInterface> {
    const body = new FormData();
    body.append('type', type);

    return this.stdRequest(
      this.http.post<ReqLikesInterface>(`${ this.rootApi }/getlikes/${ idObject.toString() }`, body)
    );
  }

  /**
   * Used to add a like on a post or a gallery.
   * @param idObject id of object
   * @param type can be ‘gallery’ or ‘post’
   */
  addLike(idObject: number, type: string): Observable<ReqAddRemoveLikeInterface> {
    const body = new FormData();
    body.append('type', type);

    return this.stdRequest(
      this.http.post<ReqAddRemoveLikeInterface>(`${ this.rootApi }/addlike/${ idObject.toString() }`, body)
    );
  }

  /**
   * Used to delete user like on a post or a gallery.
   * @param idObject id of object
   * @param type can be ‘gallery’ or ‘post’
   */
  removeLike(idObject: number, type: string): Observable<ReqAddRemoveLikeInterface> {
    const body = new FormData();
    body.append('type', type);

    return this.stdRequest(
      this.http.post<ReqAddRemoveLikeInterface>(`${ this.rootApi }/removelike/${ idObject.toString() }`, body)
    );
  }

  /**
   * Used to toggle user like on a post or a gallery.
   * @param idObject id of object
   * @param type can be ‘gallery’ or ‘post’
   * @param liked indicate if user already like the object
   */
  toggleLike(idObject: number, type: string, liked: boolean): Observable<ReqAddRemoveLikeInterface> {
    if (liked) {
      return this.removeLike(idObject, type);
    } else {
      return this.addLike(idObject, type);
    }
  }

  /**
   * Used to add a like on a gallery.
   * @param idObject id of object
   * @param idMedia id of media
   */
  addLikeGallery(idObject: number, idMedia?: number): Observable<ReqAddRemoveLikeInterface> {
    const body = new FormData();
    if (idMedia !== null && idMedia !== undefined) {
      body.append('id_media', idMedia.toString());
    }

    return this.stdRequest(
      this.http.post<ReqAddRemoveLikeInterface>(`${ this.rootApi }/addlikegallery/${ idObject.toString() }`, body)
    );
  }

  /**
   * Used to delete user like on a gallery.
   * @param idObject id of object
   * @param idMedia id of media
   */
  removeLikeGallery(idObject: number, idMedia?: number): Observable<ReqAddRemoveLikeInterface> {
    const body = new FormData();
    if (idMedia !== null && idMedia !== undefined) {
      body.append('id_media', idMedia.toString());
    }

    return this.stdRequest(
      this.http.post<ReqAddRemoveLikeInterface>(`${ this.rootApi }/removelikegallery/${ idObject.toString() }`, body)
    );
  }

  /**
   * Used to toggle user like on a gallery.
   * @param idObject id of object
   * @param idMedia id of media
   * @param liked indicate if user already like the object
   * @param gallery to update
   * @param idCustomer id of current customer
   */
  toggleLikeGallery(idObject: number,
                    liked: boolean,
                    idMedia?: number,
                    gallery?: GalleryInterface,
                    idCustomer?: number): Observable<ReqAddRemoveLikeInterface> {
    if (liked) {
      return this.removeLikeGallery(idObject, idMedia).pipe(tap(() => {
        if (gallery && idCustomer) {
          gallery.likes = gallery.likes.filter(liker => Utils.toNumber(liker.id_customer) !== Utils.toNumber(idCustomer));
          gallery.user_like = false;
        }
      }));
    } else {
      return this.addLikeGallery(idObject, idMedia).pipe(tap(response => {
        if (gallery && idCustomer) {
          gallery.likes = response.likes_list || [];
          gallery.user_like = true;
        }
      }));
    }
  }

}
