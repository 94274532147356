import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CguAgreementModal } from './cgu-agreement.modal';
import { ConnectionService } from '@services/connection.service';

export interface CguAgreementOptionsInterface {
  idAction?: number;
  viewCgu?: boolean;
  viewCguMedias?: boolean;
  mode?: 'view' | 'agree';
}

@Injectable()
export class CguAgreementController {

  isOpened: boolean;

  constructor(private modalCtrl: ModalController,
              private authServ: ConnectionService) {
    this.isOpened = false;
  }

  /**
   * Show modal
   * @param props parameters
   * @param callback callback called on dismiss
   */
  async show(props: CguAgreementOptionsInterface, callback?: (choice) => void) {
    if (this.isOpened) {
      return null;
    }

    this.isOpened = true;

    const modal = await this.modalCtrl.create({
      component: CguAgreementModal,
      componentProps: props,
      cssClass: 'cgu-agreement-modal',
    });

    modal.onDidDismiss().then(data => {
      this.isOpened = false;
      if (callback) {
        callback(data.role === 'backdrop' ? null : data.data);
      }
    });

    return await modal.present();
  }

  /**
   * Indicate if this modal shall be show
   */
  public shallBeShow(): boolean|null {
    const auth = this.authServ.getTokenValue();
    const platform = this.authServ.getSelectedPlatformValue();
    if (!auth) {
      return null;
    }
    return (auth.user.optin === '0' || (auth.user.optin_medias === '0' && platform?.require_optin_medias === '1'));
  }

}
