import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { EmojiPanelController } from '@components/utils/emoji-panel';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { TeamService } from '@services/team.service';
import { Utils } from '@helpers/utils';
import { MentionItemInterface } from '@components/forms/textarea-input/textarea-input.interface';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaInputComponent),
      multi: true,
    },
  ],
})
export class TextareaInputComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() type: 'html' | 'text' = 'text';

  // Min Rows and Max Rows for text type only
  @Input() minRows = 1;
  @Input() maxRows = 8;

  @Input() allowMentions = true;
  @Input() disableEmojiButton = false;

  @Input() context: 'normal' | 'comment' = 'normal';

  @Output() inputKeyup: EventEmitter<{ value: string, event: KeyboardEvent }> = new EventEmitter<{ value: string; event: any }>();
  @Output() inputKeydown: EventEmitter<{ value: string, event: KeyboardEvent }> = new EventEmitter<{ value: string; event: any }>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  textareaFocused = false;
  message: string;
  oldMessage: string;


  editor: Editor;
  editorToolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['text_color']
  ];

  mentionItems: MentionItemInterface[] = [];
  mentionConfig = {
    labelKey: 'name'
  };

  public onChange = (_: any) => {};
  public onTouch = () => {};

  constructor(private emojiCtrl: EmojiPanelController,
              private teamServ: TeamService) {
  }

  ngOnInit() {
    this.editor = new Editor();

    if (this.allowMentions) {
      if (this.teamServ.getTeamFeedValue() > 0) {
        const team = this.teamServ.getSelectedTeamForFeed();
        team?.members?.forEach(user => {
          this.mentionItems.push({
            id: Utils.toString(user.id_customer),
            name: user.firstname + ' ' + user.lastname,
            type: 'user',
            image: user.profil_pic
          });
        });
      } else {
        this.teamServ.getTeamsAndMembers().subscribe(response => {
          response?.customers?.forEach(user => {
            this.mentionItems.push({
              id: Utils.toString(user.id_customer),
              name: user.firstname + ' ' + user.lastname,
              type: 'user',
              image: user.profil_pic
            });
          });
          response?.teams?.forEach(team => {
            this.mentionItems.push({
              id: Utils.toString(team.id_action_equipe),
              name: team.nom,
              type: 'team',
              image: team.profil_pic
            });
          });
        });
      }
    }
  }

  focus() {
    // Delay focus to avoid expression checked error
    setTimeout(() => {
      document.getElementById('textInput')?.focus();
    }, 100);
  }

  /**
   * Apply change according type
   */
  applyChange() {
    this.onChange(this.getFormattedMessage(this.message));
  }

  /**
   * Get formatted message according input and mentions
   */
  getFormattedMessage(inputMessage: any) {
    let message = inputMessage;
    // HTML type
    if (this.type === 'html') {
      if (message && typeof message === 'object') {
        message = toHTML(message as any);
      } else if (typeof message !== 'string') {
        message = '';
      }
    }

    // Mentions
    if (this.allowMentions) {
      // Add space in order to works mention replacement
      message += ' ';
      this.mentionItems?.forEach(item => {
        // Replace all mention with space or < at the end
        message = message.replace(new RegExp(`@${item.name}([ <]+)`, 'gi'), `{{@:${ item.id }:${ item.name }:${ item.type }}}$1`);
      });
      message = message.trim();
    }

    return message;
  }

  /**
   * Get value according type
   * @param val base val
   * TODO: To remove ?
   */
  getValueAccordingType(val) {
    if (this.type === 'html' && val && typeof val === 'object') {
      return toHTML(val);
    }
    return val;
  }


  /**
   * Insert emoji
   * @param event event of click
   * @param textEl text element
   */
  insertEmoji(event, textEl) {
    if (!this.disabled) {
      this.emojiCtrl.open({
        textEl,
        event,
        callback: () => {
          if (this.type === 'text') {
            this.message = textEl.value;
            this.valueChange.emit(this.message);
          }
        }
      })?.then(/* Nothing to do */);
    }
  }

  /**
   * Focus or blur component
   * @param focused is focused ?
   */
  focusBlur(focused: boolean) {
    this.textareaFocused = focused;
    if (!focused && this.message !== this.oldMessage) {
      this.oldMessage = this.message;
      this.valueChange.emit(this.message);
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    this.message = inputValue;

    if (this.allowMentions && this.message) {
      this.message = this.message.replace(/{{([^{}]*)}}/g, (c, p) => {
        const part = p.split(':');

        if (part[0] === '@') {
          return `@${ part[2] } `;
        } else {
          return c;
        }
      });
    }

    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
