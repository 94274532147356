import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar-modal',
  template: `
    <ion-toolbar>
      <ion-buttons slot="end" class="ion-hide-sm-down">
        <ion-button (click)="dismissEvent.emit()" color="medium">
          <ion-icon slot="icon-only" name="rd-close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="start" class="ion-hide-sm-up">
        <ion-button (click)="dismissEvent.emit()" color="medium">
          <ion-icon slot="icon-only" name="arrow-back" *ngIf="type === 'modal'"></ion-icon>
          <ion-icon slot="icon-only" name="close" *ngIf="type === 'popup'"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title *ngIf="title">{{title}}</ion-title>
      <ng-content></ng-content>
    </ion-toolbar>
  `,
})
export class ToolbarModalComponent implements OnInit {

  // Modal is an ionic modal which displayed in full screen
  // Popup is an ionic modal which displayed as popup
  @Input() type: 'modal' | 'popup' = 'modal';

  @Input() title: string;

  @Output() dismissEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

}
