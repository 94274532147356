import { Injectable } from '@angular/core';
import { HttpBaseService } from '../http-base.service';
import { GalleryInterface, ReqGalleriesInterface, ReqGalleryInterface } from '@interfaces/api/client';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RoadboxUtils } from '@roadoo-app/roadbox/roadbox.utils';
import { environment } from '@environments/environment';
import { Utils } from '@helpers/utils';


@Injectable()
export class RoadBoxService extends HttpBaseService {

  private localGalleries: ReqGalleriesInterface;
  private localIdCategory: string;

  /**
   * Build Unsafe media
   * @param gallery object
   */
  static buildUnsafeMedias(gallery: GalleryInterface) {
    gallery?.medias?.forEach(media => {
      media.src_unsafe = `${environment.mediaRoot}/clients/gallery/${gallery.h}/${Utils.extractFilename(media.src)}`;
    });
  }

  /**
   * Used to get the list of galleries.
   */
  galleries(idCategory?: string, reload?: boolean): Observable<ReqGalleriesInterface> {
    if (this.localGalleries && this.localIdCategory === idCategory && !reload) {
      return of(this.localGalleries);
    } else {
      const body = new FormData();
      if (idCategory) {
        body.append('id_category', idCategory);
      }
      return this.stdRequest<ReqGalleriesInterface>(
        this.http.post<ReqGalleriesInterface>(`${ this.rootApi }/getgalleries`, body)
      ).pipe(tap(response => {
        this.localGalleries = response;
        this.localIdCategory = idCategory;
      }));
    }
  }

  /**
   * Return category from id
   * @param idGallery id of gallery
   */
  gallery(idGallery: string): Observable<ReqGalleryInterface> {
    return this.stdRequest<ReqGalleryInterface>(
      this.http.post<ReqGalleryInterface>(`${ this.rootApi }/getgallery/${idGallery}`, null)
    ).pipe(tap(response => {
      RoadBoxService.buildUnsafeMedias(response?.gallery);
    }));
  }

}
